import axios from "../api/axios-provider"
import DeviceHelper from "./DeviceHelper"
import { MediaItemType } from "../queries/client/graphql"
import { getRealFilePath } from "../types/File"
import { FileSize } from "../types/FileSize"

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
declare global {
  interface Window {
    chrome?: unknown
  }
}

class FileHelper {
  async getDataFromURL(fileUuid: string, size = FileSize.ORIGINAL): Promise<string> {
    if (!(DeviceHelper.isMobile() || DeviceHelper.isSafari())) {
      return getRealFilePath(fileUuid, "client", size)
    }

    const data = await axios.get(`/client/media/${fileUuid}?size=${size}`, { responseType: "blob" })

    return window.URL.createObjectURL(new Blob([data.data]))
  }

  async getBlob(fileUuid: string, type: MediaItemType): Promise<Blob> {
    const data = await axios.get(`/client/media/${fileUuid}`, { responseType: "blob" })

    return new Blob([data.data], { type: this.getType(type) })
  }

  getType(type: MediaItemType): string {
    switch (type) {
      case MediaItemType.Jpeg:
        return "image/jpeg"
      case MediaItemType.Png:
        return "image/png"
      default:
        return ""
    }
  }

  getFilename(id: string, type: MediaItemType): string {
    switch (type) {
      case MediaItemType.Jpeg:
        return `${id}.jpeg`
      case MediaItemType.Png:
        return `${id}.png`
      default:
        return ""
    }
  }

  blobToBase64(blob: Blob) {
    const reader = new FileReader()
    reader.readAsDataURL(blob)

    return new Promise((resolve) => {
      reader.onloadend = () => {
        if (reader) {
          resolve(reader.result)
        }
      }
    })
  }

  filepathToBase64(file: string): Promise<string | ArrayBuffer | null> {
    const xhr = new XMLHttpRequest()
    xhr.open("GET", file, true)
    xhr.responseType = "blob"

    return new Promise((resolve) => {
      xhr.onload = () => {
        const reader = new FileReader()
        reader.onload = (event) => {
          const res = event.target?.result
          console.log(res)
        }
        reader.readAsDataURL(xhr.response)
        reader.onloadend = () => {
          if (reader) {
            resolve(reader.result)
          }
        }
      }
      xhr.send()
    })
  }

  private download(file: string, fileName: string) {
    const a = document.createElement("a")
    a.href = file
    a.style.display = "none"
    a.download = fileName
    a.setAttribute("download", fileName)
    document.body.appendChild(a)
    a.click()

    if (a.parentNode) {
      a.parentNode.removeChild(a)
    }
  }

  // Create and download file
  public downloadFile(blob: Blob, filename: string): void {
    const isChrome = window.chrome || navigator.userAgent.match("CriOS")
    let type: string

    if (isChrome) {
      type = "createObjectURL"
    } else {
      type = "readAsDataURL"
    }

    // every other browser
    const reader = new FileReader()
    reader.onloadend = () => {
      let url: string

      switch (type) {
        case "createObjectURL":
          url = window.URL.createObjectURL(blob)
          break
        case "readAsDataURL":
        default:
          url = reader.result as string
      }

      if (url) {
        this.download(url, filename)

        if (type === "createObjectURL") {
          window.URL.revokeObjectURL(url)
        }
      }
    }

    reader.readAsDataURL(blob)
  }
}

const instance = new FileHelper()

export default instance
