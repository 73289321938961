import { authApolloClient } from "./apollo-provider"
import {
  AuthenticatedRoute,
  LoginPassword,
  LoginPasswordMutation,
  LoginPasswordMutationVariables,
  RefreshToken,
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
  LogoutUserFromDevice,
  LogoutUserFromDeviceMutation,
  LogoutUserFromDeviceMutationVariables,
  SessionTokenFragment,
  GenerateLostPasswordToken,
  GenerateLostPasswordTokenMutation,
  GenerateLostPasswordTokenMutationVariables,
  UseLostPasswordToken,
  UseLostPasswordTokenMutation,
  UseLostPasswordTokenMutationVariables,
} from "../queries/auth/graphql"
import DeviceHelper from "../helpers/DeviceHelper"

class APIAuth {
  async login(username: string, password: string): Promise<SessionTokenFragment> {
    const result = await authApolloClient.mutate<LoginPasswordMutation, LoginPasswordMutationVariables>({
      mutation: LoginPassword,
      variables: {
        email: username,
        password,
        route: AuthenticatedRoute.Client,
        deviceId: DeviceHelper.getUUID(),
        platform: DeviceHelper.getPlatform(),
        description: "",
      },
    })
    if (result.data) {
      return result.data.authentication.loginPassword
    }
    throw new Error("No result received in login")
  }

  async refreshToken(refreshToken: string | null): Promise<SessionTokenFragment> {
    const result = await authApolloClient.mutate<RefreshTokenMutation, RefreshTokenMutationVariables>({
      mutation: RefreshToken,
      variables: {
        refreshToken: refreshToken ?? undefined,
        route: AuthenticatedRoute.Client,
        deviceId: DeviceHelper.getUUID(),
        platform: DeviceHelper.getPlatform(),
      },
    })

    if (result.data) {
      return result.data.authentication.refreshToken
    }

    throw new Error("No result received in refresh")
  }

  async logout(): Promise<boolean> {
    const result = await authApolloClient.mutate<LogoutUserFromDeviceMutation, LogoutUserFromDeviceMutationVariables>({
      mutation: LogoutUserFromDevice,
      variables: {
        route: AuthenticatedRoute.Client,
        deviceId: DeviceHelper.getUUID(),
        platform: DeviceHelper.getPlatform(),
      },
    })
    if (result.data) {
      return result.data.authentication.logoutUserFromDevice
    }
    throw new Error("No result received in logout")
  }

  async generateLostPasswordToken(email: string): Promise<Date> {
    const result = await authApolloClient.mutate<GenerateLostPasswordTokenMutation, GenerateLostPasswordTokenMutationVariables>({
      mutation: GenerateLostPasswordToken,
      variables: {
        email,
      },
    })
    if (result.data) {
      return result.data.authentication.generateLostPasswordToken
    }
    throw new Error("No result received in generateLostPasswordToken")
  }

  async useLostPasswordToken(email: string, token: string, password: string): Promise<boolean> {
    const result = await authApolloClient.mutate<UseLostPasswordTokenMutation, UseLostPasswordTokenMutationVariables>({
      mutation: UseLostPasswordToken,
      variables: {
        email,
        token,
        password,
      },
    })
    if (result.data) {
      return result.data.authentication.useLostPasswordToken
    }
    throw new Error("No result received in useLostPasswordToken")
  }
}

const instance = new APIAuth()

export default instance
