import { ActivityFragment } from "../queries/client/graphql"
import DateHelper from "../helpers/DateHelper"

export interface ActivitiesData {
  dateStr: string
  activities: ActivityFragment[]
}

export function getDataActivities(dateStart: Date, numberOfDays: number, activities: ActivityFragment[]): ActivitiesData[] {
  const dataActivities: ActivitiesData[] = []

  const sortedActivities = activities.sort((a, b) => new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime())

  for (let index = 0; index < numberOfDays; index += 1) {
    const date = new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate() + index)
    const filterActivities = sortedActivities.filter((activity) => DateHelper.isDateEqual(date, new Date(activity.startDateTime)))

    dataActivities.push({ dateStr: DateHelper.getDateStringFormat(date), activities: filterActivities })
  }

  return dataActivities
}
