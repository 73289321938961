<template>
  <Widget class="token-form">
    <h3 class="title">Nouveau mot de passe</h3>
    <h4 class="sub-title">
      Un e-mail vous a été envoyé avec un code pour réinitialiser votre mot de passe. Veuillez le saisir ci-dessous, ainsi que votre nouveau
      mot de passe.
    </h4>
    <FormTextField
      v-model="form"
      class="fields"
      :fields="fields"
      :status="status"
      :error="error"
      button-label="Valider"
      @submit="handleSubmit"
    />
  </Widget>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import APIAuth from "@/api/ApiAuth"
import TextFieldItem from "@/components/form-text-field/types/TextFieldItem"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"

interface ForgetPasswordForm {
  token: string | null
  password: string | null
  passwordConfirm: string | null
}

@Component({
  components: {
    Widget: async () => await import("@/components/widgets/widget.vue"),
    FormTextField: async () => await import("@/components/form-text-field/form-text-field.vue"),
  },
})
export default class TokenForm extends Vue {
  @Prop({ type: String, required: true }) readonly email!: string
  @Prop({ type: String, required: false, default: null }) readonly token!: string | null

  form: ForgetPasswordForm = {
    token: this.token,
    password: null,
    passwordConfirm: null,
  }
  fields: TextFieldItem[] = [
    { name: "token", label: "Code", type: "text" },
    { name: "password", label: "Nouveau mot de passe", type: "password" },
    { name: "passwordConfirm", label: "Confirmation du nouveau mot de passe", type: "password" },
  ]
  status = "enabled"
  error: string | null = null

  async handleSubmit() {
    this.error = null

    if (!this.form.token) {
      this.error = "Veuillez fournir un code"
      return
    }

    if (!this.form.password) {
      this.error = "Le mot de passe est obligatoire."
      return
    }

    if (this.form.password !== this.form.passwordConfirm) {
      this.error = "La confirmation du mot de passe n'est pas valide."
      return
    }

    this.status = "loading"

    try {
      const result = await APIAuth.useLostPasswordToken(this.email, this.form.token, this.form.password)
      if (!result) {
        this.error = "Compte inexistant"
      } else {
        TrackingHelper.track(AppTrackEvent.passwordLostCompleted)
        this.$emit("success")
      }
      this.status = "enabled"
    } catch (error) {
      this.status = "enabled"
      this.error = "Une erreur est survenue."
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.token-form {
  .title {
    margin-bottom: 24px;
  }

  .sub-title {
    color: $text-body;
    font-weight: normal;
  }

  .fields {
    padding-top: 48px;
  }
}
</style>
