<template>
  <div class="screen-wrapper">
    <div
      class="screen"
      :style="{
        width: `${screenWidth}px`,
        height: `${screenHeight}px`,
        marginTop: `${screenMarginTop}px`,
        marginLeft: `${screenMarginLeft}px`,
        fontSize: `${screenFontSize}px`,
      }"
    >
      <template v-if="currentScreen">
        <screen-placeholder v-if="isReseting" content="" />

        <screen-ephemeris v-else-if="isCurrentEphemeris" :data="currentScreen.data.ephemeris" :class="currentScreen.state" />
        <screen-anecdote v-else-if="isCurrentAnecdote" :data="currentScreen.data.anecdote" :class="currentScreen.state" />
        <screen-menu-day v-else-if="isCurrentMenuDay" :data="currentScreen.data.menuDay" :class="currentScreen.state" />
        <screen-menu-next-days
          v-else-if="isCurrentMenuNextDays"
          :data="currentScreen.data.menuNextDays"
          :timer="currentScreen.data.timer"
          :class="currentScreen.state"
        />
        <screen-animations v-else-if="isCurrentAnimationsWeek" :data="currentScreen.data.animationsWeek" :class="currentScreen.state" />
        <screen-pdf v-else-if="isCurrentPdf" :data="currentScreen.data.pdf" :class="currentScreen.state" />
        <screen-album
          v-else-if="isCurrentAlbum"
          :data="currentScreen.data.album"
          :timer="currentScreen.data.timer"
          :class="currentScreen.state"
        />

        <screen-placeholder v-else content="Type d'écran non géré" />
      </template>
      <screen-placeholder v-else content="Chargement en cours" />
      <screen-placeholder content="" class="screen-background" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import * as Sentry from "sentry-cordova"
import ApiScreen from "@/api/ApiScreen"
import { ScreenSlideFragment, ScreenSlideType } from "@/queries/screen/graphql"

const SCREEN_RATIO = 16 / 9
const REFRESH_DELAY = 60000

enum ScreenState {
  HIDDEN = "hidden",
  INTRO_HIDDEN = "intro hidden",
  INTRO_SHOWN = "intro shown",
  OUTRO_SHOWN = "outro shown",
  OUTRO_HIDDEN = "outro hidden",
}

type ScreenSlide = {
  data: ScreenSlideFragment
  state: ScreenState
  index: number
}

@Component({
  components: {
    "screen-placeholder": () => import("@/components/screen/screen-placeholder.vue"),
    "screen-ephemeris": () => import("@/components/screen/screen-ephemeris.vue"),
    "screen-anecdote": () => import("@/components/screen/screen-anecdote.vue"),
    "screen-menu-day": () => import("@/components/screen/screen-menu-day.vue"),
    "screen-menu-next-days": () => import("@/components/screen/screen-menu-next-days.vue"),
    "screen-animations": () => import("@/components/screen/screen-animations.vue"),
    "screen-pdf": () => import("@/components/screen/screen-pdf.vue"),
    "screen-album": () => import("@/components/screen/screen-album.vue"),
  },
})
export default class Screen extends Vue {
  screens: ScreenSlideFragment[] = []
  currentScreen: ScreenSlide | null = null
  refreshTimer: number | null = null
  screenStateTimer: number | null = null
  isReseting = false
  screenWidth = 1920
  screenHeight = 1080
  screenMarginTop = 0
  screenMarginLeft = 0
  screenFontSize = 16

  get isCurrentEphemeris() {
    return this.currentScreen?.data.type === ScreenSlideType.Ephemeris
  }

  get isCurrentAnecdote() {
    return this.currentScreen?.data.type === ScreenSlideType.Anecdote
  }

  get isCurrentMenuDay() {
    return this.currentScreen?.data.type === ScreenSlideType.MenuDay
  }

  get isCurrentMenuNextDays() {
    return this.currentScreen?.data.type === ScreenSlideType.MenuNextDays
  }

  get isCurrentAnimationsWeek() {
    return this.currentScreen?.data.type === ScreenSlideType.AnimationsWeek
  }

  get isCurrentPdf() {
    return this.currentScreen?.data.type === ScreenSlideType.Pdf
  }

  get isCurrentAlbum() {
    return this.currentScreen?.data.type === ScreenSlideType.Album
  }

  async created() {
    // Prepare API
    const token = this.$route.query.token
    if (token != null && typeof token === "string") {
      ApiScreen.setScreenToken(token)
    }

    // Retrieve API data and schedule refresh
    await this.fetchScreensData()

    // Update size on resize
    this.refreshSize()
    window.addEventListener("resize", this.refreshSize)
  }

  destroyed() {
    this.clearRefreshTimer()
    this.clearScreenStateTimer()
    window.removeEventListener("resize", this.refreshSize)
  }

  async fetchScreensData() {
    // Remove any existing timer
    this.clearRefreshTimer()

    // Retore existing data if needed
    if (this.screens == null) {
      const screensStr = window.localStorage.getItem("screens")

      if (screensStr != null) {
        try {
          this.setScreens(JSON.parse(screensStr))
        } catch (error) {
          Sentry.captureException(error)
          window.localStorage.removeItem("screens")
        }
      }
    }

    // Retrieve data from API
    let screens: ScreenSlideFragment[] | undefined
    try {
      screens = await ApiScreen.getScreens()
    } catch (error) {
      Sentry.captureException(error)
    }

    if (screens != null && screens.length > 0) {
      this.setScreens(screens)
    }

    if (!this.destroyed) {
      this.refreshTimer = window.setTimeout(() => this.fetchScreensData(), REFRESH_DELAY)
    }
  }

  setScreens(screens: ScreenSlideFragment[]) {
    const needNext = this.screens.length === 0

    this.screens = screens

    // Cache data
    window.localStorage.setItem("screens", JSON.stringify(screens))

    // Load next screen if no screen displayed
    if (needNext) {
      this.nextScreen()
      this.nextScreenState()
    }
  }

  nextScreen() {
    if (this.screens.length === 0) {
      return
    }

    let nextIndex = 0
    if (this.currentScreen != null) {
      nextIndex = this.currentScreen.index + 1
    }

    if (this.screens.length <= nextIndex) {
      nextIndex = 0
    }

    this.currentScreen = {
      data: this.screens[nextIndex],
      state: ScreenState.HIDDEN,
      index: nextIndex,
    }
  }

  nextScreenState() {
    if (this.currentScreen == null) {
      return
    }

    this.clearScreenStateTimer()

    let delay = 10

    switch (this.currentScreen.state) {
      case ScreenState.HIDDEN:
        this.currentScreen.state = ScreenState.INTRO_HIDDEN
        this.isReseting = false
        delay = 100
        break

      case ScreenState.INTRO_HIDDEN:
        this.currentScreen.state = ScreenState.INTRO_SHOWN
        delay = 1000
        break

      case ScreenState.INTRO_SHOWN:
        this.currentScreen.state = ScreenState.OUTRO_SHOWN
        delay = this.currentScreen.data.timer * 1000
        break

      case ScreenState.OUTRO_SHOWN:
        this.currentScreen.state = ScreenState.OUTRO_HIDDEN
        delay = 1000
        break

      case ScreenState.OUTRO_HIDDEN:
        this.currentScreen.state = ScreenState.HIDDEN
        this.isReseting = true
        this.nextScreen()
        delay = 50
        break
    }

    if (!this.destroyed) {
      this.screenStateTimer = window.setTimeout(() => this.nextScreenState(), delay)
    }
  }

  clearScreenStateTimer() {
    if (this.screenStateTimer != null) {
      clearTimeout(this.screenStateTimer)
      this.screenStateTimer = null
    }
  }

  clearRefreshTimer() {
    if (this.refreshTimer != null) {
      clearTimeout(this.refreshTimer)
      this.refreshTimer = null
    }
  }

  refreshSize() {
    const width = window.innerWidth
    const height = window.innerHeight
    const ratio = width / height

    if (ratio > SCREEN_RATIO) {
      this.screenWidth = height * SCREEN_RATIO
      this.screenHeight = height
      this.screenMarginTop = 0
      this.screenMarginLeft = (window.innerWidth - this.screenWidth) / 2
    } else {
      this.screenWidth = width
      this.screenHeight = width / SCREEN_RATIO
      this.screenMarginTop = (window.innerHeight - this.screenHeight) / 2
      this.screenMarginLeft = 0
    }

    this.screenFontSize = (16 * this.screenWidth) / 1920
  }
}
</script>
<style scoped lang="scss">
.screen-wrapper {
  width: 100%;
  height: 100%;
  font-family: "Helvetica", sans-serif;
  background: black;
}

.img-center {
  margin: auto;
  display: block;
}

.screen {
  position: relative;
  background: #f7f9f8;

  .screen-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
}
</style>
