// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
declare global {
  interface Navigator {
    connection?: {
      type: string
    }
  }
}

export function isOffline() {
  return window.navigator.connection?.type === "none"
}
