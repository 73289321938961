import { VuexModule, Module, Action, Mutation } from "vuex-class-modules"
import store from ".."
import { ActivityFragment } from "../../queries/client/graphql"
import DateHelper from "../../helpers/DateHelper"
import APIClient from "../../api/ApiClient"
import { Status } from "../../types/Status"
import { setToStorage, getFromStorage, removeItemsFromLocalStorage } from "../../utils/storage"
import { ActivitiesData, getDataActivities } from "../../types/Activity"
import { isOffline } from "../../helpers/ConnectionHelper"
import { loaderModule } from "./loader"

const ACTIVITIES_KEY = "activities"

enum SubStatus {
  Loading = "loading",
  Ready = "ready",
}

type ActivityWithStatus = {
  status: SubStatus
  date: Date
  activities: ActivityFragment[] | null
}

@Module
class ActivityModule extends VuexModule {
  data: { [residenceId: string]: { [dateStr: string]: ActivityWithStatus } } = {}

  status: Status = Status.New
  // ~6 months + 7 extra days due to how range is processed in activities view
  numberOfDays = 7 * 4 * 6 + 7

  get activitiesWithStatus() {
    return (residenceId: string, date: Date) => this.data[residenceId][DateHelper.getDateStringFormat(date)]
  }

  get activities() {
    return (residenceId: string, startDate: Date, endDate: Date) => {
      return Object.keys(this.data[residenceId])
        .filter((dateStr) => {
          const date = new Date(dateStr)
          return startDate.getTime() <= date.getTime() && endDate.getTime() >= date.getTime()
        })
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .map((dateStr) => this.data[residenceId][dateStr])
    }
  }

  @Mutation
  initResidence(residenceId: string) {
    if (this.data[residenceId] == null) {
      this.data[residenceId] = {}
    }
  }

  @Mutation
  removeLoadingData({ residenceId, dates }: { residenceId: string; dates: Date[] }) {
    dates.forEach((date) => {
      const dateStr = DateHelper.getDateStringFormat(date)

      if (this.data[residenceId][dateStr]?.status === SubStatus.Loading) {
        delete this.data[residenceId][dateStr]
      }
    })
    this.data = { ...this.data }
  }

  @Mutation
  setLoadingData({ residenceId, dates }: { residenceId: string; dates: Date[] }) {
    dates.forEach((date) => {
      const dateStr = DateHelper.getDateStringFormat(date)

      if (this.data[residenceId][dateStr] == null) {
        this.data[residenceId][dateStr] = {
          status: SubStatus.Loading,
          date: new Date(dateStr),
          activities: null,
        }
      }
    })
    this.data = { ...this.data }
  }

  @Mutation
  setData({ residenceId, dataActivities, dates }: { residenceId: string; dataActivities: ActivitiesData[]; dates: Date[] }) {
    // Set Activitiess
    dataActivities.forEach((data) => {
      this.data[residenceId][data.dateStr] = {
        status: SubStatus.Ready,
        date: new Date(data.dateStr),
        activities: data.activities,
      }
    })

    // Clean loading states
    dates.forEach((date) => {
      const dateStr = DateHelper.getDateStringFormat(date)

      if (this.data[residenceId][dateStr]?.status === SubStatus.Loading) {
        this.data[residenceId][dateStr].status = SubStatus.Ready
      }
    })
    this.data = { ...this.data }
  }

  @Mutation
  clearData() {
    removeItemsFromLocalStorage(ACTIVITIES_KEY)
    this.data = {}
    this.status = Status.New
  }

  @Mutation
  setStatus(status: Status) {
    this.status = status
  }

  @Action
  async syncActivities(residenceId: string) {
    // Prepare residence
    this.initResidence(residenceId)

    const dates: Date[] = this.getActivitiesDates()

    this.setLoadingData({ residenceId, dates })

    let activities: ActivityFragment[]

    try {
      // Exception will be catched in SyncModule
      activities = await APIClient.getActivities(residenceId, dates[0], this.numberOfDays)
    } catch (error) {
      this.removeLoadingData({ residenceId, dates })
      throw error
    }

    // Cache data
    setToStorage(ACTIVITIES_KEY, residenceId, activities)

    const dataActivities = getDataActivities(dates[0], this.numberOfDays, activities)
    this.setData({ residenceId, dataActivities, dates })
  }

  @Action
  async preloadActivities(residenceId: string) {
    // Prepare residence
    this.initResidence(residenceId)

    const dates: Date[] = this.getActivitiesDates()

    if (this.status === Status.New) {
      this.setStatus(Status.Loading)

      const localActivities = getFromStorage<ActivityFragment[]>(ACTIVITIES_KEY, residenceId)

      if (localActivities != null) {
        const dataActivities = getDataActivities(dates[0], this.numberOfDays, localActivities)

        this.setData({ residenceId, dataActivities, dates })
        this.setStatus(Status.Ready)
      }
    }

    await this.syncActivities(residenceId)

    if (Object.keys(this.data[residenceId]).length === 0) {
      this.setStatus(Status.Error)
    } else {
      this.setStatus(Status.Ready)
    }
  }

  @Action
  async loadActivities({ residenceId, date }: { residenceId: string; date: Date }) {
    // Prepare residence
    this.initResidence(residenceId)

    if (this.status === Status.New) {
      this.setStatus(Status.Loading)

      const localActivities = getFromStorage<ActivityFragment[]>(ACTIVITIES_KEY, residenceId)

      if (localActivities != null) {
        const dataActivities = getDataActivities(date, 1, localActivities)

        this.setData({ residenceId, dataActivities, dates: [date] })
        this.setStatus(Status.Ready)
      }
    }

    const activitiesWithStatus = this.data[residenceId][DateHelper.getDateStringFormat(date)]
    if (activitiesWithStatus != null && activitiesWithStatus.status === SubStatus.Loading) {
      // Loading in progress
      return
    }

    if (!isOffline()) {
      loaderModule.willRefresh()

      this.setLoadingData({ residenceId, dates: [date] })

      try {
        const activities = await APIClient.getActivitiesForDay(residenceId, date)

        const dataActivities = getDataActivities(date, 1, activities)

        this.setData({ residenceId, dataActivities, dates: [date] })
      } catch (error) {
        this.removeLoadingData({ residenceId, dates: [date] })

        // TODO
        console.log(error)
      }

      loaderModule.didRefresh()
    }

    if (Object.keys(this.data[residenceId]).length === 0) {
      this.setStatus(Status.Error)
    } else {
      this.setStatus(Status.Ready)
    }
  }

  private getDates(date: Date, numberOfDays: number) {
    const dates: Date[] = []
    let currentDate

    for (let index = 0; index <= numberOfDays; index += 1) {
      currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + index)
      dates.push(currentDate)
    }
    return dates
  }

  private getActivitiesDates() {
    const startDate = new Date()

    if (startDate.getDay() > 1) {
      startDate.setDate(startDate.getDate() - startDate.getDay() + 1)
    } else if (startDate.getDay() === 0) {
      startDate.setDate(startDate.getDate() - 6)
    }

    return this.getDates(startDate, 30)
  }
}

export const activityModule = new ActivityModule({ store, name: "activity" })
