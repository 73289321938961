<template>
  <screen-card class="card-ephemeris" :title="title">
    <p v-if="data" class="content">{{ content }}</p>
    <p v-else class="info-unavailable">Information indisponible</p>
  </screen-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { EphemerisFragment } from "../../queries/screen/graphql"

@Component({
  components: {
    "screen-card": () => import("./screen-card.vue"),
  },
})
export default class ScreenCardEphemeris extends Vue {
  @Prop({ type: Object, required: false }) data!: EphemerisFragment | undefined

  get title() {
    if (this.data?.gender == null) {
      return "Événement du jour"
    } else {
      return "Saint du jour"
    }
  }

  get content() {
    if (this.data?.gender == null) {
      return this.data?.name
    } else {
      return `${this.data.gender === "MALE" ? "Saint" : "Sainte"} ${this.data.name}`
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../style/variables";

.card-ephemeris {
  background-image: url("~@/assets/images/card-calendar.png");
  background-image: -webkit-image-set(url("~@/assets/images/card-calendar@2x.png") 2x, url("~@/assets/images/card-calendar@3x.png") 3x);
  background-image: image-set(url("~@/assets/images/card-calendar@2x.png") 2x, url("~@/assets/images/card-calendar@3x.png") 3x);
  background-position: bottom right;
  background-size: 23.7%;
  background-repeat: no-repeat;

  .content {
    width: 75%;
    font-size: 275%;
    color: $secondary;
    margin: 2% 0;
    display: inline-block;
  }

  .info-unavailable {
    margin: 5% 0;
    text-align: center;
    font-size: 250%;
    font-weight: 600;
    font-family: "Lobster", sans-serif;
    color: $secondary;
  }
}
</style>
