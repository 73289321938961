<template>
  <div class="list-residence">
    <div class="list-residence-container d-flex align-items-center justify-content-center">
      <Widget>
        <h2 class="title">Veuillez sélectionner une résidence</h2>
        <button v-for="residence in residences" :key="residence.id" class="jda-btn btn-default" @click="onClick(residence)">
          {{ residence.name }}
        </button>
        <div v-if="!residences.length" class="empty"> Aucune residence n'est disponible sur votre compte </div>
        <div>
          <button class="jda-btn btn-danger" @click="logout()"> Déconnexion </button>
        </div>
      </Widget>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import { ResidenceFragment } from "@/queries/client/graphql"
import { sessionModule } from "@/store/modules/session"
import { syncModule } from "@/store/modules/sync"

@Component({
  components: {
    Widget: async () => await import("@/components/widgets/widget.vue"),
  },
})
export default class ListResidence extends Vue {
  get residences() {
    return sessionModule.residences?.sort((a, b) => a.name.localeCompare(b.name)) ?? []
  }

  async logout() {
    await sessionModule.logout()
    await this.$router.push("/login")
  }

  async onClick(residence: ResidenceFragment) {
    sessionModule.setCurrentResidence(residence)

    syncModule.startService()

    await this.$router.replace("/")
  }
}
</script>

<style scoped lang="scss">
.list-residence {
  height: 100%;
  padding: 50px;

  .list-residence-container {
    height: 100%;

    .widget {
      max-width: 550px;
      padding: 32px;

      .title {
        margin-bottom: 24px;
      }

      .empty {
        text-align: center;
        margin-bottom: 24px;
      }

      .btn-primary,
      .btn-danger,
      .btn-default {
        max-width: 500px;
        margin: 16px 0;
      }
    }
  }
}
</style>
