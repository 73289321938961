<template>
  <div class="animations-container">
    <div class="date-container" :class="{ today: isToday }">
      <p>
        <span>{{ weekDay }}</span>
        <br />
        {{ dayMonth }}
      </p>
    </div>
    <div class="activities-container">
      <screen-card-activity v-for="(activity, index) in activities" :key="`${date}-${index}`" :data="activity" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator"
import DateHelper from "../../helpers/DateHelper"
import { ActivityFragment } from "../../queries/screen/graphql"

@Component({
  components: {
    "screen-card-activity": () => import("./screen-card-activity.vue"),
  },
})
export default class ScreenAnimationsDay extends Vue {
  @Prop({ type: Array, required: true }) private readonly data!: ActivityFragment[]
  @Prop({ type: String, required: true }) readonly date!: string

  get activities(): ActivityFragment[] {
    return this.data.sort((a, b) => a.startDateTime.getTime() - b.startDateTime.getTime())
  }

  get dateString(): string {
    return DateHelper.getDateStringFormat(new Date(this.date), "EEEE d MMM")
  }

  get weekDay(): string {
    return this.dateString.slice(0, this.dateString.indexOf(" "))
  }

  get dayMonth(): string {
    return this.dateString.slice(this.dateString.indexOf(" ") + 1)
  }

  get isToday(): boolean {
    return DateHelper.getDateStringFormat(new Date()) === this.date
  }
}
</script>

<style lang="scss">
@import "../../style/_variables.scss";

.animations-container {
  .date-container {
    display: inline-block;
    height: 75%;
    width: 11.3%;
    border-radius: 13px;
    padding: 0.5% 1%;
    margin: 0.8% 2.63% 0.8% 0;
    vertical-align: middle;

    > p {
      text-transform: capitalize;
      font-size: 170%;
      line-height: normal;
      letter-spacing: -0.39px;

      span {
        color: $secondary;
        font-weight: 600;
        font-size: 114.3%;
      }
    }

    &.today {
      background-color: $primary;
      box-shadow: 0 2px 14px 0 rgba(70, 150, 130, 0.24);

      &,
      > p span {
        color: white;
      }
    }
  }

  .activities-container {
    display: inline-block;
    overflow-x: hidden;
    white-space: nowrap;
    vertical-align: middle;
    width: 85%;
    height: 100%;
  }
}
</style>
