import { VuexModule, Module, Action, Mutation } from "vuex-class-modules"
import store from ".."
import { parseJSON, removeItemsFromLocalStorage, setToStorage } from "../../utils/storage"
import { isOffline } from "../../helpers/ConnectionHelper"
import APIClient from "../../api/ApiClient"
import { Status } from "../../types/Status"
import { loaderModule } from "./loader"
import { LinkData } from "../../types/Link"

const LINKS_KEY = "links"

@Module
class LinkModule extends VuexModule {
  data: LinkData[] | null = null
  status: Status = Status.New

  @Mutation
  setData({ data, residenceId }: { data: LinkData[] | null; residenceId: string }) {
    this.data = data

    setToStorage(LINKS_KEY, residenceId, this.data)
  }

  @Mutation
  clearData() {
    removeItemsFromLocalStorage(LINKS_KEY)
    this.data = null
    this.status = Status.New
  }

  @Mutation
  setStatus(status: Status) {
    this.status = status
  }

  @Action
  async loadLinks(residenceId: string) {
    if (this.status === Status.Loading || this.status === Status.Refreshing) {
      return
    }

    if (this.status === Status.New) {
      this.setStatus(Status.Loading)

      this.loadFromStorage(residenceId)
    } else {
      this.setStatus(Status.Refreshing)
    }

    loaderModule.willRefresh()

    try {
      await this.loadFromAPI(residenceId)

      this.setStatus(Status.Ready)
    } catch (error) {
      if (!this.data) {
        this.setStatus(Status.Error)
      } else {
        this.setStatus(Status.Ready)
      }

      // TODO
      console.log(error)
    }
    loaderModule.didRefresh()
  }

  @Action
  loadFromStorage(residenceId: string) {
    if (residenceId) {
      const data = parseJSON<LinkData[]>(localStorage.getItem(`${LINKS_KEY}${residenceId}`))
      this.setData({ data, residenceId })
    }
  }

  @Action
  async loadFromAPI(residenceId: string) {
    if (!residenceId || (isOffline() && this.data == null)) {
      this.setStatus(Status.Error)
      return
    }

    const links = await APIClient.getLinks(residenceId)

    const data: LinkData[] = []

    links.forEach((link) => {
      const index = data.findIndex((value) => value.category.id === link.category.id)

      if (index === -1) {
        data.push({ category: link.category, links: [link] })
      } else {
        data[index].links.push(link)
      }
    })
    this.setData({ data, residenceId })
  }
}

export const linkModule = new LinkModule({ store, name: "link" })
