<template>
  <div class="list-photo">
    <NavigationBar :left-buttons="[{ text: 'Retour', back: true }]" title="Albums photos" />
    <div class="app-view">
      <InternetError v-if="status === 'error'" :retry="loadAlbum" />
      <div v-else-if="status !== 'ready'" class="loader"></div>
      <div v-else>
        <div class="title">
          <b-container>
            <b-row class="justify-content-end align-items-center">
              <b-col md="6">
                <h2>{{ album.title }}</h2>
                <h4 class="date">{{ album.date | date | dateFormat }}</h4>
              </b-col>
              <b-col class="content-right" md="3">
                <h6 class="count"> {{ album.photos.length }} {{ album.photos.length > 1 ? "photos" : "photo" }}</h6>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="images">
          <button v-for="photo in album.photos" :key="photo.id" class="btn-widget-image flex-fill" @click="onClick(photo.id)">
            <WidgetImage :file-id="photo.mediaId" />
          </button>
        </div>
      </div>
    </div>
    <PhotosViewer v-if="album" v-show="showPhotoViewer" v-model="currentPhotoId" :album="album" @close="showPhotoViewer = false" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import APIClient from "@/api/ApiClient"
import { sessionModule } from "@/store/modules/session"
import { GalleryAlbumFragment } from "@/queries/client/graphql"
import { Status } from "@/types/Status"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"

@Component({
  components: {
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
    WidgetImage: async () => await import("@/components/widgets/widget-image.vue"),
    PhotosViewer: async () => await import("@/components/photos-viewer.vue"),
    InternetError: async () => await import("@/components/internet-error.vue"),
  },
})
export default class ListPhoto extends Vue {
  @Prop({ type: String, required: true }) readonly albumId!: string

  album: GalleryAlbumFragment | null = null
  currentPhotoId = ""
  showPhotoViewer = false
  status = Status.New

  async mounted() {
    TrackingHelper.track(AppTrackEvent.galleryAlbumOpened)

    await this.loadAlbum()
  }

  async loadAlbum() {
    const residenceId = sessionModule.currentResidence?.id
    if (!residenceId) {
      return
    }
    this.status = Status.Loading

    try {
      this.album = await APIClient.getAlbum(residenceId, this.albumId)
      this.status = Status.Ready
    } catch {
      this.status = Status.Error
    }
  }

  onClick(photoId: string) {
    this.currentPhotoId = photoId
    this.showPhotoViewer = true
  }
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.list-photo {
  height: 100%;
  display: flex;
  flex-flow: column;

  .title {
    padding-top: 30px;

    .date {
      color: $text-body;
    }

    .content-right {
      padding-top: 20px;

      @media (min-width: 768px) {
        padding-top: 0;
        text-align: right;
      }

      .count {
        display: inline;
        padding: 12px 16px;
        border-radius: 8px;
        background-color: #eaf1ef;
      }
    }
  }

  .images {
    text-align: center;
    padding: 15px;

    .btn-widget-image {
      width: 218px;
      margin: 15px;

      @media (min-width: $xl) {
        width: 256px;
      }
    }
  }
}
</style>
