<template>
  <div class="profile">
    <DefaultView :left-buttons="[{ text: 'Retour', back: true }]" title="Profil">
      <div class="content d-flex flex-column justify-content-between">
        <div>
          <Widget>
            <div class="widget-content">
              <h3 class="name">{{ name }}</h3>
              <div class="sync-status">{{ lastSyncDate }}</div>
              <FormTextField v-model="form" class="fields" :fields="fields" button-label="" :button-visible="false" status="disabled" />

              <button v-if="hasMultiResidences" class="jda-btn btn-primary" @click="changeResidence()">Changer de résidence</button>
              <button class="jda-btn btn-primary" @click="changePassword()">Changer le mot de passe</button>
              <button class="jda-btn btn-danger" @click="showModal">Déconnexion</button>
            </div>
          </Widget>
        </div>

        <div class="version"> Version: {{ appInfo.version }}{{ appInfo.build ? " - Build " + appInfo.build : "" }} </div>
      </div>
    </DefaultView>

    <b-modal id="logout" size="lg" hide-header hide-footer centered>
      <template #default="{ close }">
        <PopupAlert
          title="Etes-vous sûr de vouloir vous déconnecter de l'application Jardins d'Arcadie ?"
          :buttons="['Non', 'Oui']"
          :left-button="close"
          :right-button="openLogin"
        />
      </template>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import TextFieldItem from "@/components/form-text-field/types/TextFieldItem"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import DateHelper from "@/helpers/DateHelper"
import { sessionModule } from "@/store/modules/session"
import { syncModule, SyncStatus } from "@/store/modules/sync"
import { AppInfo } from "@/types/AppInfo"

interface ProfileForm {
  email: string | null
  birthdate: string | null
  arrivalDate: string | null
}

@Component({
  components: {
    PopupAlert: async () => await import("@/components/popups/popup-alert.vue"),
    Widget: async () => await import("@/components/widgets/widget.vue"),
    FormTextField: async () => await import("@/components/form-text-field/form-text-field.vue"),
    DefaultView: async () => await import("@/components/default-view.vue"),
  },
})
export default class Profile extends Vue {
  form: ProfileForm = {
    email: null,
    birthdate: null,
    arrivalDate: null,
  }
  fields: TextFieldItem[] = [{ name: "email", label: "E-mail", type: "email" }]
  name = ""
  appInfo: AppInfo = { version: "1.9.1", build: "46" }

  get hasMultiResidences() {
    return (sessionModule.residences?.length ?? 0) > 1
  }

  get lastSyncDate(): string {
    if (syncModule.status === SyncStatus.SYNCING) {
      return "Synchronisation en cours"
    }

    const currentResidenceId = sessionModule.currentResidence?.id
    if (this.$options.filters?.dateTimeFormat && currentResidenceId) {
      const lastSyncDate = syncModule.lastSyncDate(currentResidenceId)

      if (lastSyncDate) {
        return `Dernière synchronisation le : ${this.$options.filters.dateTimeFormat(lastSyncDate)}`
      }
    }

    return ""
  }

  async mounted() {
    TrackingHelper.track(AppTrackEvent.profileOpened)

    this.name = sessionModule.fullName

    this.form.email = sessionModule.user?.email ?? null
    this.form.birthdate = sessionModule.user?.residentProfile?.birthdate
      ? DateHelper.getDateStringFormat(new Date(sessionModule.user.residentProfile.birthdate), "dd/MM/yyyy")
      : null
    this.form.arrivalDate = sessionModule.user?.residentProfile?.activeStay.arrivalDate
      ? DateHelper.getDateStringFormat(new Date(sessionModule.user.residentProfile.activeStay.arrivalDate), "dd/MM/yyyy")
      : null

    if (this.form.birthdate) {
      this.fields.push({ name: "birthdate", label: "Date de naissance", type: "text" })
    }

    if (this.form.arrivalDate) {
      this.fields.push({ name: "arrivalDate", label: "Date d'arrivée", type: "text" })
    }

    if (window.cordova?.getAppVersion.getVersionNumber) {
      const versionNumber = await window.cordova?.getAppVersion.getVersionNumber()
      if (versionNumber) {
        this.appInfo.version = versionNumber
      }
    }

    const versionCode = await window.cordova?.getAppVersion.getVersionCode()
    if (versionCode) {
      this.appInfo.build = versionCode
    }
  }

  async changePassword() {
    TrackingHelper.track(AppTrackEvent.profileChangedPasswordClicked)
    await this.$router.push("change-password")
  }

  async changeResidence() {
    await this.$router.push("list-residence")
  }

  showModal() {
    this.$bvModal.show("logout")
  }

  async openLogin() {
    await sessionModule.logout()
    await this.$router.replace("login")
  }
}
</script>

<style scoped lang="scss">
.profile {
  .content {
    padding: 20px;

    .widget {
      width: 100%;
      max-width: 550px;
      margin: 50px auto;

      .widget-content {
        padding: 30px 32px;

        .name {
          padding-bottom: 30px;
        }

        .sync-status {
          text-align: center;
          padding-bottom: 30px;
        }

        .btn-primary {
          margin-bottom: 24px;
        }
      }
    }

    .version {
      width: 100%;
      text-align: center;
    }
  }

  .fields {
    margin-bottom: 38px;
  }
}
</style>
