<template>
  <div class="menu-item" :class="{ 'next-days': isNextDays }">
    <p v-if="!hasData" class="coming-soon" :class="{ 'next-days': isNextDays }">Prochainement disponible...</p>
    <template v-else>
      <p v-if="starter && starter.content.length">
        <span v-for="(mealContent, index) in starter.content" :key="`starter-${index}`" class="content">{{ mealContent.label }}</span>
      </p>
      <p v-if="main && main.content.length">
        <span v-for="(mealContent, index) in main.content" :key="`main-${index}`" class="content">{{ mealContent.label }}</span>
      </p>
      <p v-if="desert && desert.content.length">
        <span v-for="(mealContent, index) in desert.content" :key="`desert-${index}`" class="content">{{ mealContent.label }}</span>
      </p>
    </template>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator"
import { MealContentDataFragment, MealContentType, MealFragment } from "../../queries/screen/graphql"

@Component
export default class ScreenMenuItem extends Vue {
  @Prop({ type: Object, required: true }) private readonly data!: MealFragment
  @Prop({ type: Boolean, required: false, default: false }) readonly isNextDays!: boolean

  get starter(): MealContentDataFragment | null {
    return this.data.content.find((content) => content.type === MealContentType.Starter) ?? null
  }

  get main(): MealContentDataFragment | null {
    return this.data.content.find((content) => content.type === MealContentType.Main) ?? null
  }

  get desert(): MealContentDataFragment | null {
    return this.data.content.find((content) => content.type === MealContentType.Desert) ?? null
  }

  get hasData(): boolean {
    return this.starter != null || this.main != null || this.desert != null
  }
}
</script>

<style lang="scss">
@import "../../style/_variables.scss";

.menu-item {
  font-size: 250%;
  font-weight: 500;
  text-align: center;

  &.next-days {
    font-size: 162.5%;
  }

  > p {
    margin-bottom: 5%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .coming-soon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    font-size: 165%;
    font-weight: 600;
    font-family: "Lobster", sans-serif;
    color: $highlight;

    &.next-days {
      position: relative;
      margin: 0;
      transform: none;
    }
  }

  .content {
    display: block;
    line-height: normal;

    &:first-letter {
      text-transform: capitalize;
    }
  }
}
</style>
