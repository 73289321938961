<template>
  <div class="card-menu-day-item">
    <p class="title">{{ title }}</p>
    <p v-if="subtitle" class="subtitle">{{ subtitle }}</p>
    <hr />
    <screen-menu-item v-if="data" :data="data" is-next-days />
    <p v-else class="coming-soon">Prochainement disponible...</p>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator"
import { MealFragment } from "../../queries/screen/graphql"

@Component({
  components: {
    "screen-menu-item": () => import("./screen-menu-item.vue"),
  },
})
export default class ScreenCardMenuDayItem extends Vue {
  @Prop({ type: Object, required: false, default: null }) readonly data!: MealFragment | null
  @Prop({ type: String, required: true }) readonly title!: string
  @Prop({ type: String, required: false, default: null }) readonly subtitle!: string | null
}
</script>

<style lang="scss">
@import "../../style/_variables.scss";

.screen-card .card-menu-day-item {
  position: relative;
  border: solid 1px #dfece9;
  border-radius: 8px;
  padding: 2.5%;

  hr {
    width: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
    border-top: 1px solid #e8e8e8;
  }

  .title {
    text-align: center;
    margin: 0;
    color: $secondary;
    font-size: 187.5%;
    font-weight: 600;
  }

  .subtitle {
    text-align: center;
    color: $secondary;
    font-size: 125%;
    margin-top: 0.4%;
    margin-bottom: 0;
  }

  .coming-soon {
    margin: 15% 0;
    text-align: center;
    font-size: 250%;
    font-weight: 600;
    font-family: "Lobster", sans-serif;
    color: $secondary;
  }
}
</style>
