<template>
  <div class="progress-bar">
    <div class="progress" :style="{ width: `${progress}%` }"></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class ProgressBar extends Vue {
  @Prop({ type: Number, required: true }) readonly progress!: number
}
</script>

<style lang="scss">
@import "@/style/variables";

.progress-bar {
  width: 100%;
  height: 20px;
  position: relative;
  border-radius: 10px;
  background-color: $activity-morning;

  .progress {
    height: 20px;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $secondary;
  }
}
</style>
