import { VuexModule, Module, Action, Mutation } from "vuex-class-modules"
import store from ".."
import { getFromStorage, removeItemsFromLocalStorage, setToStorage } from "../../utils/storage"
import APIClient from "../../api/ApiClient"
import { Status } from "../../types/Status"
import { WidgetFragment } from "../../queries/client/graphql"

const WIDGETS_KEY = "widgets"

@Module
class WidgetModule extends VuexModule {
  data: { [residenceId: string]: WidgetFragment[] } = {}

  status: Status = Status.New

  get widgets() {
    return (residenceId: string, date: Date) => {
      return this.data[residenceId].filter(
        (widget) => date.getTime() >= new Date(widget.startDateTime).getTime() && date.getTime() <= new Date(widget.endDateTime).getTime()
      )
    }
  }

  @Mutation
  initResidence(residenceId: string) {
    if (this.data[residenceId] == null) {
      this.data[residenceId] = []
    }
  }

  @Mutation
  setData({ residenceId, widgets }: { residenceId: string; widgets: WidgetFragment[] }) {
    this.data[residenceId] = widgets
  }

  @Mutation
  clearData() {
    removeItemsFromLocalStorage(WIDGETS_KEY)
    this.data = {}
    this.status = Status.New
  }

  @Mutation
  setStatus(status: Status) {
    this.status = status
  }

  @Action
  async syncWidgets(residenceId: string) {
    // Prepare residence
    this.initResidence(residenceId)

    const dates: Date[] = []
    const currentDate = new Date()

    for (let index = 0; index < 30; index += 1) {
      dates.push(currentDate)
      currentDate.setDate(currentDate.getDate() + 1)
    }

    // Exception will be catched in SyncModule
    const widgets = await APIClient.getWidgets(residenceId, new Date(), 30)

    // Cache data
    setToStorage(WIDGETS_KEY, residenceId, widgets)

    this.setData({ residenceId, widgets })
  }

  @Action
  async preloadWidgets(residenceId: string) {
    // Prepare residence
    this.initResidence(residenceId)

    if (this.status === Status.New) {
      this.setStatus(Status.Loading)

      const localWidgets = getFromStorage<WidgetFragment[]>(WIDGETS_KEY, residenceId)

      if (localWidgets != null) {
        this.setData({ residenceId, widgets: localWidgets })
        this.setStatus(Status.Ready)
      }
    }

    await this.syncWidgets(residenceId)

    if (this.data[residenceId] == null) {
      this.setStatus(Status.Error)
    } else {
      this.setStatus(Status.Ready)
    }
  }
}

export const widgetModule = new WidgetModule({ store, name: "widget" })
