<template>
  <div class="links">
    <NavigationBar :left-buttons="[{ text: 'Retour', back: true }]" title="Liens utiles" />
    <div class="app-view">
      <InternetError v-if="status === 'error'" :retry="loadLinks" />
      <div v-else-if="status === 'new' || status === 'loading'" class="loader"></div>
      <template v-else-if="linkData">
        <div v-for="(item, index) in linkData" :key="item.category.id" class="d-flex flex-column flex-shrink-0">
          <h1 class="title">
            {{ item.category.name }}
          </h1>
          <div class="d-flex flex-wrap">
            <div v-for="link in item.links" :key="link.id" class="widget">
              <WidgetLink class="widget-link" :link="link" />
            </div>
          </div>
          <div v-if="index !== linkData.length - 1" class="border-link">
            <div class="border-horizontal"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import { linkModule } from "@/store/modules/link"
import { sessionModule } from "@/store/modules/session"

@Component({
  components: {
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
    InternetError: async () => await import("@/components/internet-error.vue"),
    WidgetLink: async () => await import("@/components/widgets/widget-link.vue"),
  },
})
export default class Links extends Vue {
  get linkData() {
    return linkModule.data
  }

  get status() {
    return linkModule.status
  }

  get currentResidenceId() {
    return sessionModule.currentResidence?.id
  }

  beforeMount() {
    TrackingHelper.track(AppTrackEvent.linksOpened)
    this.loadLinks()
  }

  loadLinks() {
    if (!this.currentResidenceId) {
      return
    }

    linkModule.loadLinks(this.currentResidenceId)
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.links {
  height: 100%;
  display: flex;
  flex-flow: column;

  .app-view {
    padding: 30px;

    .title {
      text-align: left;
      padding: 10px;
      @include title-secondary-style();
    }

    .widget {
      padding: 10px;
      width: 100%;
    }

    .border-link {
      width: 100%;
      padding: 20px 10px;
    }
  }
}
</style>
