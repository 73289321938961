<template>
  <div class="widget-list">
    <div class="widget">
      <InternetError v-if="teamStatus === 'error'" :retry="loadTeam" />
      <div v-else-if="teamStatus === 'new' || teamStatus === 'loading' || teamWithStatus.status === 'loading'" class="loader"></div>
      <div v-else-if="team">
        <WidgetTeam :team="team" />
      </div>
    </div>
    <div class="widget">
      <WidgetWeather />
    </div>
    <div>
      <InternetError v-if="widgetStatus === 'error'" :retry="loadWidgets" />
      <div v-else-if="widgetStatus === 'new' || widgetStatus === 'loading'" class="loader"></div>
      <div v-else-if="widgets">
        <WidgetEvent v-for="(widget, index) in widgets" :key="`widget-${index}`" class="widget" :widget="widget" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import { sessionModule } from "@/store/modules/session"
import { showError } from "@/helpers/UtilsHelper"
import { teamModule } from "@/store/modules/team"
import { widgetModule } from "@/store/modules/widget"

@Component({
  components: {
    WidgetTeam: async () => await import("@/components/widgets/widget-team.vue"),
    WidgetWeather: async () => await import("@/components/widgets/widget-weather.vue"),
    WidgetEvent: async () => await import("@/components/widgets/widget-event.vue"),
    InternetError: async () => await import("@/components/internet-error.vue"),
  },
})
export default class WidgetList extends Vue {
  get teamStatus() {
    return teamModule.status
  }

  get teamWithStatus() {
    const residenceId = sessionModule.currentResidence?.id ?? null
    if (residenceId == null) {
      return null
    }

    return teamModule.teamWithStatus(residenceId, new Date())
  }

  get team() {
    return this.teamWithStatus?.team?.teamMembers.length ? this.teamWithStatus.team : null
  }

  get widgetStatus() {
    return widgetModule.status
  }

  get widgets() {
    const residenceId = sessionModule.currentResidence?.id ?? null
    if (residenceId == null) {
      return null
    }

    return widgetModule.widgets(residenceId, new Date())
  }

  beforeMount() {
    this.loadTeam()
    this.loadWidgets()
  }

  loadTeam() {
    const residenceId = sessionModule.currentResidence?.id ?? null
    if (!residenceId) {
      return
    }

    teamModule.loadTeam({ residenceId, date: new Date() })
  }

  loadWidgets() {
    const residenceId = sessionModule.currentResidence?.id ?? null
    if (!residenceId) {
      return
    }

    widgetModule.preloadWidgets(residenceId)
  }

  showError() {
    showError(
      this.$bvToast,
      "Chargement de l'accueil",
      new Error(
        "Un problème lors de la récupération des informations de la page d'accueil s'est produit. Veuillez vérifier votre connexion internet et réessayer"
      )
    )
  }
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.widget-list {
  padding: 28px 15px;

  @media (min-width: $md), (orientation: landscape) {
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .widget {
    padding: 12px 0;
  }

  h1 {
    padding: 12px;
  }
}
</style>
