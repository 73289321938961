<template>
  <div class="widget-secondary">
    <Widget>
      <div v-if="title" class="title-info">
        <h4 class="title">{{ title }}</h4>
      </div>
      <slot></slot>
    </Widget>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  components: {
    Widget: async () => await import("./widget.vue"),
  },
})
export default class WidgetSecondary extends Vue {
  @Prop({ type: String, required: true }) readonly title!: string
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.widget-secondary {
  .title-info {
    width: 100%;
    padding: 24px;
    border-top-left-radius: 8px 8px;
    border-top-right-radius: 8px 8px;
    background-color: rgba(199, 223, 217, 0.5);

    .title {
      color: $secondary;
      text-align: left;
    }
  }
}
</style>
