<template>
  <div class="forget-password home-view d-flex align-items-center justify-content-center">
    <div v-if="status !== 'success'" class="d-flex">
      <div class="back">
        <ButtonBack class="jda-btn btn-secondary" secondary />
      </div>
    </div>

    <GenerateTokenForm v-if="status === 'init'" class="content" @success="onSuccessGenerate" />
    <TokenForm v-else-if="status === 'generate'" class="content" :email="internalEmail" :token="token" @success="status = 'success'" />
    <Widget v-else class="content success-content">
      <h3 class="title">Votre mot de passe<br />a été réinitiliasé</h3>
      <button class="jda-btn btn-highlight" @click="$router.go(-1)">Retour à la connexion</button>
    </Widget>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  components: {
    ButtonBack: async () => await import("@/components/buttons/button-back.vue"),
    Widget: async () => await import("@/components/widgets/widget.vue"),
    TokenForm: async () => await import("@/components/forgot-password/token-form.vue"),
    GenerateTokenForm: async () => await import("@/components/forgot-password/generate-token-form.vue"),
  },
})
export default class ForgetPassword extends Vue {
  @Prop({ type: String, required: false }) readonly token!: string | null
  @Prop({ type: String, required: false }) readonly email!: string | null

  internalEmail: string | null = null

  status: "init" | "generate" | "success" = "init"

  mounted() {
    if (this.email && this.token) {
      // Directly go to the token page
      this.onSuccessGenerate(this.email)
    }
  }

  onSuccessGenerate(email: string) {
    this.internalEmail = email
    this.status = "generate"
  }
}
</script>

<style scoped lang="scss">
.forget-password {
  height: 100%;

  .back {
    position: absolute;
    top: 40px;
    left: 40px;
  }

  .content {
    max-width: 500px;
    padding: 49px 32px 32px 32px;
    margin: 32px;
    text-align: left;

    &.success-content {
      max-width: 500px;
      padding-top: 64px;

      .title {
        margin-bottom: 56px;
        text-align: center;
      }
    }
  }
}
</style>
