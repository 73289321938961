<template>
  <div class="services-catalog">
    <NavigationBar :left-buttons="[{ text: 'Retour', back: true }]" title="Services à la carte" />
    <div class="app-view">
      <InternetError v-if="status === 'error'" :retry="loadServices" />
      <div v-else-if="status === 'new' || status === 'loading'" class="loader"></div>
      <b-container v-else-if="services">
        <b-row>
          <b-col v-for="service in services" :key="service.id" md="6">
            <div class="service">
              <WidgetService :service="service" />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import { serviceModule } from "@/store/modules/service"
import { sessionModule } from "@/store/modules/session"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"

@Component({
  components: {
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
    WidgetService: async () => await import("@/components/widgets/widget-service.vue"),
    InternetError: async () => await import("@/components/internet-error.vue"),
  },
})
export default class ServicesCatalog extends Vue {
  get services() {
    return serviceModule.services
  }

  get status() {
    return serviceModule.status
  }

  get currentResidenceId() {
    return sessionModule.currentResidence?.id
  }

  beforeMount() {
    this.loadServices()
    TrackingHelper.track(AppTrackEvent.servicesOpened)
  }

  loadServices() {
    if (!this.currentResidenceId) {
      return
    }

    serviceModule.loadServices(this.currentResidenceId)
  }
}
</script>

<style scoped lang="scss">
.services-catalog {
  height: 100%;
  display: flex;
  flex-flow: column;

  .service {
    padding-top: 40px;
    padding-bottom: 42px;
  }
}
</style>
