import { API_URL } from "../api/url"
import { FileSize } from "./FileSize"

const mapped2XableFileSizes: Record<string, FileSize> = {
  [FileSize.WIDTH_HD]: FileSize.WIDTH_HD_2X,
  [FileSize.FULL_HD]: FileSize.FULL_HD_2X,
  [FileSize.SMALL_SQUARE]: FileSize.SMALL_SQUARE_2X,
  [FileSize.MEDIUM_SQUARE]: FileSize.MEDIUM_SQUARE_2X,
  [FileSize.LARGE_SQUARE]: FileSize.LARGE_SQUARE_2X,
}

export function getRealFilePath(fileUuid: string, path: "screen" | "client", size = FileSize.ORIGINAL) {
  const realSize = window.devicePixelRatio > 1 ? mapped2XableFileSizes[size] ?? size : size

  return `${API_URL}/${path}/media/${fileUuid}?size=${realSize}`
}
