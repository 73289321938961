<template>
  <div class="internet-error d-flex flex-column justify-content-center align-items-center">
    <h2>Connexion Internet requise</h2>
    <div class="sub-title"> Pour afficher cette page, veuillez vous connecter à Internet et réessayer. </div>
    <button class="jda-btn btn-highlight" @click="retry">Réessayer</button>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class InternetError extends Vue {
  @Prop({ type: Function, required: true }) readonly retry!: () => void
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.internet-error {
  height: 100%;
  padding: 20px;
  min-height: 0;

  .sub-title {
    max-width: 415px;
    margin: 30px 0;
    text-align: center;
    @include body();
  }

  .jda-btn {
    width: 245px;
  }
}
</style>
