<template>
  <div class="widget-activity">
    <Widget class="d-flex align-items-center align-self-center" :class="{ 'current-activity': isCurrent }">
      <div class="icon-type" :class="{ morning: isMorning, afternoon: !isMorning }">
        <ImageUrl v-if="activity.icon && !isOffline" class="icon" :file-id="activity.icon.mediaId" :size="FileSize.SMALL_SQUARE" />
        <img
          v-else
          class="icon"
          src="@/assets/images/icon-default.png"
          srcset="@/assets/images/icon-default@2x.png 2x, @/assets/images/icon-default@3x.png 3x"
        />
      </div>
      <div class="content">
        {{ activity.name }}
        <div class="hour">
          <img class="icon-time" src="@/assets/images/time.png" srcset="@/assets/images/time@2x.png 2x, @/assets/images/time@3x.png 3x" />
          {{ activity.startDateTime | date | timeFormat }} - {{ activity.endDateTime | date | timeFormat }}
        </div>
      </div>
      <b-button class="btn-widget" variant="transparent" @click="showActivity()"> </b-button>
    </Widget>

    <b-modal :id="activity.id" size="lg" hide-header hide-footer centered>
      <template #default="{ close }">
        <PopupActivity :activity="activity" :close="close" />
      </template>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { ActivityFragment } from "@/queries/client/graphql"
import { isOffline } from "@/helpers/ConnectionHelper"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import { FileSize } from "@/types/FileSize"

@Component({
  components: {
    Widget: async () => await import("./widget.vue"),
    PopupActivity: async () => await import("@/components/popups/popup-activity.vue"),
    ImageUrl: async () => await import("@/components/image-url.vue"),
  },
})
export default class WidgetActivity extends Vue {
  @Prop({ type: Object, required: true }) readonly activity!: ActivityFragment

  FileSize = FileSize

  isCurrent = false
  isMorning = false

  get isOffline() {
    return isOffline()
  }

  mounted() {
    const now = new Date()
    const startDateTime = new Date(this.activity.startDateTime)
    const endDateTime = new Date(this.activity.endDateTime)

    this.isCurrent = now.getTime() >= startDateTime.getTime() && now.getTime() <= endDateTime.getTime()
    this.isMorning = startDateTime.getHours() < 14
  }

  showActivity() {
    this.$bvModal.show(this.activity.id)
    TrackingHelper.track(AppTrackEvent.activitiesItemClicked, { activityId: this.activity.id })
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.widget-activity {
  min-width: 230px;
  padding: 0;
  text-align: left;

  .widget {
    height: 72px;
    padding: 0 12px;
    position: relative;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);

    &.current-activity {
      border: 2px solid $primary;
    }

    .btn-widget {
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .content {
      flex-shrink: 1;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 400px;
      @include title-body();

      .hour {
        @include body3();
        font-weight: normal;

        .icon-time {
          width: 14px;
          height: 14px;
        }
      }
    }

    .icon-type {
      width: 40px;
      height: 40px;
      margin-right: 12px;
      padding: 0 12px;
      border-radius: 8px;
      text-align: center;

      .icon {
        width: 16px;
        height: 100%;
        opacity: 0.5;
        object-fit: contain;
      }

      &.morning {
        background-color: $activity-morning;
      }

      &.afternoon {
        background-color: $activity-afternoon;
      }
    }
  }
}
</style>
