<template>
  <div class="albums">
    <NavigationBar :left-buttons="[{ text: 'Retour', back: true }]" title="Albums photos" />
    <div class="app-view">
      <InternetError v-if="status === 'error'" :retry="loadAlbums" />
      <div v-else-if="status !== 'ready'" class="loader"></div>
      <div v-else>
        <div v-if="newAlbums.length > 0" class="new-album">
          <h1 class="new-title"> Nouveautés </h1>
          <div class="new-album-container">
            <div v-for="album in newAlbums" :key="album.id" class="album">
              <AlbumGroup :album="album" />
            </div>
          </div>
        </div>
        <div v-if="albums">
          <div v-for="albumByYear in albums" :key="`album-${albumByYear.year}`" class="albums-container">
            <h1 class="year">
              {{ albumByYear.year }}
            </h1>
            <div class="d-flex flex-wrap">
              <div v-for="album in albumByYear.albums" :key="album.id" class="album flex-fill">
                <AlbumGroup :album="album" />
              </div>
            </div>
            <div class="border-horizontal"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import APIClient from "@/api/ApiClient"
import { sessionModule } from "@/store/modules/session"
import { GalleryAlbumFragment } from "@/queries/client/graphql"
import { Status } from "@/types/Status"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"

type AlbumsByYear = {
  year: number
  albums: GalleryAlbumFragment[]
}

@Component({
  components: {
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
    InternetError: async () => await import("@/components/internet-error.vue"),
    AlbumGroup: async () => await import("@/components/album-group.vue"),
  },
})
export default class Albums extends Vue {
  albums: AlbumsByYear[] = []
  newAlbums: GalleryAlbumFragment[] = []
  status = Status.New

  async mounted() {
    TrackingHelper.track(AppTrackEvent.galleryOpened)

    await this.loadAlbums()
  }

  async loadAlbums() {
    const residenceId = sessionModule.currentResidence?.id
    if (!residenceId) {
      return
    }
    this.status = Status.Loading

    try {
      const albums = await APIClient.getAlbums(residenceId)

      this.setNewAlbums(albums)
      this.setAlbums(albums)
      this.status = Status.Ready
    } catch {
      this.status = Status.Error
    }
  }

  setNewAlbums(albums: GalleryAlbumFragment[]) {
    albums.sort((a, b) => new Date(b.lastUpdateDateTime).getTime() - new Date(a.lastUpdateDateTime).getTime())
    const newAlbums = albums
      .filter((album) => {
        const now = new Date()
        now.setDate(now.getDate() - 7)

        return new Date(album.lastUpdateDateTime) >= now
      })
      .slice(0, 3)

    this.newAlbums = newAlbums
  }

  setAlbums(albums: GalleryAlbumFragment[]) {
    for (let index = 0; index < albums.length; index += 1) {
      const album = albums[index]
      const date = new Date(album.date)
      const albumsIndex = this.albums.findIndex((value) => value.year === date.getFullYear())

      if (albumsIndex >= 0) {
        this.albums[albumsIndex].albums.push(album)
      } else {
        this.albums.push({ year: date.getFullYear(), albums: [album] })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.albums {
  height: 100%;
  display: flex;
  flex-flow: column;

  .app-view {
    padding: 30px;
  }

  .new-album {
    margin-bottom: 30px;
    padding: 10px 0;
    border-radius: 12px;
    background-color: $background-primary;

    .new-title {
      padding: 0 20px 10px 20px;
      font-family: "Lobster", sans-serif;
    }

    .new-album-container {
      text-align: center;
      overflow-x: auto;
      white-space: nowrap;

      .album {
        margin: 0 20px;
        display: inline-block;
      }
    }
  }

  .albums-container {
    .year {
      text-align: left;
      padding-bottom: 5px;
      font-family: "Lobster", sans-serif;
    }

    .album {
      max-width: 321px;

      @media (max-width: $md) {
        max-width: none;

        .album-group {
          margin: 0 auto;
        }
      }
    }

    .border-horizontal {
      margin: 20px 0;
    }

    &:last-child {
      .border-horizontal {
        display: none;
      }
    }
  }
}
</style>
