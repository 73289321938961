<template>
  <screen-base title="" sub-title="" class="screen-placeholder">
    <p>{{ content }}</p>
  </screen-base>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  components: {
    "screen-base": () => import("./screen-base.vue"),
  },
})
export default class ScreenEphemeris extends Vue {
  @Prop({ type: String, required: true }) readonly content!: string
}
</script>
<style scoped lang="scss">
@import "../../style/variables";

.screen-placeholder {
  p {
    color: $secondary;
    font-size: 500%;
    font-family: "Lobster", sans-serif;
    font-weight: normal;
    text-align: center;
    margin-top: 20%;
    margin-bottom: 0;
  }
}
</style>
