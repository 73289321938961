import { VuexModule, Module, Action, Mutation } from "vuex-class-modules"
import store from ".."
import { parseJSON, removeItemsFromLocalStorage, setToStorage } from "../../utils/storage"
import { isOffline } from "../../helpers/ConnectionHelper"
import APIClient from "../../api/ApiClient"
import { Status } from "../../types/Status"
import { loaderModule } from "./loader"
import { ServiceFragment } from "../../queries/client/graphql"

const SERVICES_KEY = "services"

@Module
class ServiceModule extends VuexModule {
  services: ServiceFragment[] | null = null
  status: Status = Status.New

  @Mutation
  setData({ services, residenceId }: { services: ServiceFragment[] | null; residenceId: string }) {
    this.services = services

    setToStorage(SERVICES_KEY, residenceId, this.services)
  }

  @Mutation
  clearData() {
    removeItemsFromLocalStorage(SERVICES_KEY)
    this.services = null
    this.status = Status.New
  }

  @Mutation
  setStatus(status: Status) {
    this.status = status
  }

  @Action
  async loadServices(residenceId: string) {
    if (this.status === Status.Loading || this.status === Status.Refreshing) {
      return
    }

    if (this.status === Status.New) {
      this.setStatus(Status.Loading)

      this.loadFromStorage(residenceId)
    } else {
      this.setStatus(Status.Refreshing)
    }

    loaderModule.willRefresh()

    try {
      await this.loadFromAPI(residenceId)

      this.setStatus(Status.Ready)
    } catch (error) {
      if (!this.services) {
        this.setStatus(Status.Error)
      } else {
        this.setStatus(Status.Ready)
      }

      // TODO
      console.log(error)
    }
    loaderModule.didRefresh()
  }

  @Action
  loadFromStorage(residenceId: string) {
    if (residenceId) {
      const services = parseJSON<ServiceFragment[]>(localStorage.getItem(`${SERVICES_KEY}${residenceId}`))
      this.setData({ services, residenceId })
    }
  }

  @Action
  async loadFromAPI(residenceId: string) {
    if (!residenceId || (isOffline() && this.services == null)) {
      this.setStatus(Status.Error)
      return
    }

    const services = await APIClient.getServices(residenceId)

    this.setData({ services, residenceId })
  }
}

export const serviceModule = new ServiceModule({ store, name: "service" })
