<template>
  <div class="pdf-viewer">
    <NavigationBar :left-buttons="[{ text: 'Retour', back: true }]" :title="title" />
    <div class="app-view">
      <PdfDocument :pdf-id="pdfId" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  components: {
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
    PdfDocument: async () => await import("@/components/pdf-document/pdf-document.vue"),
  },
})
export default class PdfViewer extends Vue {
  @Prop({ type: String, required: true }) readonly pdfId!: string
  @Prop({ type: String, required: false, default: "PDF" }) readonly title!: string
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.pdf-viewer {
  height: 100%;
  display: flex;
  flex-flow: column;

  .app-view {
    padding: 40px;

    @media (max-width: $sm), (max-width: $md) and (orientation: landscape) {
      padding: 20px;
    }
  }
}
</style>
