// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    plugin?: any
    _env: {
      API_URL?: string | null
      MIXPANEL_TOKEN?: string | null
      GOOGLE_MAPS_BROWSER_API_KEY?: string | null
      SENTRY_DSN?: string | null
    }
  }
}

let apiUrl: string | null = null

if (window._env != null && window._env.API_URL != null) {
  apiUrl = window._env.API_URL
} else if (process.env.API_URL != null) {
  apiUrl = process.env.API_URL
}

export const API_URL = apiUrl
