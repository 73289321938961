<template>
  <div class="date-pick">
    <table class="m-auto">
      <thead class="header">
        <tr class="header-month">
          <th colspan="7">
            <div class="month">
              <select v-model="displayedDate">
                <option v-for="(month, index) in monthOptions" :key="`option-${index}`" :value="month" :selected="isSameMonth(month)">
                  {{ month | monthYearFormat | capitalize }}
                </option>
              </select>
              <img
                class="img-dropdown"
                src="@/assets/images/dropdown.png"
                srcset="@/assets/images/dropdown@2x.png 2x, @/assets/images/dropdown@3x.png 3x"
              />
            </div>
          </th>
        </tr>
        <tr>
          <th colspan="7">
            <div class="header-border">
              <div class="border-content"></div>
            </div>
          </th>
        </tr>
        <tr>
          <th v-for="(weekday, index) in weekdays" :key="`weekday-${index}`" class="weekday">
            {{ weekday | capitalize }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(week, weekIndex) in weeks" :key="`week-${weekIndex}`">
          <td v-for="(date, index) in week" :key="`date-${index}`">
            <button
              class="btn-date"
              :class="{
                'btn-current-month': date.getMonth() === displayedDate.getMonth(),
                'btn-selected': isSameDay(selectedDate, date),
                'btn-current': isSameDay(date),
              }"
              @click="selectDate(date)"
            >
              {{ date.getDate() }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Model } from "vue-property-decorator"
import { isSameDay } from "date-fns"
import DateHelper from "@/helpers/DateHelper"

@Component
export default class DatePick extends Vue {
  @Model("change", { type: Date }) readonly selectedDate!: Date

  displayedDate = new Date()
  weekdays = DateHelper.getShortWeekDays()
  weeks: Array<Date[]> = []

  get monthOptions() {
    const months: Array<Date> = []

    const now = new Date()
    const date = new Date(now.getFullYear(), now.getMonth() - 3, 1)

    for (let index = 0; index < 6; index++) {
      months.push(new Date(date.getFullYear(), date.getMonth(), 1))
      date.setMonth(date.getMonth() + 1)
    }

    return months
  }

  selectDate(date: Date) {
    this.$emit("change", new Date(date))
  }

  isSameMonth(date: Date): boolean {
    return this.displayedDate.getMonth() === date.getMonth() && this.displayedDate.getFullYear() === date.getFullYear()
  }

  isSameDay(dateLeft: Date, dateRight: Date = new Date()): boolean {
    return isSameDay(dateLeft, dateRight)
  }

  @Watch("selectedDate", { immediate: true, deep: true })
  onSelectedDateChanged() {
    this.displayedDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1)
  }

  @Watch("displayedDate", { immediate: true })
  updateCalendar() {
    this.weeks = []
    const dates: Date[] = []

    let currentDate = new Date(this.displayedDate)
    while (currentDate.getDay() !== 1) {
      currentDate.setDate(currentDate.getDate() - 1)
      dates.splice(0, 0, new Date(currentDate))
    }

    currentDate = new Date(this.displayedDate)
    while (this.displayedDate.getMonth() === currentDate.getMonth()) {
      dates.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    while (currentDate.getDay() !== 1) {
      dates.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    for (let index = 0; index < dates.length; index += this.weekdays.length) {
      this.weeks.push(dates.slice(index, index + this.weekdays.length))
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.date-pick {
  .header-month {
    text-align: center;

    .month {
      display: inline-flex;
      position: relative;
      margin-bottom: 10px;
      padding: 3px 0;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
      background-color: white;
      border-radius: 9.6px;

      select {
        @include title-h3();
        z-index: 10;
        padding: 0 33px 0 15px;
        font-weight: normal;
        border: none;
        color: $secondary;
        background-color: transparent;
        text-align-last: center;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      .img-dropdown {
        position: absolute;
        top: 10px;
        bottom: 10px;
        right: 15px;
        object-fit: contain;
      }
    }
  }

  .header-border {
    width: 100%;
    height: 2px;
    padding: 0 20px;

    .border-content {
      width: 100%;
      height: 100%;
      background-color: $border;
    }
  }

  .weekday {
    padding-top: 19px;
    padding-bottom: 25px;
    @include title-body();
    text-align: center;
  }

  .btn-date {
    width: 61px;
    height: 65px;
    margin: 0 5px;
    border-radius: 28px;
    @include body2();
    color: $disabled;

    @media (max-width: $xl) {
      width: 37px;
      height: 41px;
    }

    &.btn-current-month {
      color: $text-body;
    }

    &.btn-current {
      color: $danger;
    }

    &.btn-selected {
      color: white;
      font-weight: bold;
      background-color: $primary;
      box-shadow: 0 2px 14px 0 rgba(70, 150, 130, 0.24);
    }
  }
}
</style>
