<template>
  <screen-base title="Galerie photos" :sub-title="subtitle" class="screen-album">
    <div v-for="(photo, index) in currentPhotos" :key="`screen-album-photo-${index}`" :class="`photo-container ${Display[displayMode]}`">
      <img :src="photo" class="screen-photo" />
    </div>
  </screen-base>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator"
import DateHelper from "../../helpers/DateHelper"
import { GalleryAlbumFragment } from "../../queries/screen/graphql"
import { getRealFilePath } from "../../types/File"
import { FileSize } from "../../types/FileSize"

enum Display {
  solo = 1,
  duo,
  trio,
  quatuor,
}

@Component({
  components: {
    "screen-base": () => import("./screen-base.vue"),
  },
})
export default class ScreenAlbum extends Vue {
  @Prop({ type: Object, required: true }) readonly data!: GalleryAlbumFragment
  @Prop({ type: Number, required: true }) readonly timer!: number

  Display = Display

  currentIndex = 0
  // eslint-disable-next-line no-undef
  interval: NodeJS.Timeout | null = null

  get subtitle() {
    return `${this.data.title.toUpperCase()} - ${DateHelper.getDateStringFormat(
      new Date(this.data.date),
      "EEEE d MMMM yyyy"
    ).toUpperCase()}`
  }

  get displayMode(): Display {
    switch (this.data.photos.length) {
      case 1:
        return Display.solo
      case 2:
      case 5:
        return Display.duo
      case 3:
      case 6:
      case 7:
        return Display.trio
      default:
        return Display.quatuor
    }
  }

  get photosUrls() {
    return this.data.photos.map(
      ({ mediaId }) => `${getRealFilePath(mediaId, "screen", FileSize.WIDTH_HD)}&apiKey=${this.$route.query.token}`
    )
  }

  get currentPhotos() {
    const start = (this.currentIndex * this.displayMode) % this.photosUrls.length

    return this.photosUrls
      .slice(start, start + this.displayMode)
      .concat(
        start + this.displayMode > this.photosUrls.length
          ? this.photosUrls.slice(0, this.displayMode - (this.photosUrls.length - start))
          : []
      )
  }

  mounted() {
    if (this.photosUrls.length > 4) {
      this.interval = setInterval(() => this.currentIndex++, (this.timer / Math.ceil(this.photosUrls.length / this.displayMode)) * 1000)
    }
  }

  destroyed() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
}
</script>

<style lang="scss">
.screen-album {
  .screen-content-wrapper {
    position: relative;

    .photo-container {
      position: absolute;

      &.solo {
        top: 5%;
        bottom: 5%;
        left: 12.5%;
        right: 12.5%;

        .screen-photo {
          padding: 0.55%;
        }
      }

      &.duo {
        &:nth-child(1) {
          top: 13.4%;
          right: 47.6%;
          bottom: 23.7%;
          left: 5%;
          transform: rotate(-7deg);
        }

        &:nth-child(2) {
          top: 30%;
          right: 5%;
          bottom: 13.4%;
          left: 50%;
          transform: rotate(5.4deg);
        }

        .screen-photo {
          padding: 0.9%;
        }
      }

      &.trio {
        &:nth-child(1) {
          top: 9.7%;
          right: 54.4%;
          bottom: 43.6%;
          left: 8.1%;
          transform: rotate(-3.2deg);
        }

        &:nth-child(2) {
          top: 46.7%;
          right: 31.9%;
          bottom: 8.8%;
          left: 29.8%;
        }

        &:nth-child(3) {
          top: 14.3%;
          right: 8.1%;
          bottom: 43.8%;
          left: 58.2%;
          transform: rotate(3deg);
        }

        .screen-photo {
          padding: 1.1%;
        }
      }

      &.quatuor {
        &:nth-child(1) {
          top: 38.4%;
          right: 65.1%;
          bottom: 21.8%;
          left: 3.3%;
          transform: rotate(-3.6deg);
        }

        &:nth-child(2) {
          top: 7.3%;
          right: 44.7%;
          bottom: 52.6%;
          left: 24.4%;
          transform: rotate(5.3deg);
        }

        &:nth-child(3) {
          top: 57.7%;
          right: 27.4%;
          bottom: 5.3%;
          left: 40.7%;
        }

        &:nth-child(4) {
          top: 23.8%;
          right: 4.5%;
          bottom: 35.9%;
          left: 63.5%;
          transform: rotate(-3.7deg);
        }

        .screen-photo {
          padding: 1%;
          border-radius: 16px;
        }
      }

      .screen-photo {
        width: 100%;
        height: 100%;
        border-radius: 22px;
        background: white;
        box-shadow: 0px 3px 11px 0px #00000014;
        object-fit: cover;
      }
    }
  }
}
</style>
