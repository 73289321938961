<template>
  <screen-base class="screen-animations" title="Animations" :sub-title="subTitle">
    <template v-for="({ date, activities }, index) in internalData">
      <screen-animations-day :key="`activity-${date}-${index}`" class="animations-row" :data="activities" :date="date" />
      <div v-if="index < 6" :key="`separator-${date}-${index}`" class="separator" />
    </template>
  </screen-base>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator"
import { startOfWeek } from "date-fns"
import DateHelper from "../../helpers/DateHelper"
import { ActivityFragment } from "../../queries/screen/graphql"

type InternalData = {
  date: string
  activities: ActivityFragment[]
}

@Component({
  components: {
    "screen-base": () => import("./screen-base.vue"),
    "screen-animations-day": () => import("./screen-animations-day.vue"),
  },
})
export default class ScreenAnimations extends Vue {
  @Prop({ type: Array, required: true }) private readonly data!: ActivityFragment[]

  get dateRange(): string[] {
    const currentDate = startOfWeek(new Date(), { weekStartsOn: 1 })
    const dateRange: string[] = []

    for (let i = 0; i < 7; i++) {
      dateRange.push(DateHelper.getDateStringFormat(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return dateRange
  }

  get subTitle(): string {
    const startDate = new Date(this.dateRange[0])
    const endDate = new Date(this.dateRange[6])
    const isSameMonth = startDate.getMonth() === endDate.getMonth()
    const startDateString = DateHelper.getDateStringFormat(startDate, isSameMonth ? "d" : "d MMMM")
    const endDateString = DateHelper.getDateStringFormat(endDate, "d MMMM")

    return `Programme du ${startDateString} au ${endDateString}`.toUpperCase()
  }

  get internalData(): InternalData[] {
    const internalData: InternalData[] = []

    for (const date of this.dateRange) {
      const activities = this.data.filter((activity) => DateHelper.getDateStringFormat(activity.startDateTime) === date)

      internalData.push({ date, activities })
    }

    return internalData
  }
}
</script>

<style lang="scss">
@import "../../style/_variables.scss";

.screen-animations {
  .animations-row {
    height: 14%;
  }

  .separator {
    height: 2px;
    margin: 0 0 0 11.3%;
    background-image: url("~@/assets/images/separator-horizontal.png");
    background-repeat: repeat-x;
    background-size: auto 2px;
  }
}
</style>
