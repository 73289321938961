<template>
  <div class="restaurant">
    <NavigationBar :left-buttons="[{ text: 'Retour', back: true }]" title="Restaurant" />

    <div class="app-view">
      <InternetError v-if="status === 'error'" :retry="loadMenus" />
      <div v-else-if="status === 'new' || status === 'loading'" class="loader"></div>
      <div v-else-if="dates.length" class="h-100">
        <Flicking ref="flick" class="h-100" :options="flickOptions" @ready="updatePosition()" @changed="indexChange($event)">
          <div
            v-for="(date, index) in dates"
            :key="date.toISOString()"
            :ref="date.toISOString()"
            class="menu-block"
            :class="{ scrollable: currentIndex === index }"
          >
            <div class="h-100">
              <MenuComponent :date="date" />
            </div>
          </div>
        </Flicking>
        <div ref="borderLeft" class="border-menu left d-flex flex-column" :style="`left: ${borderLeftPositon}px`">
          <div class="border-margin"></div>
          <div class="flex-grow-1 border-vertical"></div>
        </div>
        <div ref="borderRight" class="border-menu right d-flex flex-column" :style="`right: ${borderRightPositon}px`">
          <div class="border-margin"></div>
          <div class="flex-grow-1 border-vertical"></div>
        </div>
        <div v-if="currentIndex !== 0" ref="arrowLeft" class="arrow left d-flex align-items-center" :style="`left: ${arrowLeftPositon}px`">
          <button @click="previousMenu()">
            <img
              src="@/assets/images/next-back.png"
              srcset="@/assets/images/next-back@2x.png 2x, @/assets/images/next-back@3x.png 3x"
              alt="Précédent"
            />
          </button>
        </div>
        <div
          v-if="currentIndex !== dates.length - 1"
          ref="arrowRight"
          class="arrow right d-flex align-items-center"
          :style="`right: ${arrowRightPositon}px`"
        >
          <button @click="nextMenu()">
            <img src="@/assets/images/next.png" srcset="@/assets/images/next@2x.png 2x, @/assets/images/next@3x.png 3x" alt="Suivant" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import type { Flicking } from "@egjs/vue-flicking"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import { sessionModule } from "@/store/modules/session"
import { menuModule } from "@/store/modules/menu"

@Component({
  components: {
    Flicking: async () => (await import("@egjs/vue-flicking")).Flicking,
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
    InternetError: async () => await import("@/components/internet-error.vue"),
    MenuComponent: async () => await import("@/components/menu.vue"),
  },
})
export default class Restaurant extends Vue {
  @Prop({ type: String }) readonly date!: string | null

  currentIndex = 15
  arrowLeftPositon = 0
  arrowRightPositon = 0
  borderLeftPositon = 0
  borderRightPositon = 0
  resizeTimeout?: NodeJS.Timeout

  $refs!: {
    flick?: InstanceType<typeof Flicking>
    borderLeft?: HTMLDivElement
    borderRight?: HTMLDivElement
    arrowLeft?: HTMLDivElement
    arrowRight?: HTMLDivElement
  }

  get flickOptions(): Partial<Flicking> {
    return {
      autoResize: true,
      duration: 800,
      defaultIndex: this.currentIndex,
      renderOnlyVisible: false,
      align: "center",
    }
  }

  get status() {
    return menuModule.status
  }

  get dates() {
    const currentDate = this.date ? new Date(this.date) : new Date()
    const year = currentDate.getFullYear(),
      month = currentDate.getMonth(),
      date = currentDate.getDate()
    const dates: Array<Date> = []

    for (let index = -15; index <= 30; index++) {
      dates.push(new Date(year, month, date + index))
    }

    return dates
  }

  created() {
    window.addEventListener("resize", this.handleResize)
    window.addEventListener("keydown", this.handleKeyDown)
  }

  async mounted() {
    TrackingHelper.track(AppTrackEvent.restaurantOpened)

    await this.loadMenus()
  }

  destroyed() {
    window.removeEventListener("resize", this.handleResize)
    window.removeEventListener("keydown", this.handleKeyDown)
  }

  handleResize() {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout)
    }

    this.resizeTimeout = setTimeout(this.updatePosition, 300)
  }

  handleKeyDown({ key }: KeyboardEvent) {
    if (!key) {
      return
    }

    switch (key) {
      case "ArrowLeft":
        this.previousMenu()
        break
      case "ArrowRight":
        this.nextMenu()
        break

      default:
        break
    }
  }

  updatePosition() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const menuElement = (this.$refs as any)[this.dates[this.currentIndex]?.toISOString()]?.[0] as HTMLDivElement | undefined
    if (!menuElement) {
      return
    }

    const menuWidth = menuElement.clientWidth
    const screenWidth = window.innerWidth
    const middleX = screenWidth / 2

    const offset = menuWidth === screenWidth ? 18 : 0

    const position = middleX - menuWidth / 2 + offset

    this.borderRightPositon = position
    this.borderLeftPositon = position
    this.arrowRightPositon = position
    this.arrowLeftPositon = position
  }

  async loadMenus() {
    const residenceId = sessionModule.currentResidence?.id ?? null
    if (!residenceId) {
      return
    }

    await menuModule.preloadMenus(residenceId)
  }

  indexChange({ index }: { index: number }) {
    this.currentIndex = index
  }

  previousMenu() {
    if (!this.$refs.flick || this.$refs.flick.animating) {
      return
    }

    this.$refs.flick.prev()
  }

  nextMenu() {
    if (!this.$refs.flick || this.$refs.flick.animating) {
      return
    }

    this.$refs.flick.next()
  }
}
</script>

<style>
@import "@egjs/vue-flicking/dist/flicking";
</style>

<style scoped lang="scss">
.restaurant {
  height: 100%;
  display: flex;
  flex-flow: column;

  .app-view {
    height: 100%;
    position: relative;

    .menu-block {
      width: 100%;
      height: 100%;
      max-width: 484px;

      &.scrollable {
        display: flex;
        flex-flow: column;
        overflow-y: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .border-menu,
    .arrow {
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;

      &.left {
        left: 0;
        transform: translateX(-50%);
      }

      &.right {
        right: 0;
        transform: translateX(50%);
      }
    }

    .border-menu {
      .border-margin {
        height: 90px;
        width: 5px;
      }
    }

    .arrow {
      z-index: 100;
    }
  }
}
</style>
