<template>
  <img class="image" :draggable="draggable" :src="src" />
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import FileHelper from "@/helpers/FileHelper"
import { FileSize } from "@/types/FileSize"

@Component
export default class ImageURL extends Vue {
  @Prop({ type: String, required: true }) readonly fileId!: string
  @Prop({ type: Boolean, default: true }) readonly draggable!: boolean
  @Prop({ type: String, required: false, default: FileSize.ORIGINAL }) private readonly size!: FileSize

  url: string | null = null

  get src() {
    return this.url ?? require("@/assets/images/placeholder.jpg")
  }

  async mounted() {
    this.url = await FileHelper.getDataFromURL(this.fileId, this.size)
  }
}
</script>
