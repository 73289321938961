<template>
  <div class="surveys">
    <NavigationBar :left-buttons="[{ text: 'Retour', back: true }]" title="Enquête" />

    <div class="app-view survey-container">
      <InternetError v-if="status === 'error'" :retry="loadSurveys" />
      <div v-else-if="status !== 'ready'" class="loader"></div>
      <div v-else>
        <h2 class="title">Participez aux nouvelles enquêtes et remportez des prix !</h2>

        <div v-if="surveys.length">
          <h1 class="survey-section-title">Nos enquêtes</h1>
          <div class="d-flex flex-wrap">
            <div v-for="survey in surveys" :key="survey.id" class="widget-container">
              <Widget>
                <div class="d-flex justify-content-between">
                  <div class="name align-self-center">{{ survey.title }}</div>
                  <button @click="openSurvey(survey.id)">
                    <img
                      src="@/assets/images/survey-arrow.png"
                      srcset="@/assets/images/survey-arrow@2x.png 2x, @/assets/images/survey-arrow@3x.png 3x"
                    />
                  </button>
                </div>
              </Widget>
            </div>
          </div>
        </div>
        <div v-if="surveys.length && submissionSurveys.length" class="border-horizontal"></div>
        <div v-if="submissionSurveys.length">
          <h1 class="survey-section-title">Enquêtes déjà répondues</h1>
          <div class="d-flex flex-wrap">
            <div v-for="survey in submissionSurveys" :key="survey.id" class="widget-container">
              <Widget>
                <div class="d-flex justify-content-between">
                  <div class="name align-self-center">{{ survey.title }}</div>
                  <button @click="selectSurvey(survey)">
                    <img
                      src="@/assets/images/survey-arrow.png"
                      srcset="@/assets/images/survey-arrow@2x.png 2x, @/assets/images/survey-arrow@3x.png 3x"
                    />
                  </button>
                </div>
              </Widget>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="popup-prize" size="lg" hide-header hide-footer centered>
      <template #default="{ close }">
        <PopupAlert v-if="selectedSurvey" :title="selectedSurvey.title" :sub-title="subTitle" :buttons="['Ok']" :single-button="close" />
      </template>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import { sessionModule } from "@/store/modules/session"
import { SurveyFragment } from "@/queries/client/graphql"
import { Status } from "@/types/Status"
import APIClient from "@/api/ApiClient"

@Component({
  components: {
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
    Widget: async () => await import("@/components/widgets/widget.vue"),
    PopupAlert: async () => await import("@/components/popups/popup-alert.vue"),
    InternetError: async () => await import("@/components/internet-error.vue"),
  },
})
export default class Survey extends Vue {
  status = Status.New
  surveys: SurveyFragment[] = []
  submissionSurveys: SurveyFragment[] = []
  selectedSurvey: SurveyFragment | null = null

  get subTitle(): string {
    return `Merci pour votre participation à cette enquête.
      ${this.selectedSurvey?.prize ? `\n\nVous avez gagné : ${this.selectedSurvey.prize}.` : ""}`
  }

  async mounted() {
    TrackingHelper.track(AppTrackEvent.surveyOpened)
    await this.loadSurveys()
  }

  async loadSurveys() {
    const currentResidenceId = sessionModule.currentResidence?.id
    if (!currentResidenceId) {
      return
    }

    this.status = Status.Loading

    try {
      const surveys = await APIClient.getSurveys(currentResidenceId)

      this.surveys = surveys.filter((survey) => !survey.submission)
      this.submissionSurveys = surveys.filter((survey) => survey.submission)

      this.status = Status.Ready
    } catch (error) {
      this.status = Status.Error
    }
  }

  async openSurvey(surveyId: string) {
    await this.$router.push(`question/${surveyId}`)
  }

  selectSurvey(survey: SurveyFragment) {
    this.$bvModal.show("popup-prize")
    this.selectedSurvey = survey
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.surveys {
  height: 100%;
  display: flex;
  flex-flow: column;

  .survey-container {
    padding: 40px 22.5px 40px 22.5px;

    .title {
      margin-bottom: 43px;
      padding: 0 17.5px;
    }

    .survey-section-title {
      @include title-secondary-style();
      text-align: left;
      padding: 0 17.5px;
    }

    .widget-container {
      width: 100%;
      max-width: 540px;
      padding: 20px 17.5px 20px 17.5px;

      .widget {
        padding: 15px;

        .name {
          color: $secondary;
          font-weight: 600;
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .border-horizontal {
      margin-bottom: 57px;
    }
  }
}
</style>
