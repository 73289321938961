import { VuexModule, Module, Action, Mutation } from "vuex-class-modules"
import store from ".."
import { DataEventType, DataEventFragment } from "../../queries/client/graphql"
import APIClient from "../../api/ApiClient"
import { sessionModule } from "./session"

@Module
class DataEventModule extends VuexModule {
  dataEvents: DataEventFragment[] | null = null

  get currentResidenceId() {
    return sessionModule.currentResidence?.id
  }

  get statusForType() {
    return (type: DataEventType) => this.dataEvents?.find((value) => value.type === type)?.status ?? null
  }

  @Mutation
  setDataEvents(dataEvents: DataEventFragment[]) {
    this.dataEvents = dataEvents
  }

  @Action
  async markAsReadForType(type: DataEventType) {
    if (this.currentResidenceId) {
      await APIClient.markAsReadForType(type, this.currentResidenceId)
    }
  }

  @Action
  async loadDataEvents() {
    if (this.currentResidenceId) {
      let dataEvents: DataEventFragment[] = []

      try {
        dataEvents = await APIClient.getDataEvents(this.currentResidenceId)
      } catch (error) {
        console.log(error)
      }

      this.setDataEvents(dataEvents)
    }
  }
}

export const dataEventModule = new DataEventModule({ store, name: "data-event" })
