<template>
  <div class="radio-form">
    <h5 class="form-type">Une seule réponse possible</h5>
    <Widget>
      <div v-for="(answer, index) in answers" :key="index" class="radio-content d-flex align-items-center">
        <button :disabled="disabled" @click="onCheck(index)">
          <div class="d-flex align-items-center">
            <div>
              <div class="radio">
                <div class="selected" :class="{ 'd-none': selected !== index }"></div>
              </div>
            </div>
            <div class="name">{{ answer }}</div>
          </div>
        </button>
      </div>
    </Widget>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model, Watch, Emit } from "vue-property-decorator"

@Component({
  components: {
    Widget: async () => await import("@/components/widgets/widget.vue"),
  },
})
export default class RadioForm extends Vue {
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean
  @Prop({ type: Array, default: false }) readonly answers!: string[]

  @Model("change", { type: String }) readonly value!: string

  selected = -1

  created() {
    if (this.value) {
      this.selected = Number(this.value)
    }
  }

  onCheck(index: number) {
    this.selected = index
  }

  @Watch("selected")
  onInputValueChange() {
    this.onModelChange(this.selected)
  }

  @Emit("change")
  onModelChange(value: number): string {
    return value.toString()
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.radio-form {
  width: 100%;
  max-width: 510px;
  margin: 0 auto;

  .form-type {
    color: $text-body;
    font-style: italic;
    font-weight: normal;
  }

  .widget {
    margin-top: 50px;
    padding: 27.5px 32px;

    .radio-content {
      padding: 17.5px 0;
      max-width: 510px;

      .name {
        @include title-h3();
        font-weight: normal;
        padding-right: 15px;
      }

      .radio {
        width: 20px;
        height: 20px;
        margin-right: 32px;
        border-radius: 10px;
        border: solid 1.5px $secondary;
        background-color: rgba($background-default, 0.5);
        position: relative;

        .selected {
          width: 14px;
          height: 14px;
          border-radius: 10px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: $secondary;
        }
      }
    }
  }
}
</style>
