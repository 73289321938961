import { MealType } from "../queries/client/graphql"

export function getMealType(type: MealType): string {
  switch (type) {
    case MealType.Lunch:
      return "Déjeuner"
    case MealType.Dinner:
      return "Dîner"
    default:
      break
  }
  return ""
}
