<template>
  <div class="team-day d-flex flex-wrap justify-content-around">
    <div v-for="teamMember in team.teamMembers" :key="teamMember.id" class="block">
      <TeamMemberBlock :team-member="teamMember" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { TeamFragment } from "@/queries/client/graphql"

@Component({
  components: {
    TeamMemberBlock: async () => await import("./team-member-block.vue"),
  },
})
export default class TeamDay extends Vue {
  @Prop({ type: Object, required: true }) readonly team!: TeamFragment
}
</script>

<style scoped lang="scss">
.team-day {
  .block {
    padding-bottom: 19px;
  }
}
</style>
