<template>
  <div class="question">
    <NavigationBar :left-buttons="[{ text: 'Retour', back: true }]" :title="surveyTitle" />

    <div class="app-view">
      <InternetError v-if="surveyStatus === 'error'" :retry="loadSurvey" />
      <div v-else-if="surveyStatus !== 'ready'" class="loader"></div>
      <div v-else>
        <div class="progress-block">
          <ProgressBar :progress="progess" />
          <h4 v-if="status === 'success' || status === 'loading'" class="progress-title">fin</h4>
          <h4 v-else-if="survey" class="progress-title">{{ currentIndex + 1 }}/{{ survey.questions.length }}</h4>
        </div>
        <div v-if="status !== 'success' && status !== 'loading' && currentQuestion" class="question-container">
          <div class="form-container">
            <h3 class="question-title">{{ currentQuestion.content }}</h3>
            <RadioForm
              v-if="currentQuestion.type === 'RADIO'"
              :key="currentQuestion.id"
              v-model="currentAnswer"
              :answers="currentQuestion.answers"
              :disabled="formDisabled"
            />
            <CheckboxForm
              v-else-if="currentQuestion.type === 'CHECKBOX'"
              :key="currentQuestion.id"
              v-model="currentAnswer"
              :current-answers="currentQuestion.answers"
              :disabled="formDisabled"
            />
            <TextForm
              v-else-if="currentQuestion.type === 'TEXT'"
              :key="currentQuestion.id"
              v-model="currentAnswer"
              :disabled="formDisabled"
            />
            <NumberForm
              v-else-if="currentQuestion.type === 'NUMBER'"
              :key="currentQuestion.id"
              v-model="currentAnswer"
              :disabled="formDisabled"
            />
          </div>
          <div class="button-container d-flex flex-row-reverse justify-content-between">
            <ButtonLoading v-if="isNewAnswer" class="btn-highlight btn-next" title="Valider" :status="status" :click="validate" />
            <ButtonArrow v-else class="jda-btn btn-default btn-next" text="Question suivante" :click="next" arrow-right small />
            <ButtonArrow
              v-if="currentIndex !== 0"
              class="jda-btn btn-default btn-prev"
              text="Question précédente"
              :click="prev"
              arrow-left
              small
            />
          </div>
        </div>
        <div v-if="status === 'success'" class="success-container justify-content-center">
          <h1 class="success-title">Merci pour votre participation !</h1>
          <h4 v-if="survey.prize" class="success-subtitle">Vous avez gagné : {{ survey.prize }}.</h4>
          <button class="jda-btn btn-highlight" @click="$router.go(-1)">Retour à l’accueil</button>
        </div>
        <div v-if="status === 'loading'" class="loading-container justify-content-center">
          <div class="loader"></div>
          <h4 class="loading-title">Sauvegarde de vos réponses</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import { InputSubmitSurvey } from "@/queries/client/graphql"
import { showError } from "@/helpers/UtilsHelper"
import { sessionModule } from "@/store/modules/session"
import { Status } from "@/types/Status"
import APIClient from "@/api/ApiClient"

export enum SurveyQuestionType {
  Radio = "RADIO",
  Checkbox = "CHECKBOX",
  Text = "TEXT",
  Number = "NUMBER",
}

export type SurveyQuestionFragment = {
  id: string
  surveyId: string
  order: number
  type: SurveyQuestionType
  content: string
  answers: Array<string>
}

export type SurveyFragment = {
  id: string
  title: string
  questions: Array<SurveyQuestionFragment>
}

@Component({
  components: {
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
    ProgressBar: async () => await import("@/components/progress-bar.vue"),
    ButtonArrow: async () => await import("@/components/buttons/button-arrow.vue"),
    ButtonLoading: async () => await import("@/components/buttons/button-loading.vue"),
    CheckboxForm: async () => await import("@/components/forms/checkbox-form.vue"),
    NumberForm: async () => await import("@/components/forms/number-form.vue"),
    TextForm: async () => await import("@/components/forms/text-form.vue"),
    RadioForm: async () => await import("@/components/forms/radio-form.vue"),
    InternetError: async () => await import("@/components/internet-error.vue"),
  },
})
export default class Question extends Vue {
  @Prop({ type: String, required: true }) readonly surveyId!: string

  inputSubmiteSurvey: InputSubmitSurvey = { answers: [] }
  currentIndex = 0
  currentQuestion: SurveyQuestionFragment | null = null
  currentAnswer = ""
  status = "enabled"
  survey: SurveyFragment | null = null
  surveyStatus = Status.New

  get currentResidenceId() {
    return sessionModule.currentResidence?.id ?? null
  }

  get surveyTitle() {
    return this.survey?.title ?? ""
  }

  get isNewAnswer() {
    return (
      !this.inputSubmiteSurvey.answers.find((answer) => answer.questionId === this.currentQuestion?.id) ||
      this.inputSubmiteSurvey.answers.length === this.survey?.questions.length
    )
  }

  get progess(): number {
    return this.survey?.questions.length ? ((this.currentIndex + 1) / this.survey.questions.length) * 100 : 0
  }

  get formDisabled() {
    return this.status !== "enabled"
  }

  async mounted() {
    TrackingHelper.track(AppTrackEvent.surveyStarted, { surveyId: this.surveyId })

    await this.loadSurvey()
  }

  async loadSurvey() {
    if (!this.currentResidenceId) {
      return
    }

    this.surveyStatus = Status.Loading
    try {
      this.survey = await APIClient.getSurveyById(this.currentResidenceId, this.surveyId)
      this.currentQuestion = this.survey.questions[0] ?? null
      this.surveyStatus = Status.Ready
    } catch (error) {
      this.surveyStatus = Status.Error
    }
  }

  async validate() {
    if (!this.survey) {
      showError(this.$bvToast, "Erreur", new Error("Pas de questionnaire"))
      return
    }
    if (!this.currentAnswer || this.currentAnswer === "[]") {
      showError(this.$bvToast, "Erreur", new Error("Le champ est vide"))
      return
    }

    if (!this.currentQuestion) {
      return
    }

    this.status = "loading"
    const answer = { questionId: this.currentQuestion.id, answer: this.currentAnswer }
    this.inputSubmiteSurvey.answers.push(answer)

    if (this.currentIndex + 1 === this.survey.questions.length) {
      if (!this.currentResidenceId) {
        return
      }

      try {
        await APIClient.submitSurvey(this.currentResidenceId, this.surveyId, this.inputSubmiteSurvey)
        TrackingHelper.track(AppTrackEvent.surveySubmitted, { surveyId: this.surveyId })

        this.status = "success"
      } catch (error) {
        showError(this.$bvToast, "Une erreur est survenue lors de l'envoie des réponses", new Error((error as Error).message))
        this.status = "enabled"
      }
    } else {
      this.next()
      this.status = "enabled"
    }
  }

  prev() {
    if (!this.survey || !this.currentIndex) {
      return
    }

    this.currentIndex -= 1

    this.currentQuestion = this.survey.questions[this.currentIndex]
    const answer = this.inputSubmiteSurvey.answers.find((value) => value.questionId === this.currentQuestion?.id)
    if (answer) {
      this.currentAnswer = answer.answer
    }
  }

  next() {
    if (!this.survey) {
      return
    }
    // // Check answer changed
    const answer = this.inputSubmiteSurvey.answers.find((value) => value.questionId === this.currentQuestion?.id)

    if (answer && answer.answer !== this.currentAnswer) {
      if (!this.currentAnswer || this.currentAnswer === "[]") {
        showError(this.$bvToast, "Erreur", new Error("Le champ est vide"))
        return
      }
      answer.answer = this.currentAnswer
    }

    this.currentIndex += 1
    this.currentQuestion = this.survey.questions[this.currentIndex]
    const currentAnswer = this.inputSubmiteSurvey.answers.find((value) => value.questionId === this.currentQuestion?.id)

    this.currentAnswer = currentAnswer?.answer ?? ""
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.question {
  height: 100%;
  display: flex;
  flex-flow: column;

  .progress-block {
    margin: 32px 32px 0 32px;

    .progress-title {
      width: 100%;
      margin-top: 10px;
      text-align: right;
    }
  }

  .question-container {
    .form-container {
      width: 100%;
      padding: 0 40px;
      flex: 1;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .widget {
        padding: 24px;
      }
    }
  }

  .success-container {
    width: 100%;
    padding: 0 40px 88px 40px;

    .success-title {
      @include title-secondary-style();
    }

    .success-subtitle {
      color: $text-body;
      margin-top: 30px;
    }

    .btn-highlight {
      width: 215px;
      margin: 50px auto 0 auto;
    }
  }

  .loading-container {
    padding: 0 40px 88px 40px;

    .loading-title {
      margin-top: 25px;
      @include title-secondary-style();
    }
  }

  .loading-container,
  .success-container,
  .question-container {
    height: 100%;
    display: flex;
    overflow: scroll;
    flex-direction: column;
  }

  .button-container {
    padding: 32px;

    .btn-default,
    .btn-highlight {
      max-width: 300px;

      &.btn-prev {
        margin-right: 12px;
      }

      &.btn-next {
        margin-left: 12px;
      }
    }

    .btn-default {
      @media (max-width: $sm) {
        width: unset;
      }
    }
  }
}
</style>
