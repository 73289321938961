<template>
  <div v-if="place" class="widget-place" :style="style">
    <div class="content">
      <h4 class="title">{{ place.title }}</h4>
      <h4 class="sub-title">{{ place.subTitle }}</h4>
      <Nl2Br class="description" :content="place.address" />
      <Nl2Br v-if="place.description" class="description" :content="place.description" />
    </div>
    <div class="top-tail"></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import { PlaceFragment } from "@/queries/client/graphql"

@Component({
  components: {
    Nl2Br: async () => await import("@/components/nl2br.vue"),
  },
})
export default class WidgetPlace extends Vue {
  place: PlaceFragment | null = null
  top = 0
  left = 0
  visible = false

  get style() {
    return `top: ${this.top}px; left: ${this.left}px; display: ${this.visible ? "block" : "none"}`
  }

  setPosition(x: number, y: number) {
    this.left = x - 28
    this.top = y
  }

  setVisible(visible: boolean) {
    this.visible = visible
  }

  setPlace(place: PlaceFragment) {
    this.place = place
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.widget-place {
  width: 335px;
  margin-top: 10px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.32);
  background-color: white;

  .content {
    padding: 15px 20px;

    .title {
      text-align: left;
    }

    .sub-title {
      text-align: left;
      font-weight: normal;
      margin-bottom: 16px;
    }

    .description {
      @include body();
    }
  }

  .top-tail {
    position: absolute;
    left: 28px;
    top: -10px;
    height: 0px;
    width: 0px;
    margin-left: -10px;
    border-bottom: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top-color: transparent;
    border-top-style: solid;
  }
}
</style>
