<template>
  <screen-base title="Éphéméride" :sub-title="date">
    <div class="column">
      <screen-card-weather :data="data.weather" />
      <screen-card-ephemeris class="last-item" :data="data.ephemeris" />
    </div>
    <div class="column">
      <screen-card-team class="full-height-item" :data="data.team" />
    </div>
  </screen-base>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import DateHelper from "../../helpers/DateHelper"
import { EphemerisSlideFragment } from "../../queries/screen/graphql"

@Component({
  components: {
    "screen-base": () => import("./screen-base.vue"),
    "screen-card-weather": () => import("./screen-card-weather.vue"),
    "screen-card-ephemeris": () => import("./screen-card-ephemeris.vue"),
    "screen-card-team": () => import("./screen-card-team.vue"),
  },
})
export default class ScreenEphemeris extends Vue {
  @Prop({ type: Object, required: true }) readonly data!: EphemerisSlideFragment

  get date() {
    return DateHelper.getDateStringFormat(new Date(), "EEEE d MMMM yyyy").toUpperCase()
  }
}
</script>
<style scoped lang="scss">
.column {
  width: 49.8%;
  height: 100%;
  padding: 2.63%;
  display: inline-block;
  vertical-align: top;

  .last-item {
    margin-top: 10.76%;
  }

  .full-height-item {
    height: 100%;
  }
}
</style>
