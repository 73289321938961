<template>
  <div class="widget-image">
    <div class="image-container">
      <ImageUrl :file-id="fileId" :size="FileSize.THUMBNAIL" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { FileSize } from "@/types/FileSize"

@Component({
  components: {
    ImageUrl: async () => await import("@/components/image-url.vue"),
  },
})
export default class WidgetImage extends Vue {
  @Prop({ type: String, required: true }) readonly fileId!: string

  FileSize = FileSize
}
</script>

<style scoped lang="scss">
.widget-image {
  width: 100%;
  padding-top: 66.66%;
  height: 0;
  margin: auto;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  position: relative;

  .image-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: solid 10px white;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
</style>
