import { VuexModule, Module, Mutation } from "vuex-class-modules"
import store from ".."

@Module
class PhotoModule extends VuexModule {
  zoomedIn = false

  get isZoomedIn() {
    return this.zoomedIn
  }

  @Mutation
  setZoomedIn(zoomedIn: boolean) {
    this.zoomedIn = zoomedIn
  }
}

export const photoModule = new PhotoModule({ store, name: "photo" })
