import { ApolloClient, from, DefaultOptions, InMemoryCache, Observable, HttpLink, ServerError } from "@apollo/client/core"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import DeviceHelper from "../helpers/DeviceHelper"
import { API_URL } from "./url"
import { ProviderDelegate } from "./ProviderDelegate"

let delegate: ProviderDelegate | null = null

export function setDelegate(newDelegate: ProviderDelegate | null): void {
  delegate = newDelegate
}

const clientInterceptor = setContext(() => {
  if (delegate == null) {
    return {}
  }

  const accessToken = delegate.delegateGetAccessToken()
  if (accessToken != null && DeviceHelper.isMobile()) {
    return {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  }

  return {}
})

// Screen specific part
let screenToken: string | null = null

export function setScreenToken(newScreenToken: string | null): void {
  screenToken = newScreenToken
}

const screenInterceptor = setContext(() => {
  if (screenToken == null) {
    return {}
  }

  return {
    headers: {
      "X-API-Key": screenToken,
    },
  }
})

const errorLink = onError(({ networkError, operation, forward }) => {
  if (networkError && (networkError as ServerError).statusCode === 401) {
    // Ignore errors coming from RefreshToken or Logout process
    const definitionsString = JSON.stringify(operation.query.definitions)
    if (
      definitionsString.indexOf("RefreshToken") !== -1 ||
      definitionsString.indexOf("DeleteNotificationToken") !== -1 ||
      definitionsString.indexOf("LogoutUserFromDevice") !== -1
    ) {
      return undefined
    }

    console.log("[ApolloProvider] Unauthorized: starting a refresh token...", networkError)

    return new Observable((observer) => {
      if (delegate == null) {
        observer.error(new Error("No auth delegate to refresh token"))
        return
      }

      const replayOperation = () => {
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        }

        // Add Authorization Header on mobile
        if (delegate != null && DeviceHelper.isMobile()) {
          const token = delegate.delegateGetAccessToken()

          if (token != null) {
            const oldHeaders = operation.getContext().headers
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${token}`,
              },
            })
          }
        }

        forward(operation).subscribe(subscriber)
      }

      if (delegate.delegateIsRefreshingSession()) {
        delegate.delegateGetRefreshSession$().subscribe(() => {
          replayOperation()
        })
      } else {
        delegate
          .delegateRefreshSession()
          .then(() => {
            replayOperation()

            return Promise.resolve()
          })
          .catch((error: Error) => {
            observer.error(error)
          })
      }
    })
  }
  return undefined
})

const authHttpLink = new HttpLink({
  uri: `${API_URL}/auth/graphql`,
  credentials: "include",
})

const clientHttpLink = new HttpLink({
  uri: `${API_URL}/client/graphql`,
  credentials: "include",
})

const screenHttpLink = new HttpLink({
  uri: `${API_URL}/screen/graphql`,
})

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
  },
  query: {
    fetchPolicy: "no-cache",
  },
}

export const authApolloClient = new ApolloClient({
  link: from([errorLink, authHttpLink]),
  cache: new InMemoryCache(),
  defaultOptions,
})

export const apolloClient = new ApolloClient({
  link: from([clientInterceptor, errorLink, clientHttpLink]),
  cache: new InMemoryCache(),
  defaultOptions,
})

export const apolloScreen = new ApolloClient({
  link: from([screenInterceptor, screenHttpLink]),
  cache: new InMemoryCache(),
  defaultOptions,
})
