<template>
  <div class="popup-alert">
    <h3 class="title">{{ title }}</h3>
    <Nl2Br v-if="subTitle" class="sub-title" tag="h4" :content="subTitle" />
    <div class="buttons">
      <div v-if="buttons.length === 1" class="d-flex justify-content-center">
        <button class="jda-btn btn-primary btn-single" @click="singleButton()"> {{ buttons[0] }} </button>
      </div>
      <div v-else class="d-flex justify-content-center">
        <button class="jda-btn btn-primary btn-left" @click="leftButton()"> {{ buttons[0] }} </button>
        <button class="jda-btn btn-danger btn-right" @click="rightButton()"> {{ buttons[1] }} </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  components: {
    Nl2Br: async () => await import("@/components/nl2br.vue"),
  },
})
export default class PopupAlert extends Vue {
  @Prop({ type: String, required: false, default: "" }) readonly title!: string
  @Prop({ type: String, required: false, default: null }) readonly subTitle!: string | null
  @Prop({ type: Array, required: false, default: () => ["ok"] }) readonly buttons!: string[]
  @Prop({ type: Function, required: false, default: () => {} }) readonly singleButton!: () => void
  @Prop({ type: Function, required: false, default: () => {} }) readonly leftButton!: () => void
  @Prop({ type: Function, required: false, default: () => {} }) readonly rightButton!: () => void
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.popup-alert {
  padding: 40px 24px 24px 24px;

  .title {
    margin-bottom: 40px;
  }

  .sub-title {
    color: $text-body;
    margin-bottom: 40px;
  }

  .btn-single {
    width: 100%;
    max-width: 435px;
  }

  .btn-left,
  .btn-right {
    height: 48px;
    width: 150px;
    margin: 16px;
  }
}
</style>
