export enum Status {
  New = "new",
  Loading = "loading",
  Refreshing = "refreshing",
  Error = "error",
  Ready = "ready",
}
export enum SubStatus {
  Loading = "loading",
  Ready = "ready",
}
