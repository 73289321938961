<template>
  <div class="residence">
    <NavigationBar :left-buttons="[{ text: 'Retour', back: true }]" title="Ma résidence" />
    <div class="app-view">
      <div class="residence-content">
        <div class="title">
          <div class="d-flex justify-content-between align-items-center">
            <h4>{{ title }} </h4>
            <button v-b-toggle.collapse>
              <img src="@/assets/images/list.png" />
            </button>
          </div>
        </div>
        <div class="tab">
          <b-collapse id="collapse" v-model="visible">
            <button
              v-for="(tab, index) in tabs"
              :key="index"
              class="btn-tab"
              :class="{ active: tab.id === selectedTabId }"
              @click="onClickButton(tab.id)"
            >
              {{ tab.name }}
            </button>
          </b-collapse>
        </div>
        <div class="flex-fill d-flex content">
          <div v-if="selectedTabId === 'faq'" class="block">
            <Faq />
          </div>
          <div v-else-if="selectedTabId === 'trombi'" class="block">
            <Trombi :trombi-id="residence.trombiId" />
          </div>
          <div v-else-if="selectedTabId === 'map'" class="block">
            <MapComponent :pdf-id="residence.mapId" />
          </div>
          <div v-else-if="selectedTabId === 'timesheet'" class="block">
            <Information :residence="residence" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import { sessionModule } from "@/store/modules/session"

type Tab = "faq" | "trombi" | "map" | "timesheet"

@Component({
  components: {
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
    Faq: async () => await import("@/components/residence/faq.vue"),
    Trombi: async () => await import("@/components/residence/trombi.vue"),
    Information: async () => await import("@/components/residence/information.vue"),
    MapComponent: async () => await import("@/components/residence/map.vue"),
  },
})
export default class Residence extends Vue {
  selectedTabId: Tab = "faq"
  visible = false

  get residence() {
    return sessionModule.currentResidence
  }

  get title(): string {
    return this.tabs.find((tab) => tab.id === this.selectedTabId)?.name ?? ""
  }

  get tabs(): Array<{ id: Tab; name: string }> {
    if (!this.residence) {
      return []
    }

    const tabs: Array<{ id: Tab; name: string }> = []

    tabs.push({ id: "faq", name: "FAQ" })

    if (this.residence.trombiId) {
      tabs.push({ id: "trombi", name: "Trombinoscope" })
    }

    if (this.residence.mapId) {
      tabs.push({ id: "map", name: "Plan" })
    }

    tabs.push({ id: "timesheet", name: "Horaires" })

    return tabs
  }

  created() {
    window.addEventListener("orientationchange", this.onScreenChanged)
    window.addEventListener("resize", this.onScreenChanged)
  }

  beforeMount() {
    this.onScreenChanged()
  }

  async mounted() {
    await sessionModule.loadSessionData()
  }

  destroyed() {
    window.removeEventListener("orientationchange", this.onScreenChanged)
    window.removeEventListener("resize", this.onScreenChanged)
  }

  onScreenChanged() {
    this.visible = window.innerWidth > 768
  }

  onClickButton(id: Tab) {
    this.selectedTabId = id
    this.onScreenChanged()
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.residence {
  height: 100%;
  display: flex;
  flex-flow: column;

  .residence-content {
    height: 100%;
    min-height: 0;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;

    .title {
      display: none;
    }

    .tab {
      width: 214px;
      height: 100%;
      z-index: 2;
      background-color: white;
      box-shadow: 0 5px 5px 5px rgba(rgb(31, 31, 31), 0.03);

      .btn-tab {
        width: 100%;
        min-width: 214px;
        padding: 16px 30px;
        @include sub-title();
        text-align: left;
        color: $secondary;
        background-color: white;

        &:hover {
          background-color: rgba($color: $secondary, $alpha: 0.3);
        }

        &.active,
        &:active {
          background-color: $secondary;
          color: white;
          font-weight: bold;
        }
      }
    }

    .content {
      min-width: 320px;
      height: 100%;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      .block {
        width: 100%;
      }

      .timesheet {
        margin-top: 245px;
      }
    }

    @media (max-width: $md) {
      flex-flow: column;

      .title {
        width: 100%;
        text-align: left;
        padding: 16px 30px;
        display: inline-table;
        background-color: white;
      }

      .tab {
        width: 100%;
        height: auto;
        flex-flow: row;
      }

      .content {
        overflow-y: visible;
      }
    }
  }
}
</style>
