<template>
  <div class="map">
    <div id="pdf-container" class="pdf-container">
      <PdfDocument :pdf-id="pdfId" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"

@Component({
  components: {
    PdfDocument: async () => await import("@/components/pdf-document/pdf-document.vue"),
  },
})
export default class Map extends Vue {
  @Prop({ type: String, required: true }) readonly pdfId!: string

  mounted() {
    TrackingHelper.track(AppTrackEvent.myResidencePlanOpened)
  }
}
</script>

<style scoped lang="scss">
.map {
  height: 100%;
  width: 100%;
  padding: 40px;

  .pdf-container {
    height: 100%;
    overflow: auto;
  }
}
</style>
