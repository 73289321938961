<template>
  <div class="contact">
    <NavigationBar :left-buttons="[{ text: 'Retour', back: true }]" title="Contact" />
    <div class="app-view">
      <InternetError v-if="status === 'error'" :retry="loadMessage" />
      <div v-else-if="status !== 'ready'" class="loader"></div>
      <div v-else class="chat-container d-flex flex-column">
        <div id="chat-history" class="chat-history">
          <ul v-if="messages.length > 0">
            <div v-for="message in messages" :key="message.id" class="message">
              <MessageContainer :message="message" :authors="authors" :user-id="userId" />
            </div>
            <li v-if="isSent && confirmMessage" class="is-sent float-right">
              {{ confirmMessage }}
            </li>
            <br />
          </ul>
        </div>
        <MessageField v-model="message" :disabled="isLoading" @send="sendMessage" />
      </div>
    </div>
    <b-modal id="popup-error" size="lg" hide-header hide-footer centered>
      <template #default="{ close }">
        <PopupAlert :title="errorTitle" :sub-title="error" :buttons="['Ok']" :single-button="close" />
      </template>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import { ThreadMessageFragment, ThreadAuthorFragment, UserResidenceRole } from "@/queries/client/graphql"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import { showError } from "@/helpers/UtilsHelper"
import { sessionModule } from "@/store/modules/session"
import { Status } from "@/types/Status"
import APIClient from "@/api/ApiClient"

export interface Message {
  id: number
  author: string
  date: Date
  message: string
}

@Component({
  components: {
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
    MessageField: async () => await import("@/components/contact/message-field.vue"),
    MessageContainer: async () => await import("@/components/contact/message-container.vue"),
    PopupAlert: async () => await import("@/components/popups/popup-alert.vue"),
    InternetError: async () => await import("@/components/internet-error.vue"),
  },
})
export default class Contact extends Vue {
  userId: string | null = null
  currentResidenceId: string | null = null
  messages: ThreadMessageFragment[] = []
  authors: ThreadAuthorFragment[] = []

  isSent = false
  message = ""
  confirmMessage = ""
  intervalInstance = -1
  timeoutInstance = -1
  isLoading = false
  errorTitle = ""
  error = ""
  status = Status.New
  isAdmin = false

  async mounted() {
    TrackingHelper.track(AppTrackEvent.contactOpened)

    this.userId = sessionModule.user?.id ?? null
    this.currentResidenceId = sessionModule.currentResidence?.id ?? null
    const residencePermission = sessionModule.user?.residencePermissions.find(
      (perm) => (perm.residence?.id ?? null) === this.currentResidenceId
    )

    this.isAdmin = residencePermission == null || residencePermission.role === UserResidenceRole.Admin

    if (!this.isAdmin) {
      this.status = Status.Loading
      await this.loadMessage()
      this.scrollToBottom()
    } else {
      this.status = Status.Ready
    }
  }

  destroyed() {
    if (this.intervalInstance !== -1) {
      window.clearInterval(this.intervalInstance)
    }
    if (this.timeoutInstance !== -1) {
      window.clearTimeout(this.timeoutInstance)
    }
  }

  async sendMessage() {
    if (!this.currentResidenceId || this.isAdmin) {
      this.$bvModal.show("popup-error")
      this.errorTitle = "Résidence inconnue"
      this.error = "Seul un résident peut envoyer un message de contact."

      return
    }

    if (!this.message) {
      this.$bvModal.show("popup-error")
      this.errorTitle = "Message vide"
      this.error = "Veuillez saisir un message pour envoyer votre demande de contact."

      return
    }

    if (this.message.length > 2000) {
      this.$bvModal.show("popup-error")
      this.errorTitle = "Message trop long"
      this.error = "Votre message est trop long. Veuillez le limiter à 2000 caractères."

      return
    }

    this.isLoading = true
    try {
      const threadMessage = await APIClient.sendMessage(this.currentResidenceId, this.message)
      TrackingHelper.track(AppTrackEvent.contactMessageSubmitted)

      if (threadMessage) {
        this.messages.push(threadMessage)

        await this.loadMessage()

        this.message = ""

        this.setTimeout()
      }
    } catch (error) {
      showError(this.$bvToast, "Une erreur est survenue lors de l'envoie du message", new Error((error as Error).message))
    }
    this.isLoading = false
  }

  async loadMessage() {
    if (this.intervalInstance !== -1) {
      window.clearInterval(this.intervalInstance)
    }

    this.intervalInstance = window.setInterval(async () => {
      await this.updateMessages()
    }, 10000)
    await this.updateMessages()

    if (this.status !== Status.Error) {
      this.status = Status.Ready
    }
  }

  setTimeout() {
    if (this.timeoutInstance !== -1) {
      window.clearTimeout(this.timeoutInstance)
    }

    this.isSent = true

    this.timeoutInstance = window.setTimeout(() => {
      this.isSent = false
    }, 600000) // 10 min
  }

  async updateMessages() {
    if (!this.currentResidenceId || !this.userId) {
      return
    }

    try {
      const thread = await APIClient.getThread(this.currentResidenceId)

      this.authors = thread?.authors ?? []
      this.messages = thread?.messages ?? []
      this.confirmMessage = thread?.confirmMessage
        ? thread.confirmMessage
        : "Votre message a bien été envoyé. Nous vous répondrons dans les plus bref délais."

      if (thread && this.messages.length > 0) {
        const message = this.messages[this.messages.length - 1]

        const author = this.authors.find((value) => value.id === message.authorId && value.userId !== thread.userId)

        if (author) {
          if (this.timeoutInstance !== -1) {
            window.clearTimeout(this.timeoutInstance)
          }
          this.isSent = false
        }
      }

      this.status = Status.Ready
      this.scrollToBottom()
    } catch {
      this.status = Status.Error
      if (this.intervalInstance !== -1) {
        window.clearInterval(this.intervalInstance)
      }
    }
  }

  scrollToBottom() {
    const chatHistory = document.getElementById("chat-history")

    if (chatHistory) {
      chatHistory.scrollTop = chatHistory.scrollHeight
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.contact {
  height: 100%;
  display: flex;
  flex-flow: column;

  .chat-container {
    height: 100%;

    .chat-history {
      width: 100%;
      flex: 1;
      overflow-y: auto;
      padding: 40px;

      .message {
        padding: 20px 0;
      }

      .is-sent {
        @include title-h3();
        color: $text-body;
        text-align: right;
      }
    }
  }
}
</style>
