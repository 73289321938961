import Vue from "vue"
import Router, { NavigationGuard } from "vue-router"
import { sessionModule } from "../store/modules/session"
import store from "../store"

Vue.use(Router)

const ifNotAuthenticated: NavigationGuard = (_, __, next) => {
  if (!store.getters["session/isAuthenticated"]) {
    next()
    return
  }

  next("/")
}

const ifAuthenticated: NavigationGuard = (_, __, next) => {
  if (store.getters["session/isAuthenticated"]) {
    next()
    return
  }

  next("/login")
}

const hasCurrentResidence: NavigationGuard = (_, __, next) => {
  if (store.getters["session/isAuthenticated"]) {
    if (store.getters["session/hasCurrentResidence"]) {
      next()
      return
    }
    next("/list-residence")
    return
  }

  next("/login")
}

export default new Router({
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("@/pages/home.vue"),
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/pages/login.vue"),
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: "/list-residence",
      name: "ListResidence",
      component: () => import("@/pages/list-residence.vue"),
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/calendar",
      name: "Calendar",
      component: () => import("@/pages/calendar.vue"),
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/forgot-password",
      name: "ForgetPassword",
      component: () => import("@/pages/forgot-password.vue"),
      props: (route) => ({ token: route.query.token, email: route.query.email }),
      beforeEnter: ifNotAuthenticated,
    },

    {
      path: "/profile",
      name: "Profile",
      component: () => import("@/pages/profile.vue"),
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/change-password",
      name: "ResetPassword",
      component: () => import("@/pages/change-password.vue"),
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/shopping",
      name: "Shopping",
      component: () => import("@/pages/shopping.vue"),
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/activities",
      name: "Activities",
      component: () => import("@/pages/activities.vue"),
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/restaurant/:date?",
      name: "Restaurant",
      component: () => import("@/pages/restaurant.vue"),
      props: true,
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/residence",
      name: "Residence",
      component: () => import("@/pages/residence.vue"),
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/links",
      name: "UsefulLinks",
      component: () => import("@/pages/links.vue"),
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/albums",
      name: "Albums",
      component: () => import("@/pages/albums.vue"),
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/services-catalog",
      name: "ServicesCatalog",
      component: () => import("@/pages/services-catalog.vue"),
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/surveys",
      name: "Surveys",
      component: () => import("@/pages/surveys.vue"),
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/list-photo/:albumId",
      name: "ListPhoto",
      component: () => import("@/pages/list-photo.vue"),
      props: true,
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/contact",
      name: "Contact",
      component: () => import("@/pages/contact.vue"),
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/around-me",
      name: "AroundMe",
      component: () => import("@/pages/around-me.vue"),
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/question/:surveyId",
      name: "Question",
      component: () => import("@/pages/question.vue"),
      props: true,
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/pdf-viewer/:pdfId",
      name: "PdfViewer",
      component: () => import("@/pages/pdf-viewer.vue"),
      props: true,
      beforeEnter: hasCurrentResidence,
    },
    {
      path: "/screen",
      name: "Screen",
      component: () => import("@/pages/screen.vue"),
      beforeEnter: async (_, __, next) => {
        await sessionModule.logout()
        next()
      },
    },
  ],
})
