<template>
  <div class="pdf-document">
    <InternetError v-if="status === 'error'" :retry="loadPdf" />
    <div v-else-if="status !== 'ready'" class="loader"></div>
    <div v-else class="pdf-block">
      <div class="pdf-container">
        <PdfPage v-for="page in pages" :key="page.pageNumber" :page="page" :scale="scale" />
      </div>
      <button class="jda-btn btn-add" @click="zoomIn()">
        <img src="@/assets/images/zoom-in.png" srcset="@/assets/images/zoom-in@2x.png 2x, @/assets/images/zoom-in@3x.png 3x" alt="Zoomer" />
      </button>
      <button class="jda-btn btn-sub" @click="zoomOut()">
        <img
          src="@/assets/images/zoom-out.png"
          srcset="@/assets/images/zoom-out@2x.png 2x, @/assets/images/zoom-out@3x.png 3x"
          alt="Dézoomer"
        />
      </button>
    </div>
    <div ref="pdfContainer"></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { sessionModule } from "@/store/modules/session"
import { Status } from "@/types/Status"
import { PDFPageProxy } from "pdfjs-dist/types/src/display/api"
import { getRealFilePath } from "@/types/File"
import pdfjs from "./pdfjs"

@Component({
  components: {
    PdfPage: async () => await import("./pdf-page.vue"),
    InternetError: async () => await import("@/components/internet-error.vue"),
  },
})
export default class PDFDocument extends Vue {
  @Prop({ type: String, required: true }) readonly pdfId!: string

  pages: PDFPageProxy[] = []
  scale = 1
  increment = 0.25
  status = Status.New

  $refs!: {
    pdfContainer?: HTMLDivElement
  }

  async mounted() {
    await this.loadPdf()
  }

  async loadPdf() {
    this.status = Status.Loading

    try {
      const pdf = await pdfjs.getDocument({
        url: getRealFilePath(this.pdfId, "client"),
        httpHeaders: sessionModule.accessToken ? { Authorization: `Bearer ${sessionModule.accessToken}` } : undefined,
        withCredentials: true,
      }).promise

      const pagePromises: Array<Promise<PDFPageProxy>> = []
      for (let i = 1; i <= pdf.numPages; i++) {
        pagePromises.push(pdf.getPage(i))
      }

      this.pages = await Promise.all(pagePromises)

      if (this.$refs.pdfContainer) {
        // Container Width - (padding left + padding right)
        this.scale = (this.$refs.pdfContainer.clientWidth - 80) / this.pages[0].getViewport({ scale: 1 }).width
      }

      this.status = Status.Ready
    } catch {
      this.status = Status.Error
    }
  }

  zoomIn() {
    this.scale += this.increment
  }

  zoomOut() {
    if (this.scale <= this.increment) {
      return
    }

    this.scale -= this.increment
  }
}
</script>

<style scoped lang="scss">
.pdf-document {
  width: 100%;
  height: 100%;

  .pdf-block {
    height: 100%;
    position: relative;

    .pdf-container {
      height: 100%;
      padding: 40px;
      border-radius: 8px;
      overflow: auto;
      text-align: center;
      background-color: white;
    }

    .btn-add {
      bottom: 104px;
    }

    .btn-sub {
      bottom: 24px;
    }

    .btn-add,
    .btn-sub {
      width: 56px;
      height: 56px;
      position: absolute;
      right: 24px;
    }
  }
}
</style>
