<template>
  <div class="team-member-block d-flex flex-wrap align-items-center justify-content-center">
    <ImageUrl class="photo" :file-id="teamMember.photoId" :size="FileSize.MEDIUM_SQUARE" />
    <div class="content">
      <div>
        <h5 class="name">{{ name }}</h5>
        <img
          v-if="isCoordinator"
          class="team-icon"
          src="@/assets/images/day.png"
          srcset="@/assets/images/day@2x.png 2x, @/assets/images/day@3x.png 3x"
        />
        <img
          v-if="isNightWalker"
          class="team-icon"
          src="@/assets/images/night.png"
          srcset="@/assets/images/night@2x.png 2x, @/assets/images/night@3x.png 3x"
        />
      </div>
      <h6 class="job">{{ job }}</h6>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { getJob } from "@/types/Team"
import { Job, TeamMemberFragment } from "@/queries/client/graphql"
import { FileSize } from "@/types/FileSize"

@Component({
  components: {
    ImageUrl: async () => await import("@/components/image-url.vue"),
  },
})
export default class TeamMemberBlock extends Vue {
  @Prop({ type: Object, required: true }) readonly teamMember!: TeamMemberFragment

  FileSize = FileSize

  get name() {
    return `${this.teamMember.firstName} ${this.teamMember.lastName}`
  }

  get job() {
    return getJob(this.teamMember.job)
  }

  get isCoordinator(): boolean {
    return this.teamMember.job === Job.Coordinator
  }

  get isNightWalker(): boolean {
    return this.teamMember.job === Job.NightWalker
  }
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.team-member-block {
  .photo {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    border: solid 1px $border;
    object-fit: cover;
  }

  .content {
    padding-left: 8px;
    padding-right: 8px;

    .name {
      display: inline;
      margin-right: 8px;
    }

    .job {
      color: $text-body;
      font-weight: normal;
    }

    .name,
    .job {
      text-align: left;
    }

    .team-icon {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
