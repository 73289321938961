<template>
  <button :class="{ secondary }" @click="$router.go(-1)">
    <div class="d-flex">
      <img
        class="arrow"
        :src="require('@/assets/images/back-' + type + '.png')"
        :srcset="require(`@/assets/images/back-${type}@2x.png`) + ' 2x ,' + require(`@/assets/images/back-${type}@3x.png`) + ' 3x'"
      />
      <h5 class="name flex-grow-1">Retour</h5>
    </div>
  </button>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class ButtonBack extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) readonly secondary!: boolean

  get type() {
    return this.secondary ? "secondary" : "primary"
  }
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.jda-btn {
  padding-left: 16px;
  padding-right: 16px;

  &.btn-default {
    background-color: $background-primary;
  }

  .arrow {
    object-fit: contain;
  }

  .name {
    padding-left: 15px;
  }

  &.secondary {
    .name {
      color: white;
    }
  }

  @media (max-width: $sm) {
    .name {
      display: none;
    }
  }
}
</style>
