<template>
  <screen-base title="Restauration" :sub-title="date">
    <div class="column">
      <screen-card
        title="Déjeuner"
        :subtitle="lunch.specialLabel"
        center
        class="full-height lunch"
        :class="{ special: lunch.specialLabel }"
      >
        <screen-menu-item v-if="lunch" :data="lunch" />
        <p v-else class="coming-soon">Prochainement disponible...</p>
      </screen-card>
    </div>
    <div class="column">
      <screen-card title="Dîner" center class="full-height dinner">
        <screen-menu-item v-if="dinner" :data="dinner" />
        <p v-else class="coming-soon">Prochainement disponible...</p>
      </screen-card>
    </div>
  </screen-base>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator"
import DateHelper from "../../helpers/DateHelper"
import { MealFragment, MealType, MenuFragment } from "../../queries/screen/graphql"

@Component({
  components: {
    "screen-base": () => import("./screen-base.vue"),
    "screen-card": () => import("./screen-card.vue"),
    "screen-menu-item": () => import("./screen-menu-item.vue"),
  },
})
export default class ScreenMenuDay extends Vue {
  @Prop({ type: Object, required: true }) private readonly data!: MenuFragment

  get date(): string {
    return DateHelper.getDateStringFormat(new Date(this.data.date), "EEEE d MMMM yyyy").toUpperCase()
  }

  get lunch(): MealFragment | null {
    return this.data.meals.find((meal) => meal.type === MealType.Lunch) ?? null
  }

  get dinner(): MealFragment | null {
    return this.data.meals.find((meal) => meal.type === MealType.Dinner) ?? null
  }
}
</script>

<style lang="scss">
@import "../../style/_variables.scss";

.column {
  width: 49.8%;
  height: 100%;
  padding: 2.63%;
  display: inline-block;
  vertical-align: top;

  .full-height {
    height: 100%;

    .menu-item > p {
      margin-bottom: 6%;
    }

    &.lunch {
      background-image: url("~@/assets/images/lunch.png");
      background-image: -webkit-image-set(url("~@/assets/images/lunch@2x.png") 2x, url("~@/assets/images/lunch@3x.png") 3x);
      background-image: image-set(url("~@/assets/images/lunch@2x.png") 2x, url("~@/assets/images/lunch@3x.png") 3x);
      background-position: bottom right;
      background-size: 31.34%;
      background-repeat: no-repeat;

      &.special {
        background-color: #dfece9;
        background-image: url("~@/assets/images/lunch-special.png");
        background-image: -webkit-image-set(
          url("~@/assets/images/lunch-special@2x.png") 2x,
          url("~@/assets/images/lunch-special@3x.png") 3x
        );
        background-image: image-set(url("~@/assets/images/lunch-special@2x.png") 2x, url("~@/assets/images/lunch-special@3x.png") 3x);

        hr {
          border-color: $secondary;
        }

        .menu-item {
          color: #3c3c3c;
        }
      }
    }

    &.dinner {
      background-image: url("~@/assets/images/dinner.png");
      background-image: -webkit-image-set(url("~@/assets/images/dinner@2x.png") 2x, url("~@/assets/images/dinner@3x.png") 3x);
      background-image: image-set(url("~@/assets/images/dinner@2x.png") 2x, url("~@/assets/images/dinner@3x.png") 3x);
      background-position: bottom left;
      background-size: 33.46%;
      background-repeat: no-repeat;
    }

    .coming-soon {
      margin: 32% 0;
      font-size: 350%;
      font-weight: 600;
      font-family: "Lobster", sans-serif;
      color: $secondary;
      text-align: center;
    }
  }
}
</style>
