import { VuexModule, Module, Action, Mutation } from "vuex-class-modules"
import store from ".."
import { setToStorage, getFromStorage, removeItemsFromLocalStorage } from "../../utils/storage"
import { isOffline } from "../../helpers/ConnectionHelper"
import APIClient from "../../api/ApiClient"
import { Status, SubStatus } from "../../types/Status"
import { loaderModule } from "./loader"
import { MenuFragment } from "../../queries/client/graphql"
import DateHelper from "../../helpers/DateHelper"

const MENUS_KEY = "menus"

type MenuWithStatus = {
  status: SubStatus
  date: string
  menu: MenuFragment | null
}

@Module
class MenuModule extends VuexModule {
  data: { [residenceId: string]: { [dateStr: string]: MenuWithStatus } } = {}

  status: Status = Status.New

  get menuWithStatus() {
    return (residenceId: string, date: Date) => this.data[residenceId][DateHelper.getDateStringFormat(date)]
  }

  @Mutation
  initResidence(residenceId: string) {
    if (this.data[residenceId] == null) {
      this.data[residenceId] = {}
    }
  }

  @Mutation
  removeLoadingData({ residenceId, dates }: { residenceId: string; dates: Date[] }) {
    dates.forEach((date) => {
      const dateStr = DateHelper.getDateStringFormat(date)

      if (this.data[residenceId][dateStr]?.status === SubStatus.Loading) {
        delete this.data[residenceId][dateStr]
      }
    })
    this.data = { ...this.data }
  }

  @Mutation
  setLoadingData({ residenceId, dates }: { residenceId: string; dates: Date[] }) {
    dates.forEach((date) => {
      const dateStr = DateHelper.getDateStringFormat(date)

      if (this.data[residenceId][dateStr] == null) {
        this.data[residenceId][dateStr] = {
          status: SubStatus.Loading,
          date: dateStr,
          menu: null,
        }
      }
    })
    this.data = { ...this.data }
  }

  @Mutation
  setData({ residenceId, menus, dates }: { residenceId: string; menus: MenuFragment[]; dates: Date[] }) {
    // Set Menus
    menus.forEach((menu) => {
      this.data[residenceId][DateHelper.getDateStringFormat(new Date(menu.date))] = {
        status: SubStatus.Ready,
        date: DateHelper.getDateStringFormat(new Date(menu.date)),
        menu,
      }
    })

    // Clean loading states
    dates.forEach((date) => {
      const dateStr = DateHelper.getDateStringFormat(date)

      if (this.data[residenceId][dateStr]?.status === SubStatus.Loading) {
        this.data[residenceId][dateStr].status = SubStatus.Ready
      }
    })
    this.data = { ...this.data }
  }

  @Mutation
  clearData() {
    removeItemsFromLocalStorage(MENUS_KEY)
    this.data = {}
    this.status = Status.New
  }

  @Mutation
  setStatus(status: Status) {
    this.status = status
  }

  @Action
  async syncMenus(residenceId: string) {
    // Prepare residence
    this.initResidence(residenceId)

    const dates: Date[] = this.getDates(new Date(), 15, 30)

    this.setLoadingData({ residenceId, dates })

    let menus: MenuFragment[]

    try {
      // Exception will be catched in SyncModule
      menus = await APIClient.getMenus(residenceId, new Date(), 15, 30)
    } catch (error) {
      this.removeLoadingData({ residenceId, dates })
      throw error
    }

    // Cache data
    setToStorage(MENUS_KEY, residenceId, menus)

    this.setData({ residenceId, menus, dates })
  }

  @Action
  async preloadMenus(residenceId: string) {
    // Prepare residence
    this.initResidence(residenceId)

    const dates: Date[] = this.getDates(new Date(), 15, 30)
    if (this.status === Status.New) {
      this.setStatus(Status.Loading)

      const localMenus = getFromStorage<MenuFragment[]>(MENUS_KEY, residenceId)
      if (localMenus != null) {
        this.setData({ residenceId, menus: localMenus, dates })
        this.setStatus(Status.Ready)
      }
    }

    await this.syncMenus(residenceId)

    if (Object.keys(this.data[residenceId]).length === 0) {
      this.setStatus(Status.Error)
    } else {
      this.setStatus(Status.Ready)
    }
  }

  @Action
  async loadMenu({ residenceId, date }: { residenceId: string; date: Date }) {
    // Prepare residence
    this.initResidence(residenceId)

    if (this.status === Status.New) {
      this.setStatus(Status.Loading)

      const localMenus = getFromStorage<MenuFragment[]>(MENUS_KEY, residenceId)
      if (localMenus != null) {
        this.setData({ residenceId, menus: localMenus, dates: [date] })
        this.setStatus(Status.Ready)
      }
    }

    const menuWithStatus = this.data[residenceId][DateHelper.getDateStringFormat(date)]
    if (menuWithStatus != null && menuWithStatus.status === SubStatus.Loading) {
      // Loading in progress
      return
    }

    if (!isOffline()) {
      loaderModule.willRefresh()

      this.setLoadingData({ residenceId, dates: [date] })

      try {
        const menu = await APIClient.getMenu(residenceId, date)
        const dateStr = DateHelper.getDateStringFormat(date)

        this.setData({ residenceId, menus: [menu ?? { id: dateStr, date: dateStr, meals: [] }], dates: [date] })
      } catch (error) {
        this.removeLoadingData({ residenceId, dates: [date] })

        // TODO
        console.log(error)
      }

      loaderModule.didRefresh()
    }

    if (Object.keys(this.data[residenceId]).length === 0) {
      this.setStatus(Status.Error)
    } else {
      this.setStatus(Status.Ready)
    }
  }

  private getDates(date: Date, numberOfDaysBefore: number, numberOfDaysAfter: number) {
    const dates: Date[] = []
    let currentDate

    for (let index = -numberOfDaysBefore; index <= numberOfDaysAfter; index += 1) {
      currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + index)
      dates.push(currentDate)
    }
    return dates
  }
}

export const menuModule = new MenuModule({ store, name: "menu" })
