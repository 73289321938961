<template>
  <div class="widget-primary">
    <Widget>
      <div v-if="title" class="title-info">
        <h3 class="title">{{ title }}</h3>
      </div>
      <slot></slot>
    </Widget>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  components: {
    Widget: async () => await import("./widget.vue"),
  },
})
export default class WidgetPrimary extends Vue {
  @Prop({ type: String, required: true }) readonly title!: string
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.widget-primary {
  .title-info {
    padding: 16px 20px 0 20px;

    .title {
      width: 100%;
      text-align: left;
      padding-bottom: 4px;
      border-bottom: 2px solid $border;
    }
  }
}
</style>
