<template>
  <div class="form-group text-form">
    <textarea v-model="text" class="form-control text" rows="4" placeholder="Tapez votre réponse ici " :disabled="disabled"></textarea>
  </div>
</template>

<script lang="ts">
import { Component, Vue, VModel, Prop } from "vue-property-decorator"

@Component
export default class TextForm extends Vue {
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean

  @VModel({ type: String }) text!: string
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.text-form {
  .text {
    margin-top: 50px;
    @include title-h3();
    min-height: 215px;
    font-weight: normal;
    color: $text-body;
    padding: 35px;
    border-radius: 8px;
    border: none;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    background-color: white;
  }

  ::placeholder {
    color: $disabled;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: $disabled;
  }

  ::-ms-input-placeholder {
    color: $disabled;
  }
}
</style>
