import { format, startOfWeek } from "date-fns"
import { fr } from "date-fns/locale"

class DateHelper {
  getShortWeekDays(): string[] {
    const date = startOfWeek(new Date(), { weekStartsOn: 1 })
    const weekdays: string[] = []
    for (let index = 0; index < 7; index += 1) {
      weekdays.push(format(date, "iiiiii", { locale: fr }))
      date.setDate(date.getDate() + 1)
    }
    return weekdays
  }

  getWeekDays(): string[] {
    const date = startOfWeek(new Date(), { weekStartsOn: 1 })
    const weekdays: string[] = []
    for (let index = 0; index < 7; index += 1) {
      weekdays.push(format(date, "iiii", { locale: fr }))
      date.setDate(date.getDate() + 1)
    }
    return weekdays
  }

  getMonths(): string[] {
    const date = new Date()
    date.setMonth(0)
    const months: string[] = []
    for (let index = 0; index < 12; index += 1) {
      months.push(format(date, "MMMM", { locale: fr }))
      date.setMonth(date.getMonth() + 1)
    }
    return months
  }

  /**
   * @param formatStr default format string "yyyy-MM-dd"
   */
  getDateStringFormat(date: Date, formatStr = "yyyy-MM-dd"): string {
    return format(date, formatStr, { locale: fr })
  }

  getDateWithoutTime(): Date {
    const date = new Date()
    return new Date(date.getFullYear(), date.getMonth(), date.getDate())
  }

  isDateEqual(date1: Date, date2: Date): boolean {
    return this.getDateStringFormat(date1) === this.getDateStringFormat(date2)
  }
}

const instance = new DateHelper()

export default instance
