import {
  ResidenceResource,
  ResidencePermission,
  UserResidenceRole,
  FeatureFragment,
  FeatureType,
  FeatureStatus,
} from "../queries/client/graphql"

export enum HomeResourceType {
  CALENDAR,
  ACTIVITY,
  RESTAURANT,
  PHOTO,
  SERVICE,
  RESIDENCE,
  AROUND_ME,
  SHOPPING,
  LINK,
  SURVEY,
  PROFILE,
  CONTACT,
  NONE,
}

export interface HomeResource {
  type: HomeResourceType
  status: FeatureStatus
  typeName: string
  name: string
}

export function getHomeResources(permission: ResidencePermission, features: FeatureFragment[]): HomeResource[] {
  const indexedFeatures: { [key: string]: FeatureStatus } = {}
  features.forEach((feature) => {
    indexedFeatures[feature.type] = feature.status
  })

  const resources: HomeResource[] = [
    {
      type: HomeResourceType.CALENDAR,
      typeName: "calendar",
      name: "Calendrier",
      status: indexedFeatures[FeatureType.Calendar] ?? FeatureStatus.Enabled,
    },
    {
      type: HomeResourceType.RESIDENCE,
      typeName: "residence",
      name: "Ma résidence",
      status: indexedFeatures[FeatureType.Residence] ?? FeatureStatus.Enabled,
    },
    {
      type: HomeResourceType.PROFILE,
      typeName: "profile",
      name: "Profil",
      status: indexedFeatures[FeatureType.Profile] ?? FeatureStatus.Enabled,
    },
  ]

  for (let index = 0; index < permission.clientResources.length; index += 1) {
    const clientResource = permission.clientResources[index]
    switch (clientResource) {
      case ResidenceResource.Activity:
        resources.push({
          type: HomeResourceType.ACTIVITY,
          typeName: "activities",
          name: "Activités",
          status: indexedFeatures[FeatureType.Activity] ?? FeatureStatus.Enabled,
        })
        break
      case ResidenceResource.Menu:
        resources.push({
          type: HomeResourceType.RESTAURANT,
          typeName: "food",
          name: "Restaurant",
          status: indexedFeatures[FeatureType.Menu] ?? FeatureStatus.Enabled,
        })
        break
      case ResidenceResource.Gallery:
        resources.push({
          type: HomeResourceType.PHOTO,
          typeName: "photos",
          name: "Album photos",
          status: indexedFeatures[FeatureType.GalleryAlbum] ?? FeatureStatus.Enabled,
        })
        break
      case ResidenceResource.Service:
        resources.push({
          type: HomeResourceType.SERVICE,
          typeName: "list",
          name: "Services",
          status: indexedFeatures[FeatureType.Service] ?? FeatureStatus.Enabled,
        })
        break
      case ResidenceResource.Place:
        resources.push({
          type: HomeResourceType.AROUND_ME,
          typeName: "location",
          name: "Autour de moi",
          status: indexedFeatures[FeatureType.Place] ?? FeatureStatus.Enabled,
        })
        break
      case ResidenceResource.Shopping:
        resources.push({
          type: HomeResourceType.SHOPPING,
          typeName: "shopping",
          name: "Shopping",
          status: indexedFeatures[FeatureType.Product] ?? FeatureStatus.Enabled,
        })
        break
      case ResidenceResource.Link:
        resources.push({
          type: HomeResourceType.LINK,
          typeName: "websites",
          name: "Liens utiles",
          status: indexedFeatures[FeatureType.Link] ?? FeatureStatus.Enabled,
        })
        break
      case ResidenceResource.Survey:
        resources.push({
          type: HomeResourceType.SURVEY,
          typeName: "survey",
          name: "Enquête",
          status: indexedFeatures[FeatureType.Survey] ?? FeatureStatus.Enabled,
        })
        break

      default:
        break
    }
  }

  if (permission.role !== UserResidenceRole.Family && permission.role !== UserResidenceRole.Admin) {
    resources.push({ type: HomeResourceType.CONTACT, typeName: "message", name: "Contact", status: indexedFeatures[FeatureType.Contact] })
  }

  resources.sort((a, b) => a.type - b.type)

  return resources
}
