<template>
  <div class="widget-event">
    <WidgetPrimary :title="widget.title">
      <div class="widget-content">
        <div v-if="widget.type === 'ANNOUNCEMENT'" class="btn-content">
          <h5 class="title">{{ widget.content }}</h5>
        </div>
        <b-button v-else class="btn-content" variant="transparent" @click="onClick">
          <h5 class="title">{{ widget.content }}</h5>
        </b-button>
      </div>
    </WidgetPrimary>

    <b-modal v-if="widget.activityId" :id="widget.activityId" size="lg" hide-header hide-footer centered>
      <template #default="{ close }">
        <PopupActivity v-if="activity" :activity="activity" :close="close" />
      </template>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { WidgetFragment, ActivityFragment, WidgetType } from "@/queries/client/graphql"
import APIClient from "@/api/ApiClient"
import { sessionModule } from "@/store/modules/session"

@Component({
  components: {
    WidgetPrimary: async () => await import("@/components/widgets/widget-primary.vue"),
    PopupActivity: async () => await import("@/components/popups/popup-activity.vue"),
  },
})
export default class WidgetEvent extends Vue {
  @Prop({ type: Object, required: true }) readonly widget!: WidgetFragment

  activity: ActivityFragment | null = null

  get currentResidenceId() {
    return sessionModule.currentResidence?.id ?? null
  }

  async onClick() {
    if (this.widget.activityId) {
      if (!this.activity) {
        await this.loadActivity(this.widget.activityId)
      }

      this.$bvModal.show(this.widget.activityId)
    } else if (this.widget.type === WidgetType.Menu && this.widget.menuDate) {
      await this.$router.push(`restaurant/${this.widget.menuDate}`)
    }
  }

  async loadActivity(activityId: string) {
    if (!this.currentResidenceId) {
      return
    }

    this.activity = await APIClient.getActivity(this.currentResidenceId, activityId)
  }
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.widget-event {
  .widget-content {
    width: 100%;
    padding: 15px 20px 24px 20px;

    .btn-content {
      width: 100%;
      padding: 0;
      border-radius: 8px;
    }

    .title {
      padding: 10px;
      border-radius: 8px;
    }
  }

  &:nth-child(even) {
    .widget-content {
      .title {
        background-color: $activity-morning;
      }
    }
  }

  &:nth-child(odd) {
    .widget-content {
      .title {
        background-color: $activity-afternoon;
      }
    }
  }
}
</style>
