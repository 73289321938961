<template>
  <div class="screen-base">
    <screen-topbar :title="title" :sub-title="subTitle" />
    <div class="screen-content-wrapper" :class="{ flex }">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  components: {
    "screen-topbar": () => import("./screen-topbar.vue"),
  },
})
export default class ScreenBase extends Vue {
  @Prop({ type: String, required: true }) title!: string
  @Prop({ type: String, required: true }) subTitle!: string
  @Prop({ type: Boolean, required: false, default: false }) flex!: boolean
}
</script>
<style lang="scss">
@import "../../style/variables";

.screen-base {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/images/decorator.png");
  background-image: -webkit-image-set(url("~@/assets/images/decorator@2x.png") 2x, url("~@/assets/images/decorator@3x.png") 3x);
  background-image: image-set(url("~@/assets/images/decorator@2x.png") 2x, url("~@/assets/images/decorator@3x.png") 3x);
  background-position: bottom right;
  background-size: 32%;
  background-repeat: no-repeat;

  .screen-content-wrapper {
    width: 100%;
    height: 87.6%;
    padding: 2.5%;

    &.flex {
      display: flex;
    }
  }

  &.hidden {
    opacity: 0;
  }

  &.shown {
    opacity: 1;
  }

  &.intro {
    transition: opacity 400ms;
  }

  &.outro {
    transition: opacity 400ms 300ms;
  }
}
</style>
