<template>
  <div class="message-box">
    <form @submit.prevent="$emit('send')">
      <div class="form-group d-flex align-items-center">
        <div class="flex-grow-1 message-field">
          <textarea
            v-model="message"
            class="form-control message"
            rows="2"
            placeholder="Pour contacter l’accueil, écrivez votre message ici"
            :disabled="disabled"
          ></textarea>
        </div>
        <ButtonArrow class="jda-btn btn-highlight" type="submit" text="Envoyer" :disabled="disabled" arrow-right small />
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Vue, VModel, Prop } from "vue-property-decorator"

@Component({
  components: {
    ButtonArrow: async () => await import("@/components/buttons/button-arrow.vue"),
  },
})
export default class MessageField extends Vue {
  @VModel({ type: String }) message!: string

  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.message-box {
  background-color: $primary;
  padding: 40px 40px 24px 40px;
  z-index: 1;
  position: relative;

  .message-field {
    width: 100%;
    padding-right: 16px;

    .message {
      @include title-h3();
      min-height: 94px;
      padding: 12px 16px;
      font-weight: normal;
      color: $text-body;
      border-radius: 8px;
    }
  }

  @media (max-width: $sm), (max-width: $md) and (orientation: landscape) {
    padding: 16px 16px 0 16px;

    .message-field .message {
      @include title-section();
      font-weight: normal;
    }
  }

  .btn-highlight {
    width: auto;
  }
}
</style>
