import { VuexModule, Module, Mutation } from "vuex-class-modules"
import store from ".."

@Module
class LoaderModule extends VuexModule {
  refresh = 0

  get isRefreshing() {
    return this.refresh > 0
  }

  @Mutation
  didRefresh() {
    if (this.refresh > 0) {
      this.refresh -= 1
    }
  }

  @Mutation
  willRefresh() {
    this.refresh += 1
  }
}

export const loaderModule = new LoaderModule({ store, name: "loader" })
