<template>
  <div class="checkbox-form">
    <h5 class="form-type">Plusieurs réponses possible</h5>
    <Widget>
      <div v-for="(answer, index) in answers" :key="index" class="checkbox-content d-flex align-items-center">
        <button :disabled="disabled" @click="onCheck(index)">
          <div class="d-flex align-items-center">
            <div>
              <div class="checkbox">
                <img
                  class="checked"
                  :class="{ 'd-none': !answer.checked }"
                  src="@/assets/images/check/greenSea.png"
                  srcset="@/assets/images/check/greenSea@2x.png 2x, @/assets/images/check/greenSea@3x.png 3x"
                  alt="Case Cochée"
                />
              </div>
            </div>
            <div class="name">{{ answer.answer }}</div>
          </div>
        </button>
      </div>
    </Widget>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model, Watch, Emit } from "vue-property-decorator"

type Answer = {
  checked: boolean
  answer: string
}

@Component({
  components: {
    Widget: async () => await import("@/components/widgets/widget.vue"),
  },
})
export default class CheckboxForm extends Vue {
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean
  @Prop({ type: Array, default: false }) readonly currentAnswers!: string[]

  @Model("change", { type: String }) readonly value!: string

  answers: Answer[] = []
  selected: number[] = []

  created() {
    if (this.value) {
      this.selected = JSON.parse(this.value) as number[]
    }

    this.answers = this.currentAnswers.map((answer, index) => ({
      checked: this.selected.some((value) => value === index),
      answer,
    }))
  }

  onCheck(index: number) {
    this.answers[index].checked = !this.answers[index].checked

    if (this.answers[index].checked) {
      this.selected.push(index)
    } else {
      const selectedIndex = this.selected.findIndex((value) => value === index)
      this.selected.splice(selectedIndex, 1)
    }
  }

  @Watch("selected")
  onInputValueChange() {
    this.onModelChange(this.selected)
  }

  @Emit("change")
  onModelChange(value: number[]): string {
    return JSON.stringify(value)
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.checkbox-form {
  width: 100%;
  max-width: 510px;
  margin: 0 auto;

  .form-type {
    color: $text-body;
    font-style: italic;
    font-weight: normal;
  }

  .widget {
    padding: 27.5px 32px;
    margin-top: 50px;

    .checkbox-content {
      padding: 17.5px 0;
      max-width: 510px;

      .name {
        @include title-h3();
        font-weight: normal;
        padding-right: 15px;
      }

      .checkbox {
        width: 20px;
        height: 20px;
        margin-right: 32px;
        border-radius: 4px;
        border: solid 1.5px $secondary;
        background-color: rgba($background-default, 0.5);
        position: relative;

        .checked {
          position: absolute;
          bottom: 1px;
          left: 2px;
        }
      }
    }
  }
}
</style>
