<template>
  <screen-card class="activity-card" :class="{ now: isActivityNow }">
    <div :class="iconClasses">
      <img v-if="iconUrl" :src="iconUrl" />
      <img
        v-else
        src="../../assets/images/icon-default.png"
        srcset="../../assets/images/icon-default@2x.png 2x, ../../assets/images/icon-default@3x.png 3x"
      />
    </div>
    <div class="infos">
      <p class="title">{{ data.name }}</p>
      <div class="schedule">
        <img src="../../assets/images/time.png" srcset="../../assets/images/time@2x.png 2x, ../../assets/images/time@3x.png 3x" />
        <p>{{ schedule }}</p>
      </div>
    </div>
  </screen-card>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator"
import DateHelper from "../../helpers/DateHelper"
import { getRealFilePath } from "../../types/File"
import { FileSize } from "../../types/FileSize"
import { ActivityFragment } from "../../queries/screen/graphql"

@Component({
  components: {
    "screen-card": () => import("./screen-card.vue"),
  },
})
export default class ScreenCardActivity extends Vue {
  @Prop({ type: Object, required: true }) readonly data!: ActivityFragment

  screenToken: string | null = null

  created() {
    const token = this.$route.query.token
    if (token != null && typeof token === "string") {
      this.screenToken = token
    }
  }

  get isMorning(): boolean {
    return this.data.startDateTime.getHours() < 12
  }

  get iconClasses(): string {
    return `icon-container ${this.isMorning ? "morning" : "afternoon"}`
  }

  get iconUrl(): string | null {
    if (!this.data.iconMediaId || !this.screenToken) {
      return null
    }

    return `${getRealFilePath(this.data.iconMediaId, "screen", FileSize.SMALL_SQUARE)}&apiKey=${this.screenToken}`
  }

  get schedule(): string {
    const startDate = DateHelper.getDateStringFormat(this.data.startDateTime, "H-mm").replace("-", "h")
    const endDate = DateHelper.getDateStringFormat(this.data.endDateTime, "H-mm").replace("-", "h")

    return `${startDate} - ${endDate}`
  }

  get isActivityNow(): boolean {
    const now = new Date().getTime()

    return this.data.startDateTime.getTime() <= now && now < this.data.endDateTime.getTime()
  }
}
</script>

<style lang="scss">
@import "../../style/_variables.scss";

.screen-card.activity-card {
  display: inline-block;
  height: 74%;
  padding: 0.8%;
  margin: 1%;
  border: solid 2px white;

  p {
    margin: 0;
  }

  &.now {
    border: solid 2px $secondary;
  }

  .icon-container {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    border-radius: 4px;
    margin-right: 8px;
    padding: 8px;

    > img {
      width: auto;
      height: 100%;
    }

    &.morning {
      background-color: $activity-morning;
    }

    &.afternoon {
      background-color: $activity-afternoon;
    }
  }

  .infos {
    display: inline-block;
    height: 100%;
    line-height: normal;
    vertical-align: middle;

    .title {
      color: $secondary;
      font-size: 175%;
      font-weight: 600;
    }

    .schedule {
      height: 36.84%;
      font-size: 112.5%;

      > img {
        height: 100%;
      }

      > p {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
</style>
