<template>
  <component :is="tag">
    <template v-for="(line, index) in content.split('\n')">
      <br v-if="index !== 0" :key="`br-${index}`" />
      {{ line }}
    </template>
  </component>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class Nl2Br extends Vue {
  @Prop({ type: String, required: true }) readonly content!: string
  @Prop({ type: String, required: false, default: "div" }) readonly tag!: string
}
</script>
