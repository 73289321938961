<template>
  <button class="button-loading jda-btn" :disabled="disabled" @click="click">
    {{ content }}
    <div v-if="status == 'loading'" class="loader"></div>
  </button>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class ButtonLoading extends Vue {
  @Prop({ type: String, required: true }) readonly title!: string
  @Prop({ type: String, default: "enabled" }) readonly status!: string
  @Prop({ type: Function, required: false, default: () => {} }) readonly click!: () => void

  get disabled() {
    return this.status !== "enabled"
  }

  get content() {
    return this.status === "enabled" ? this.title : ""
  }
}
</script>
