import { BvToast } from "bootstrap-vue"

export function showError(bvToast: BvToast, title: string, error: Error) {
  bvToast.toast(error.message, {
    title,
    autoHideDelay: 10000,
    variant: "danger",
    toaster: "b-toaster-top-center",
    appendToast: true,
  })
}

export function showSuccess(bvToast: BvToast, title: string, message: string) {
  bvToast.toast(message, {
    title,
    autoHideDelay: 10000,
    variant: "success",
    toaster: "b-toaster-top-center",
    appendToast: true,
  })
}
