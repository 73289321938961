<template>
  <div class="widget-weather">
    <Widget>
      <div class="widget-content">
        <b-container>
          <b-row>
            <b-col class="m-auto d-none">
              <img
                class="icon icon-meteo"
                src="@/assets/images/weather.png"
                srcset="@/assets/images/weather@2x.png 2x, @/assets/images/weather@3x.png 3x"
              />
              <h5>Toulouse</h5>
              <h6>Averses et éclaircies, 10°</h6>
            </b-col>
            <b-col class="m-auto">
              <div class="calendar">
                <img
                  class="icon icon-calendar"
                  src="@/assets/images/calendar.png"
                  srcset="@/assets/images/calendar@2x.png 2x, @/assets/images/calendar@3x.png 3x"
                />

                <h1 class="date">{{ date.getDate() }}</h1>
              </div>
              <h5>{{ date | fullDateFormat | capitalize }}</h5>
              <h6 class="d-none">St Daniel</h6>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </Widget>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"

@Component({
  components: {
    Widget: async () => await import("@/components/widgets/widget.vue"),
  },
})
export default class WidgetWeather extends Vue {
  date = new Date()
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.widget-weather {
  .widget-content {
    padding: 16px 24px;

    .icon {
      margin-bottom: 25px;
      object-fit: contain;

      &.icon-meteo {
        width: 121px;
      }

      &.icon-calendar {
        width: 85px;
      }
    }

    .calendar {
      text-align: center;
      position: relative;

      .date {
        width: 100%;
        position: absolute;
        top: 34px;
        font-size: 2.5rem;
      }
    }

    h5 {
      color: $text-body;
    }

    h6 {
      color: $secondary;
      font-weight: normal;
    }
  }
}
</style>
