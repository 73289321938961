<template>
  <div :class="{ 'team-item': true, vertical: orientation === 'vertical', horizontal: orientation === 'horizontal' }">
    <img class="team-photo" :src="photoUrl" />
    <div class="team-content">
      <p class="team-name">{{ name }}</p>
      <p class="team-role">{{ job }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { TeamMemberFragment } from "../../queries/screen/graphql"
import { getRealFilePath } from "../../types/File"
import { FileSize } from "../../types/FileSize"
import { getJob } from "../../types/Team"

type Orientation = "vertical" | "horizontal"

@Component
export default class ScreenTeamItem extends Vue {
  @Prop({ type: Object, required: true }) data!: TeamMemberFragment
  @Prop({ type: String, required: true }) orientation!: Orientation

  screenToken: string | null = null

  created() {
    const token = this.$route.query.token
    if (token != null && typeof token === "string") {
      this.screenToken = token
    }
  }

  get name(): string {
    return `${this.data.firstName} ${this.data.lastName}`
  }

  get job(): string {
    return getJob(this.data.job)
  }

  get photoUrl(): string {
    return `${getRealFilePath(this.data.photoId, "screen", FileSize.MEDIUM_SQUARE)}&apiKey=${this.screenToken}`
  }
}
</script>
<style scoped lang="scss">
@import "../../style/variables";

.team-item.horizontal {
  padding: 2% 0;

  .team-photo {
    width: 25.56%;
    vertical-align: middle;
    border-radius: 50%;
  }

  .team-content {
    width: 70%;
    padding-left: 4%;
    vertical-align: middle;
    display: inline-block;

    .team-name {
      font-size: 300%;
      font-weight: 600;
      color: $secondary;
    }

    .team-role {
      font-size: 225%;
      color: $text-body;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.team-item.vertical {
  padding: 8% 0;
  text-align: center;

  .team-photo {
    width: 35.56%;
    vertical-align: top;
    border-radius: 50%;
  }

  .team-content {
    margin-top: 5%;

    .team-name {
      font-size: 300%;
      font-weight: 600;
      color: $secondary;
    }

    .team-role {
      font-size: 225%;
      color: $text-body;
    }

    p {
      margin-bottom: 0;
    }
  }
}
</style>
