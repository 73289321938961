import { apolloScreen, setScreenToken } from "./apollo-provider"
import DateHelper from "../helpers/DateHelper"
import { sortTeamByJob } from "../types/Team"
import {
  MenuFragment,
  ActivityFragment,
  ScreenSlideFragment,
  ScreenSlidesForDay,
  ScreenSlidesForDayQuery,
  ScreenSlidesForDayQueryVariables,
  ScreenSlideType,
} from "../queries/screen/graphql"

class APIScreen {
  setScreenToken(screenToken: string): void {
    setScreenToken(screenToken)
  }

  // Queries
  async getScreens(): Promise<ScreenSlideFragment[]> {
    const value = await apolloScreen.query<ScreenSlidesForDayQuery, ScreenSlidesForDayQueryVariables>({ query: ScreenSlidesForDay })

    if (value.errors != null) {
      throw new Error(JSON.stringify(value.errors))
    }

    return value.data.screens.screens
      .filter((screen) => {
        switch (screen.type) {
          case ScreenSlideType.Ephemeris:
            return (
              screen.ephemeris != null &&
              (screen.ephemeris.ephemeris != null || screen.ephemeris.weather != null || screen.ephemeris.team.teamMembers.length > 0)
            )
          case ScreenSlideType.Anecdote:
            return screen.anecdote != null
          case ScreenSlideType.MenuDay:
            return screen.menuDay != null
          case ScreenSlideType.MenuNextDays:
            return screen.menuNextDays != null && screen.menuNextDays.length > 0
          case ScreenSlideType.AnimationsWeek:
            return screen.animationsWeek != null && screen.animationsWeek.length > 0
          case ScreenSlideType.Pdf:
            return screen.pdf != null
          case ScreenSlideType.Album:
            return screen.album != null
        }
      })
      .map((screen) => {
        if (screen.type === ScreenSlideType.Ephemeris && screen.ephemeris?.team != null) {
          screen.ephemeris.team.teamMembers = sortTeamByJob(screen.ephemeris.team.teamMembers)
        }

        if (screen.type === ScreenSlideType.MenuNextDays && screen.menuNextDays != null) {
          const preparedMenus: MenuFragment[] = []
          const now = new Date()

          let menuApiIndex = 0

          for (let i = 0; i < 8; i += 1) {
            const date = DateHelper.getDateStringFormat(now)
            const currentData = screen.menuNextDays[menuApiIndex]

            if (currentData?.date === date) {
              preparedMenus.push(currentData)
              menuApiIndex += 1
            } else {
              preparedMenus.push({ date, meals: [] })
            }

            now.setDate(now.getDate() + 1)
          }

          screen.menuNextDays = preparedMenus
        }

        if (screen.type === ScreenSlideType.AnimationsWeek && screen.animationsWeek) {
          screen.animationsWeek = screen.animationsWeek.map<ActivityFragment>((activity) => ({
            ...activity,
            startDateTime: new Date(activity.startDateTime),
            endDateTime: new Date(activity.endDateTime),
          }))
        }

        return screen
      })
  }
}

const instance = new APIScreen()

export default instance
