<template>
  <div class="widget">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"

@Component
export default class Widget extends Vue {}
</script>

<style scoped lang="scss">
.widget {
  width: 100%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  background-color: white;
  max-height: 100%;
  overflow: auto;
}
</style>
