<template>
  <form class="form-text-field" @submit.prevent="submitForm">
    <div class="textfield">
      <TextField
        v-for="(field, index) in fields"
        :key="index"
        v-model="fieldValues[field.name]"
        :label="field.label"
        :name="field.name"
        :type="field.type"
        :placeholder="field.placeholder"
        :disabled="status !== 'enabled'"
      />
      <div v-if="error" class="error">{{ error }}</div>
    </div>
    <div v-if="buttonVisible" class="btn-submit">
      <ButtonLoading class="btn-highlight" :title="buttonLabel" :status="status" type="submit" />
    </div>
  </form>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model, Emit, Watch } from "vue-property-decorator"
import TextFieldItem from "./types/TextFieldItem"

@Component({
  components: {
    TextField: async () => await import("./text-field.vue"),
    ButtonLoading: async () => await import("@/components/buttons/button-loading.vue"),
  },
})
export default class FormTextField extends Vue {
  @Prop({ type: Array, required: true }) readonly fields!: Array<TextFieldItem>
  @Prop({ type: String, required: true }) readonly buttonLabel!: string
  @Prop({ type: String, default: "" }) readonly error!: string
  @Prop({ type: String, default: "enabled" }) readonly status!: string
  @Prop({ type: Boolean, default: true }) readonly buttonVisible!: boolean

  @Model("change", { type: Object }) readonly values!: { [key: string]: string }

  fieldValues: { [key: string]: string } | null = null

  created() {
    this.fieldValues = this.values
  }

  @Watch("fieldValues", { deep: true })
  onFieldValuesChange() {
    if (!this.fieldValues) {
      return
    }

    this.onModelChange()
  }

  @Emit("change")
  onModelChange() {
    return this.fieldValues
  }

  @Emit("submit")
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  submitForm() {}
}
</script>

<style lang="scss">
@import "@/style/mixins";

.form-text-field {
  text-align: left;

  .textfield {
    position: relative;

    .error {
      width: 100%;
      position: absolute;
      color: red;
      bottom: -25px;
      left: 5px;
    }
  }

  .btn-submit {
    padding-top: 24px;
  }

  .btn-highlight {
    height: 56px;
    @include title-body();
  }
}
</style>
