/* eslint-disable */
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: Date;
  Time: string;
  UUID: any;
};

export type Activity = {
  description?: Maybe<Scalars['String']>;
  endDateTime: Scalars['DateTime'];
  icon?: Maybe<Icon>;
  iconId?: Maybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  photoId?: Maybe<Scalars['UUID']>;
  startDateTime: Scalars['DateTime'];
};

export type ActivityQuery = {
  activities: Array<Activity>;
  activitiesForDay: Array<Activity>;
  activity?: Maybe<Activity>;
};


export type ActivityQueryActivitiesArgs = {
  dateStart: Scalars['Date'];
  numberOfDays: Scalars['Int'];
};


export type ActivityQueryActivitiesForDayArgs = {
  date: Scalars['Date'];
};


export type ActivityQueryActivityArgs = {
  activityId: Scalars['UUID'];
};

export type AnswersSubmitSurvey = {
  answer: Scalars['String'];
  questionId: Scalars['UUID'];
};

export type CalendarDay = {
  activities: Array<Activity>;
  date: Scalars['Date'];
  menu?: Maybe<Menu>;
  team?: Maybe<Team>;
};

export type CalendarQuery = {
  calendar: CalendarDay;
  calendars: Array<CalendarDay>;
};


export type CalendarQueryCalendarArgs = {
  date: Scalars['Date'];
};


export type CalendarQueryCalendarsArgs = {
  dateStart: Scalars['Date'];
  numberOfDays: Scalars['Int'];
};

export type CategoryQuery = {
  place: PlaceCategoryQuery;
};

export type ContactMutation = {
  sendMessage: ThreadMessage;
};


export type ContactMutationSendMessageArgs = {
  message: Scalars['String'];
  residenceId: Scalars['UUID'];
};

export type ContactQuery = {
  thread?: Maybe<Thread>;
};


export type ContactQueryThreadArgs = {
  residenceId: Scalars['UUID'];
};

export type DataEvent = {
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  lastReadDate: Scalars['DateTime'];
  lastUpdatedDate: Scalars['DateTime'];
  objectId: Scalars['UUID'];
  status: DataEventStatus;
  type: DataEventType;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['UUID'];
};

export enum DataEventStatus {
  New = 'NEW',
  Read = 'READ'
}

export enum DataEventType {
  Contact = 'CONTACT',
  Gallery = 'GALLERY',
  Survey = 'SURVEY'
}

export type FaqEntry = {
  answer: Scalars['String'];
  id: Scalars['UUID'];
  question: Scalars['String'];
};

export type FaqQuery = {
  faq: Array<FaqEntry>;
};

export type Feature = {
  id: Scalars['String'];
  status: FeatureStatus;
  type: FeatureType;
};

export enum FeatureStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  Hidden = 'HIDDEN'
}

export enum FeatureType {
  Activity = 'ACTIVITY',
  Calendar = 'CALENDAR',
  Contact = 'CONTACT',
  GalleryAlbum = 'GALLERY_ALBUM',
  Link = 'LINK',
  Menu = 'MENU',
  Place = 'PLACE',
  Product = 'PRODUCT',
  Profile = 'PROFILE',
  Residence = 'RESIDENCE',
  Service = 'SERVICE',
  Survey = 'SURVEY'
}

export type GalleryAlbum = {
  date: Scalars['Date'];
  id: Scalars['UUID'];
  lastUpdateDateTime: Scalars['DateTime'];
  photos: Array<GalleryPhoto>;
  thumbnailId?: Maybe<Scalars['UUID']>;
  title: Scalars['String'];
};

export type GalleryPhoto = {
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  media: MediaItem;
  mediaId: Scalars['UUID'];
};

export type GalleryQuery = {
  album: GalleryAlbum;
  albums: Array<GalleryAlbum>;
  photo: GalleryPhoto;
};


export type GalleryQueryAlbumArgs = {
  albumId: Scalars['String'];
};


export type GalleryQueryPhotoArgs = {
  photoId: Scalars['String'];
};

export type Icon = {
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  mediaId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type InputSubmitSurvey = {
  answers: Array<AnswersSubmitSurvey>;
};

export enum Job {
  Coordinator = 'COORDINATOR',
  NightWalker = 'NIGHT_WALKER'
}

export type Link = {
  category: LinkCategory;
  id: Scalars['UUID'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type LinkCategory = {
  id: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type LinkQuery = {
  links: Array<Link>;
};

export type Meal = {
  content: Array<MealContentData>;
  id: Scalars['UUID'];
  specialLabel?: Maybe<Scalars['String']>;
  type: MealType;
};

export type MealContent = {
  id: Scalars['UUID'];
  label: Scalars['String'];
};

export type MealContentData = {
  content: Array<MealContent>;
  type: MealContentType;
};

export enum MealContentType {
  Desert = 'DESERT',
  Main = 'MAIN',
  Starter = 'STARTER'
}

export enum MealType {
  Dinner = 'DINNER',
  Lunch = 'LUNCH'
}

export type MediaItem = {
  id: Scalars['String'];
  type: MediaItemType;
};

export enum MediaItemType {
  Jpeg = 'JPEG',
  Pdf = 'PDF',
  Png = 'PNG'
}

export type Menu = {
  date: Scalars['Date'];
  id: Scalars['UUID'];
  meals: Array<Meal>;
};

export type MenuQuery = {
  menu?: Maybe<Menu>;
  menus: Array<Menu>;
};


export type MenuQueryMenuArgs = {
  date: Scalars['Date'];
};


export type MenuQueryMenusArgs = {
  date: Scalars['Date'];
  numberOfDaysAfter: Scalars['Int'];
  numberOfDaysBefore: Scalars['Int'];
};

export type Mutation = {
  contact: ContactMutation;
  readDataEvent: Scalars['Boolean'];
  residence: ResidenceMutation;
  user: UserMutation;
  version: Scalars['String'];
};


export type MutationReadDataEventArgs = {
  residenceId: Scalars['UUID'];
  type: DataEventType;
};

export type Place = {
  address: Scalars['String'];
  category: PlaceCategory;
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  subTitle: Scalars['String'];
  title: Scalars['String'];
};

export type PlaceCategory = {
  color: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type PlaceCategoryQuery = {
  categories: Array<PlaceCategory>;
  category: PlaceCategory;
};


export type PlaceCategoryQueryCategoryArgs = {
  categoryId: Scalars['UUID'];
};

export type PlaceQuery = {
  places: Array<Place>;
};

export type Product = {
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['UUID'];
  photoId: Scalars['UUID'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type Query = {
  category: CategoryQuery;
  contact: ContactQuery;
  dataEvents: Array<DataEvent>;
  residence: ResidenceQuery;
  user: UserQuery;
  version: Scalars['String'];
};


export type QueryDataEventsArgs = {
  residenceId: Scalars['UUID'];
};

export type Residence = {
  address: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['String'];
  mapId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  reference: Scalars['String'];
  schedule: Scalars['String'];
  trombiId?: Maybe<Scalars['UUID']>;
};

export type ResidenceInternalMutation = {
  survey: SurveyMutation;
};

export type ResidenceInternalQuery = {
  activity: ActivityQuery;
  calendar: CalendarQuery;
  faq: FaqQuery;
  features: Array<Feature>;
  gallery: GalleryQuery;
  link: LinkQuery;
  menu: MenuQuery;
  place: PlaceQuery;
  service: ServiceQuery;
  shopping: ShoppingQuery;
  survey: SurveyQuery;
  team: TeamQuery;
  widget: WidgetQuery;
};

export type ResidenceMutation = {
  data: ResidenceInternalMutation;
};


export type ResidenceMutationDataArgs = {
  residenceId: Scalars['UUID'];
};

export type ResidencePermission = {
  clientResources: Array<ResidenceResource>;
  residence?: Maybe<Residence>;
  role: UserResidenceRole;
};

export type ResidenceQuery = {
  availableResidences: Array<Residence>;
  data: ResidenceInternalQuery;
  residence: Residence;
};


export type ResidenceQueryDataArgs = {
  residenceId: Scalars['String'];
};


export type ResidenceQueryResidenceArgs = {
  id: Scalars['String'];
};

export enum ResidenceResource {
  Activity = 'ACTIVITY',
  Faq = 'FAQ',
  Gallery = 'GALLERY',
  Link = 'LINK',
  Menu = 'MENU',
  Place = 'PLACE',
  Service = 'SERVICE',
  Shopping = 'SHOPPING',
  Survey = 'SURVEY',
  Team = 'TEAM',
  Widget = 'WIDGET'
}

export type ResidentProfile = {
  activeStay: ResidentStay;
  birthdate: Scalars['Date'];
  userId: Scalars['UUID'];
};

export type ResidentStay = {
  arrivalDate: Scalars['Date'];
  departureDate?: Maybe<Scalars['Date']>;
  id: Scalars['UUID'];
  roomNumber: Scalars['String'];
};

export type Service = {
  description: Scalars['String'];
  fileId?: Maybe<Scalars['UUID']>;
  icon?: Maybe<Icon>;
  iconId?: Maybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type ServiceQuery = {
  services: Array<Service>;
};

export type ShoppingQuery = {
  products: Array<Product>;
};

export type SpecialMeal = {
  id: Scalars['String'];
  label: Scalars['String'];
};

export type Survey = {
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  prize?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  questions: Array<SurveyQuestion>;
  submission?: Maybe<SurveySubmission>;
  submissionsCount: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SurveyMutation = {
  submit: SurveySubmission;
};


export type SurveyMutationSubmitArgs = {
  answers: InputSubmitSurvey;
  surveyId: Scalars['UUID'];
};

export type SurveyQuery = {
  survey: Survey;
  surveys: Array<Survey>;
};


export type SurveyQuerySurveyArgs = {
  surveyId: Scalars['UUID'];
};

export type SurveyQuestion = {
  answers: Array<Scalars['String']>;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  order: Scalars['Int'];
  surveyId: Scalars['UUID'];
  type: SurveyQuestionType;
  updatedAt: Scalars['DateTime'];
};

export enum SurveyQuestionType {
  Checkbox = 'CHECKBOX',
  Number = 'NUMBER',
  Radio = 'RADIO',
  Text = 'TEXT'
}

export type SurveySubmission = {
  id: Scalars['UUID'];
  isPrizeUsed: Scalars['Boolean'];
  prize?: Maybe<Scalars['String']>;
  surveyId: Scalars['String'];
  userId: Scalars['String'];
};

export type Team = {
  date: Scalars['Date'];
  teamMembers: Array<TeamMember>;
};

export type TeamMember = {
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  job: Job;
  lastName: Scalars['String'];
  photoId?: Maybe<Scalars['UUID']>;
};

export type TeamQuery = {
  team: Team;
  teams: Array<Team>;
};


export type TeamQueryTeamArgs = {
  date: Scalars['Date'];
};


export type TeamQueryTeamsArgs = {
  dateStart: Scalars['Date'];
  numberOfDays: Scalars['Int'];
};

export type Thread = {
  authors: Array<ThreadAuthor>;
  confirmMessage?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdById: Scalars['UUID'];
  id: Scalars['UUID'];
  lastMessageAt: Scalars['DateTime'];
  lastMessageBody: Scalars['String'];
  messages: Array<ThreadMessage>;
  residenceId: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['UUID']>;
  userId: Scalars['UUID'];
};

export type ThreadAuthor = {
  createdAt: Scalars['DateTime'];
  createdById: Scalars['UUID'];
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  lastName: Scalars['String'];
  threadId: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['UUID']>;
  userId: Scalars['UUID'];
};

export type ThreadMessage = {
  authorId: Scalars['UUID'];
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdById: Scalars['UUID'];
  id: Scalars['UUID'];
  threadId: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
  updatedById?: Maybe<Scalars['UUID']>;
};

export type User = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  lastName: Scalars['String'];
  residencePermissions: Array<ResidencePermission>;
  residentProfile?: Maybe<ResidentProfile>;
};

export type UserMutation = {
  deleteNotificationToken: Scalars['Boolean'];
  setNotificationToken: Scalars['Boolean'];
  setPassword: Scalars['Boolean'];
};


export type UserMutationDeleteNotificationTokenArgs = {
  deviceId: Scalars['String'];
};


export type UserMutationSetNotificationTokenArgs = {
  deviceId: Scalars['String'];
  notificationToken: Scalars['String'];
};


export type UserMutationSetPasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type UserQuery = {
  me?: Maybe<User>;
};

export enum UserResidenceRole {
  Admin = 'ADMIN',
  Family = 'FAMILY',
  Resident = 'RESIDENT',
  Viewer = 'VIEWER'
}

export type Widget = {
  activityId?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  endDateTime: Scalars['DateTime'];
  id: Scalars['UUID'];
  menuDate?: Maybe<Scalars['Date']>;
  published: Scalars['Boolean'];
  startDateTime: Scalars['DateTime'];
  title: Scalars['String'];
  type: WidgetType;
};

export type WidgetQuery = {
  widgets: Array<Widget>;
};


export type WidgetQueryWidgetsArgs = {
  dateStart: Scalars['Date'];
  numberOfDays: Scalars['Int'];
};

export enum WidgetType {
  Activity = 'ACTIVITY',
  Announcement = 'ANNOUNCEMENT',
  Menu = 'MENU'
}

export type ActivityFragment = { id: any, name: string, startDateTime: Date, endDateTime: Date, description?: string | null, location?: string | null, photoId?: any | null, icon?: IconFragment | null };

export type CalendarDayFragment = { date: string, team?: TeamFragment | null, menu?: MenuFragment | null, activities: Array<ActivityFragment> };

export type ThreadAuthorFragment = { id: any, threadId: any, userId: any, firstName: string, lastName: string };

export type ThreadMessageFragment = { id: any, authorId: any, threadId: any, body: string, createdAt: Date };

export type ThreadFragment = { id: any, userId: any, residenceId: any, lastMessageBody: string, confirmMessage?: string | null, authors: Array<ThreadAuthorFragment>, messages: Array<ThreadMessageFragment> };

export type DataEventFragment = { id: any, userId: any, type: DataEventType, objectId: any, status: DataEventStatus, lastReadDate: Date, lastUpdatedDate: Date, createdAt: Date, updatedAt: Date };

export type FaqFragment = { id: any, question: string, answer: string };

export type FeatureFragment = { type: FeatureType, status: FeatureStatus };

export type GalleryAlbumFragment = { id: any, date: string, title: string, lastUpdateDateTime: Date, thumbnailId?: any | null, photos: Array<GalleryPhotoFragment> };

export type GalleryPhotoFragment = { id: any, createdAt: Date, mediaId: any, media: { type: MediaItemType } };

export type IconFragment = { id: any, name: string, mediaId?: any | null };

export type LinkFragment = { id: any, title: string, url: string, category: LinkCategoryFragment };

export type LinkCategoryFragment = { id: string, name: string };

export type MediaItemFragment = { id: string, type: MediaItemType };

export type MealContentFragment = { id: any, label: string };

export type MealContentDataFragment = { type: MealContentType, content: Array<MealContentFragment> };

export type MealFragment = { id: any, type: MealType, specialLabel?: string | null, content: Array<MealContentDataFragment> };

export type MenuFragment = { id: any, date: string, meals: Array<MealFragment> };

export type PlaceFragment = { id: any, latitude: number, longitude: number, address: string, title: string, subTitle: string, description?: string | null, category: PlaceCategoryFragment };

export type PlaceCategoryFragment = { id: any, name: string, color: string };

export type UserFragment = { id: any, email: string, firstName: string, lastName: string, residentProfile?: ResidentProfileFragment | null, residencePermissions: Array<ResidencePermissionFragment> };

export type ResidentProfileFragment = { userId: any, birthdate: string, activeStay: ResidentStayFragment };

export type ResidentStayFragment = { id: any, departureDate?: string | null, arrivalDate: string, roomNumber: string };

export type ResidencePermissionFragment = { role: UserResidenceRole, clientResources: Array<ResidenceResource>, residence?: ResidenceFragment | null };

export type ResidenceFragment = { id: string, name: string, address: string, email: string, phone: string, schedule: string, reference: string, mapId?: any | null, trombiId?: any | null };

export type ServiceFragment = { id: any, name: string, description: string, fileId?: any | null, icon?: IconFragment | null };

export type ProductFragment = { id: any, title: string, description: string, url: string, photoId: any, updatedAt: Date, createdAt: Date };

export type SurveyFragment = { id: any, published: boolean, title: string, prize?: string | null, submissionsCount: number, createdAt: Date, updatedAt: Date, questions: Array<SurveyQuestionFragment>, submission?: SurveySubmissionFragment | null };

export type SurveyQuestionFragment = { id: any, surveyId: any, order: number, type: SurveyQuestionType, content: string, answers: Array<string>, createdAt: Date, updatedAt: Date };

export type SurveySubmissionFragment = { id: any, userId: string, prize?: string | null, surveyId: string, isPrizeUsed: boolean };

export type TeamFragment = { date: string, teamMembers: Array<TeamMemberFragment> };

export type TeamMemberFragment = { id: any, firstName: string, lastName: string, job: Job, photoId?: any | null };

export type WidgetFragment = { id: any, title: string, content: string, type: WidgetType, published: boolean, startDateTime: Date, endDateTime: Date, activityId?: string | null, menuDate?: string | null };

export type ActivitiesForDayQueryVariables = Exact<{
  residenceId: Scalars['String'];
  date: Scalars['Date'];
}>;


export type ActivitiesForDayQuery = { residence: { data: { activity: { activitiesForDay: Array<ActivityFragment> } } } };

export type ActivitiesQueryVariables = Exact<{
  residenceId: Scalars['String'];
  dateStart: Scalars['Date'];
  numberOfDays: Scalars['Int'];
}>;


export type ActivitiesQuery = { residence: { data: { activity: { activities: Array<ActivityFragment> } } } };

export type GetActivityQueryVariables = Exact<{
  residenceId: Scalars['String'];
  activityId: Scalars['UUID'];
}>;


export type GetActivityQuery = { residence: { data: { activity: { activity?: ActivityFragment | null } } } };

export type CalendarForDayQueryVariables = Exact<{
  residenceId: Scalars['String'];
  date: Scalars['Date'];
}>;


export type CalendarForDayQuery = { residence: { data: { calendar: { calendar: CalendarDayFragment } } } };

export type CalendarsQueryVariables = Exact<{
  residenceId: Scalars['String'];
  dateStart: Scalars['Date'];
  numberOfDays: Scalars['Int'];
}>;


export type CalendarsQuery = { residence: { data: { calendar: { calendars: Array<CalendarDayFragment> } } } };

export type GetThreadQueryVariables = Exact<{
  residenceId: Scalars['UUID'];
}>;


export type GetThreadQuery = { contact: { thread?: ThreadFragment | null } };

export type SendMessageMutationVariables = Exact<{
  residenceId: Scalars['UUID'];
  message: Scalars['String'];
}>;


export type SendMessageMutation = { contact: { sendMessage: ThreadMessageFragment } };

export type GetDataEventsQueryVariables = Exact<{
  residenceId: Scalars['UUID'];
}>;


export type GetDataEventsQuery = { dataEvents: Array<DataEventFragment> };

export type ReadDataEventMutationVariables = Exact<{
  type: DataEventType;
  residenceId: Scalars['UUID'];
}>;


export type ReadDataEventMutation = { readDataEvent: boolean };

export type GetFaqQueryVariables = Exact<{
  residenceId: Scalars['String'];
}>;


export type GetFaqQuery = { residence: { data: { faq: { faq: Array<FaqFragment> } } } };

export type GetFeaturesQueryVariables = Exact<{
  residenceId: Scalars['String'];
}>;


export type GetFeaturesQuery = { residence: { data: { features: Array<FeatureFragment> } } };

export type GetAlbumsQueryVariables = Exact<{
  residenceId: Scalars['String'];
}>;


export type GetAlbumsQuery = { residence: { data: { gallery: { albums: Array<GalleryAlbumFragment> } } } };

export type GetAlbumQueryVariables = Exact<{
  residenceId: Scalars['String'];
  albumId: Scalars['String'];
}>;


export type GetAlbumQuery = { residence: { data: { gallery: { album: GalleryAlbumFragment } } } };

export type GetPhotoQueryVariables = Exact<{
  residenceId: Scalars['String'];
  photoId: Scalars['String'];
}>;


export type GetPhotoQuery = { residence: { data: { gallery: { photo: GalleryPhotoFragment } } } };

export type GetLinksQueryVariables = Exact<{
  residenceId: Scalars['String'];
}>;


export type GetLinksQuery = { residence: { data: { link: { links: Array<LinkFragment> } } } };

export type MenuForDateQueryVariables = Exact<{
  residenceId: Scalars['String'];
  date: Scalars['Date'];
}>;


export type MenuForDateQuery = { residence: { data: { menu: { menu?: MenuFragment | null } } } };

export type MenusQueryVariables = Exact<{
  residenceId: Scalars['String'];
  date: Scalars['Date'];
  numberOfDaysBefore: Scalars['Int'];
  numberOfDaysAfter: Scalars['Int'];
}>;


export type MenusQuery = { residence: { data: { menu: { menus: Array<MenuFragment> } } } };

export type GetPlacesQueryVariables = Exact<{
  residenceId: Scalars['String'];
}>;


export type GetPlacesQuery = { residence: { data: { place: { places: Array<PlaceFragment> } } } };

export type GetPlaceCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlaceCategoriesQuery = { category: { place: { categories: Array<PlaceCategoryFragment> } } };

export type GetResidenceQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetResidenceQuery = { residence: { residence: ResidenceFragment } };

export type AvailableResidencesQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableResidencesQuery = { residence: { availableResidences: Array<ResidenceFragment> } };

export type VersionQueryVariables = Exact<{ [key: string]: never; }>;


export type VersionQuery = { version: string };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { user: { me?: UserFragment | null } };

export type ResidencesQueryVariables = Exact<{ [key: string]: never; }>;


export type ResidencesQuery = { residence: { availableResidences: Array<ResidenceFragment> } };

export type SetPasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type SetPasswordMutation = { user: { setPassword: boolean } };

export type SetNotificationTokenMutationVariables = Exact<{
  deviceId: Scalars['String'];
  notificationToken: Scalars['String'];
}>;


export type SetNotificationTokenMutation = { user: { setNotificationToken: boolean } };

export type DeleteNotificationTokenMutationVariables = Exact<{
  deviceId: Scalars['String'];
}>;


export type DeleteNotificationTokenMutation = { user: { deleteNotificationToken: boolean } };

export type GetProductsQueryVariables = Exact<{
  residenceId: Scalars['String'];
}>;


export type GetProductsQuery = { residence: { data: { shopping: { products: Array<ProductFragment> } } } };

export type GetSurveysQueryVariables = Exact<{
  residenceId: Scalars['String'];
}>;


export type GetSurveysQuery = { residence: { data: { survey: { surveys: Array<SurveyFragment> } } } };

export type GetSurveyWithIdQueryVariables = Exact<{
  residenceId: Scalars['String'];
  surveyId: Scalars['UUID'];
}>;


export type GetSurveyWithIdQuery = { residence: { data: { survey: { survey: SurveyFragment } } } };

export type SubmitSurveyMutationVariables = Exact<{
  residenceId: Scalars['UUID'];
  surveyId: Scalars['UUID'];
  answers: InputSubmitSurvey;
}>;


export type SubmitSurveyMutation = { residence: { data: { survey: { submit: { id: any } } } } };

export type TeamForDayQueryVariables = Exact<{
  residenceId: Scalars['String'];
  date: Scalars['Date'];
}>;


export type TeamForDayQuery = { residence: { data: { team: { team: TeamFragment } } } };

export type GetTeamsQueryVariables = Exact<{
  residenceId: Scalars['String'];
  dateStart: Scalars['Date'];
  numberOfDays: Scalars['Int'];
}>;


export type GetTeamsQuery = { residence: { data: { team: { teams: Array<TeamFragment> } } } };

export type GetWidgetsQueryVariables = Exact<{
  residenceId: Scalars['String'];
  dateStart: Scalars['Date'];
  numberOfDays: Scalars['Int'];
}>;


export type GetWidgetsQuery = { residence: { data: { widget: { widgets: Array<WidgetFragment> } } } };

export type GetServicesQueryVariables = Exact<{
  residenceId: Scalars['String'];
}>;


export type GetServicesQuery = { residence: { data: { service: { services: Array<ServiceFragment> } } } };

export const TeamMember = gql`
    fragment TeamMember on TeamMember {
  id
  firstName
  lastName
  job
  photoId
}
    `;
export const Team = gql`
    fragment Team on Team {
  date
  teamMembers {
    ...TeamMember
  }
}
    ${TeamMember}`;
export const MealContent = gql`
    fragment MealContent on MealContent {
  id
  label
}
    `;
export const MealContentData = gql`
    fragment MealContentData on MealContentData {
  type
  content {
    ...MealContent
  }
}
    ${MealContent}`;
export const Meal = gql`
    fragment Meal on Meal {
  id
  type
  specialLabel
  content {
    ...MealContentData
  }
}
    ${MealContentData}`;
export const Menu = gql`
    fragment Menu on Menu {
  id
  date
  meals {
    ...Meal
  }
}
    ${Meal}`;
export const Icon = gql`
    fragment Icon on Icon {
  id
  name
  mediaId
}
    `;
export const Activity = gql`
    fragment Activity on Activity {
  id
  name
  startDateTime
  endDateTime
  description
  location
  photoId
  icon {
    ...Icon
  }
}
    ${Icon}`;
export const CalendarDay = gql`
    fragment CalendarDay on CalendarDay {
  date
  team {
    ...Team
  }
  menu {
    ...Menu
  }
  activities {
    ...Activity
  }
}
    ${Team}
${Menu}
${Activity}`;
export const ThreadAuthor = gql`
    fragment ThreadAuthor on ThreadAuthor {
  id
  threadId
  userId
  firstName
  lastName
}
    `;
export const ThreadMessage = gql`
    fragment ThreadMessage on ThreadMessage {
  id
  authorId
  threadId
  body
  createdAt
}
    `;
export const Thread = gql`
    fragment Thread on Thread {
  id
  userId
  residenceId
  lastMessageBody
  confirmMessage
  authors {
    ...ThreadAuthor
  }
  messages {
    ...ThreadMessage
  }
}
    ${ThreadAuthor}
${ThreadMessage}`;
export const DataEvent = gql`
    fragment DataEvent on DataEvent {
  id
  userId
  type
  objectId
  status
  lastReadDate
  lastUpdatedDate
  createdAt
  updatedAt
}
    `;
export const Faq = gql`
    fragment Faq on FAQEntry {
  id
  question
  answer
}
    `;
export const Feature = gql`
    fragment Feature on Feature {
  type
  status
}
    `;
export const GalleryPhoto = gql`
    fragment GalleryPhoto on GalleryPhoto {
  id
  createdAt
  mediaId
  media {
    type
  }
}
    `;
export const GalleryAlbum = gql`
    fragment GalleryAlbum on GalleryAlbum {
  id
  date
  title
  lastUpdateDateTime
  thumbnailId
  photos {
    ...GalleryPhoto
  }
}
    ${GalleryPhoto}`;
export const LinkCategory = gql`
    fragment LinkCategory on LinkCategory {
  id
  name
}
    `;
export const Link = gql`
    fragment Link on Link {
  id
  title
  url
  category {
    ...LinkCategory
  }
}
    ${LinkCategory}`;
export const MediaItem = gql`
    fragment MediaItem on MediaItem {
  id
  type
}
    `;
export const PlaceCategory = gql`
    fragment PlaceCategory on PlaceCategory {
  id
  name
  color
}
    `;
export const Place = gql`
    fragment Place on Place {
  id
  latitude
  longitude
  address
  title
  subTitle
  description
  category {
    ...PlaceCategory
  }
}
    ${PlaceCategory}`;
export const ResidentStay = gql`
    fragment ResidentStay on ResidentStay {
  id
  departureDate
  arrivalDate
  roomNumber
}
    `;
export const ResidentProfile = gql`
    fragment ResidentProfile on ResidentProfile {
  userId
  birthdate
  activeStay {
    ...ResidentStay
  }
}
    ${ResidentStay}`;
export const Residence = gql`
    fragment Residence on Residence {
  id
  name
  address
  email
  phone
  schedule
  reference
  mapId
  trombiId
}
    `;
export const ResidencePermission = gql`
    fragment ResidencePermission on ResidencePermission {
  role
  clientResources
  residence {
    ...Residence
  }
}
    ${Residence}`;
export const User = gql`
    fragment User on User {
  id
  email
  firstName
  lastName
  residentProfile {
    ...ResidentProfile
  }
  residencePermissions {
    ...ResidencePermission
  }
}
    ${ResidentProfile}
${ResidencePermission}`;
export const Service = gql`
    fragment Service on Service {
  id
  name
  description
  fileId
  icon {
    ...Icon
  }
}
    ${Icon}`;
export const Product = gql`
    fragment Product on Product {
  id
  title
  description
  url
  photoId
  updatedAt
  createdAt
}
    `;
export const SurveyQuestion = gql`
    fragment SurveyQuestion on SurveyQuestion {
  id
  surveyId
  order
  type
  content
  answers
  createdAt
  updatedAt
}
    `;
export const SurveySubmission = gql`
    fragment SurveySubmission on SurveySubmission {
  id
  userId
  prize
  surveyId
  isPrizeUsed
}
    `;
export const Survey = gql`
    fragment Survey on Survey {
  id
  published
  title
  prize
  submissionsCount
  questions {
    ...SurveyQuestion
  }
  submission {
    ...SurveySubmission
  }
  createdAt
  updatedAt
}
    ${SurveyQuestion}
${SurveySubmission}`;
export const Widget = gql`
    fragment Widget on Widget {
  id
  title
  content
  type
  published
  startDateTime
  endDateTime
  activityId
  menuDate
}
    `;
export const ActivitiesForDay = gql`
    query ActivitiesForDay($residenceId: String!, $date: Date!) {
  residence {
    data(residenceId: $residenceId) {
      activity {
        activitiesForDay(date: $date) {
          ...Activity
        }
      }
    }
  }
}
    ${Activity}`;
export const Activities = gql`
    query Activities($residenceId: String!, $dateStart: Date!, $numberOfDays: Int!) {
  residence {
    data(residenceId: $residenceId) {
      activity {
        activities(dateStart: $dateStart, numberOfDays: $numberOfDays) {
          ...Activity
        }
      }
    }
  }
}
    ${Activity}`;
export const GetActivity = gql`
    query GetActivity($residenceId: String!, $activityId: UUID!) {
  residence {
    data(residenceId: $residenceId) {
      activity {
        activity(activityId: $activityId) {
          ...Activity
        }
      }
    }
  }
}
    ${Activity}`;
export const CalendarForDay = gql`
    query CalendarForDay($residenceId: String!, $date: Date!) {
  residence {
    data(residenceId: $residenceId) {
      calendar {
        calendar(date: $date) {
          ...CalendarDay
        }
      }
    }
  }
}
    ${CalendarDay}`;
export const Calendars = gql`
    query Calendars($residenceId: String!, $dateStart: Date!, $numberOfDays: Int!) {
  residence {
    data(residenceId: $residenceId) {
      calendar {
        calendars(dateStart: $dateStart, numberOfDays: $numberOfDays) {
          ...CalendarDay
        }
      }
    }
  }
}
    ${CalendarDay}`;
export const GetThread = gql`
    query getThread($residenceId: UUID!) {
  contact {
    thread(residenceId: $residenceId) {
      ...Thread
    }
  }
}
    ${Thread}`;
export const SendMessage = gql`
    mutation SendMessage($residenceId: UUID!, $message: String!) {
  contact {
    sendMessage(residenceId: $residenceId, message: $message) {
      ...ThreadMessage
    }
  }
}
    ${ThreadMessage}`;
export const GetDataEvents = gql`
    query GetDataEvents($residenceId: UUID!) {
  dataEvents(residenceId: $residenceId) {
    ...DataEvent
  }
}
    ${DataEvent}`;
export const ReadDataEvent = gql`
    mutation ReadDataEvent($type: DataEventType!, $residenceId: UUID!) {
  readDataEvent(type: $type, residenceId: $residenceId)
}
    `;
export const GetFaq = gql`
    query GetFaq($residenceId: String!) {
  residence {
    data(residenceId: $residenceId) {
      faq {
        faq {
          ...Faq
        }
      }
    }
  }
}
    ${Faq}`;
export const GetFeatures = gql`
    query GetFeatures($residenceId: String!) {
  residence {
    data(residenceId: $residenceId) {
      features {
        ...Feature
      }
    }
  }
}
    ${Feature}`;
export const GetAlbums = gql`
    query GetAlbums($residenceId: String!) {
  residence {
    data(residenceId: $residenceId) {
      gallery {
        albums {
          ...GalleryAlbum
        }
      }
    }
  }
}
    ${GalleryAlbum}`;
export const GetAlbum = gql`
    query GetAlbum($residenceId: String!, $albumId: String!) {
  residence {
    data(residenceId: $residenceId) {
      gallery {
        album(albumId: $albumId) {
          ...GalleryAlbum
        }
      }
    }
  }
}
    ${GalleryAlbum}`;
export const GetPhoto = gql`
    query GetPhoto($residenceId: String!, $photoId: String!) {
  residence {
    data(residenceId: $residenceId) {
      gallery {
        photo(photoId: $photoId) {
          ...GalleryPhoto
        }
      }
    }
  }
}
    ${GalleryPhoto}`;
export const GetLinks = gql`
    query GetLinks($residenceId: String!) {
  residence {
    data(residenceId: $residenceId) {
      link {
        links {
          ...Link
        }
      }
    }
  }
}
    ${Link}`;
export const MenuForDate = gql`
    query MenuForDate($residenceId: String!, $date: Date!) {
  residence {
    data(residenceId: $residenceId) {
      menu {
        menu(date: $date) {
          ...Menu
        }
      }
    }
  }
}
    ${Menu}`;
export const Menus = gql`
    query Menus($residenceId: String!, $date: Date!, $numberOfDaysBefore: Int!, $numberOfDaysAfter: Int!) {
  residence {
    data(residenceId: $residenceId) {
      menu {
        menus(
          date: $date
          numberOfDaysBefore: $numberOfDaysBefore
          numberOfDaysAfter: $numberOfDaysAfter
        ) {
          ...Menu
        }
      }
    }
  }
}
    ${Menu}`;
export const GetPlaces = gql`
    query GetPlaces($residenceId: String!) {
  residence {
    data(residenceId: $residenceId) {
      place {
        places {
          ...Place
        }
      }
    }
  }
}
    ${Place}`;
export const GetPlaceCategories = gql`
    query GetPlaceCategories {
  category {
    place {
      categories {
        ...PlaceCategory
      }
    }
  }
}
    ${PlaceCategory}`;
export const GetResidence = gql`
    query GetResidence($id: String!) {
  residence {
    residence(id: $id) {
      ...Residence
    }
  }
}
    ${Residence}`;
export const AvailableResidences = gql`
    query AvailableResidences {
  residence {
    availableResidences {
      ...Residence
    }
  }
}
    ${Residence}`;
export const Version = gql`
    query Version {
  version
}
    `;
export const Me = gql`
    query Me {
  user {
    me {
      ...User
    }
  }
}
    ${User}`;
export const Residences = gql`
    query Residences {
  residence {
    availableResidences {
      ...Residence
    }
  }
}
    ${Residence}`;
export const SetPassword = gql`
    mutation SetPassword($oldPassword: String!, $newPassword: String!) {
  user {
    setPassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
}
    `;
export const SetNotificationToken = gql`
    mutation SetNotificationToken($deviceId: String!, $notificationToken: String!) {
  user {
    setNotificationToken(deviceId: $deviceId, notificationToken: $notificationToken)
  }
}
    `;
export const DeleteNotificationToken = gql`
    mutation DeleteNotificationToken($deviceId: String!) {
  user {
    deleteNotificationToken(deviceId: $deviceId)
  }
}
    `;
export const GetProducts = gql`
    query GetProducts($residenceId: String!) {
  residence {
    data(residenceId: $residenceId) {
      shopping {
        products {
          ...Product
        }
      }
    }
  }
}
    ${Product}`;
export const GetSurveys = gql`
    query GetSurveys($residenceId: String!) {
  residence {
    data(residenceId: $residenceId) {
      survey {
        surveys {
          ...Survey
        }
      }
    }
  }
}
    ${Survey}`;
export const GetSurveyWithId = gql`
    query GetSurveyWithId($residenceId: String!, $surveyId: UUID!) {
  residence {
    data(residenceId: $residenceId) {
      survey {
        survey(surveyId: $surveyId) {
          ...Survey
        }
      }
    }
  }
}
    ${Survey}`;
export const SubmitSurvey = gql`
    mutation SubmitSurvey($residenceId: UUID!, $surveyId: UUID!, $answers: InputSubmitSurvey!) {
  residence {
    data(residenceId: $residenceId) {
      survey {
        submit(surveyId: $surveyId, answers: $answers) {
          id
        }
      }
    }
  }
}
    `;
export const TeamForDay = gql`
    query TeamForDay($residenceId: String!, $date: Date!) {
  residence {
    data(residenceId: $residenceId) {
      team {
        team(date: $date) {
          ...Team
        }
      }
    }
  }
}
    ${Team}`;
export const GetTeams = gql`
    query GetTeams($residenceId: String!, $dateStart: Date!, $numberOfDays: Int!) {
  residence {
    data(residenceId: $residenceId) {
      team {
        teams(dateStart: $dateStart, numberOfDays: $numberOfDays) {
          ...Team
        }
      }
    }
  }
}
    ${Team}`;
export const GetWidgets = gql`
    query GetWidgets($residenceId: String!, $dateStart: Date!, $numberOfDays: Int!) {
  residence {
    data(residenceId: $residenceId) {
      widget {
        widgets(dateStart: $dateStart, numberOfDays: $numberOfDays) {
          ...Widget
        }
      }
    }
  }
}
    ${Widget}`;
export const GetServices = gql`
    query GetServices($residenceId: String!) {
  residence {
    data(residenceId: $residenceId) {
      service {
        services {
          ...Service
        }
      }
    }
  }
}
    ${Service}`;