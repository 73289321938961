<template>
  <button :disabled="disabled" @click="click()">
    <div v-if="!disabled" class="d-flex justify-content-center align-self-center">
      <img
        v-if="arrowLeft"
        class="arrow"
        src="@/assets/images/arrow-left.png"
        srcset="@/assets/images/arrow-left@2x.png 2x, @/assets/images/arrow-left@3x.png 3x"
      />
      <h5 v-if="text" class="flex-grow-1" :class="{ title: small, left: arrowLeft, right: arrowRight }">{{ text }}</h5>
      <img
        v-if="arrowRight"
        class="arrow"
        src="@/assets/images/arrow-right.png"
        srcset="@/assets/images/arrow-right@2x.png 2x, @/assets/images/arrow-right@3x.png 3x"
      />
    </div>
    <div v-else class="loader"> </div>
  </button>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class ButtonArrow extends Vue {
  @Prop({ type: String, required: false, default: null }) readonly text!: string | null
  @Prop({ type: Boolean, required: false, default: false }) readonly arrowLeft!: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly arrowRight!: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly disabled!: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly small!: boolean
  @Prop({ type: Function, required: false, default: () => {} }) readonly click!: () => void
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.jda-btn {
  padding-left: 16px;
  padding-right: 16px;

  .arrow {
    object-fit: contain;
  }

  .title {
    &.left {
      padding-left: 10px;
    }

    &.right {
      padding-right: 10px;
    }

    @media (max-width: $sm) {
      display: none;
    }
  }
}
</style>
