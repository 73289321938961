import { VuexModule, Module, Action, Mutation } from "vuex-class-modules"
import store from ".."
import { parseJSON, removeItemsFromLocalStorage, setToStorage } from "../../utils/storage"
import { isOffline } from "../../helpers/ConnectionHelper"
import APIClient from "../../api/ApiClient"
import { Status } from "../../types/Status"
import { loaderModule } from "./loader"
import { FeatureFragment } from "../../queries/client/graphql"

const FEATURES_KEY = "features"

@Module
class FeatureModule extends VuexModule {
  features: FeatureFragment[] | null = null
  status: Status = Status.New

  @Mutation
  setData({ features, residenceId }: { features: FeatureFragment[] | null; residenceId: string }) {
    this.features = features

    setToStorage(FEATURES_KEY, residenceId, this.features)
  }

  @Mutation
  clearData() {
    removeItemsFromLocalStorage(FEATURES_KEY)
    this.features = null
    this.status = Status.New
  }

  @Mutation
  setStatus(status: Status) {
    this.status = status
  }

  @Action
  async loadFeatures(residenceId: string) {
    if (this.status === Status.Loading || this.status === Status.Refreshing) {
      return
    }

    if (this.status === Status.New) {
      this.setStatus(Status.Loading)

      this.loadFromStorage(residenceId)
    } else {
      this.setStatus(Status.Refreshing)
    }

    loaderModule.willRefresh()

    try {
      await this.loadFromAPI(residenceId)

      this.setStatus(Status.Ready)
    } catch (error) {
      console.log(error)

      if (!this.features) {
        this.setStatus(Status.Error)
      } else {
        this.setStatus(Status.Ready)
      }
    }

    loaderModule.didRefresh()
  }

  @Action
  loadFromStorage(residenceId: string) {
    if (residenceId) {
      const features = parseJSON<FeatureFragment[]>(localStorage.getItem(`${FEATURES_KEY}${residenceId}`))
      this.setData({ features, residenceId })
    }
  }

  @Action
  async loadFromAPI(residenceId: string) {
    if (!residenceId || (isOffline() && this.features == null)) {
      this.setStatus(Status.Error)
      return
    }

    const features = await APIClient.getFeatures(residenceId)

    this.setData({ features, residenceId })
  }
}

export const featureModule = new FeatureModule({ store, name: "feature" })
