<template>
  <div class="d-flex justify-content-center align-items-center header">
    <button v-if="leftButtonVisible" @click="changeWeek(-7)">
      <img src="@/assets/images/next-back.png" srcset="@/assets/images/next-back@2x.png 2x, @/assets/images/next-back@3x.png 3x" />
    </button>
    <h1 class="title"> {{ title }} du {{ startDateFormat }} au {{ endDate | dateMonthFormat }} </h1>
    <button v-if="rightButtonVisible" @click="changeWeek(7)">
      <img src="@/assets/images/next.png" srcset="@/assets/images/next@2x.png 2x, @/assets/images/next@3x.png 3x" />
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, VModel } from "vue-property-decorator"

@Component
export default class WeekDateTitle extends Vue {
  @Prop({ type: String, required: false, default: "" }) readonly title!: string
  @Prop({ type: Boolean, default: true }) readonly leftButtonVisible!: boolean
  @Prop({ type: Boolean, default: true }) readonly rightButtonVisible!: boolean

  @VModel({ type: Date, required: true }) startDate!: Date

  get endDate() {
    const end = new Date(this.startDate)

    end.setDate(end.getDate() + 6)

    return end
  }

  get startDateFormat() {
    const filter = this.startDate.getMonth() === this.endDate.getMonth() ? "dayOfMonthFormat" : "dateMonthFormat"

    return this.$options.filters?.[filter] ? this.$options.filters[filter](this.startDate) : ""
  }

  changeWeek(increment: number) {
    this.startDate = new Date(this.startDate.setDate(this.startDate.getDate() + increment))
  }
}
</script>

<style scoped lang="scss">
.header {
  padding: 32px 20px 20px 20px;

  .title {
    margin: 0 40px;
    font-family: "Lobster", sans-serif;
    font-weight: normal;
  }
}
</style>
