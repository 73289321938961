export enum AppTrackEvent {
  appInstalled = "App Installed",
  appOpened = "App Opened",

  // AUTHENTICATION
  authenticationOpened = "Authentication Opened",
  signedUp = "Signed Up",
  signedIn = "Signed In",
  accountInitialized = "Account Initialized",
  loggedOut = "Logged out",
  passwordLostStarted = "Password Lost Started",
  passwordLostCompleted = "Password Lost Completed",

  // HOME
  homeOpened = "Home Opened",
  menuClicked = "Menu Clicked",

  // CALENDAR
  calendarOpened = "Calendar Opened",
  calendarSeeActivitiesClicked = "Calendar See Activities Clicked",

  // ACTIVITIES
  activitiesOpened = "Activities Opened",
  activitiesItemClicked = "Activities Item Clicked",

  // RESTAURANT
  restaurantOpened = "Restaurant Opened",

  // GALLERY
  galleryOpened = "Gallery Opened",
  galleryAlbumOpened = "Gallery Album Opened",
  galleryPhotoOpened = "Gallery Photo Opened",

  // SERVICES
  servicesOpened = "Services Opened",
  servicesItemClicked = "Services Item Clicked",

  // MY RESIDENCE
  myResidenceFAQOpened = "My Residence FAQ Opened",
  myResidenceTrombiOpened = "My Residence Trombi Opened",
  myResidencePlanOpened = "My Residence Plan Opened",
  myResidenceHoursOpened = "My Residence Hours Opened",

  // PLACES
  placesOpened = "Places Opened",
  placesItemClicked = "Places Item Clicked",

  // SHOPPING
  shoppingOpened = "Shopping Opened",
  shoppingItemClicked = "Shopping Item Clicked",

  // LINKS
  linksOpened = "Links Opened",
  linksItemClicked = "Links Item Clicked",

  // SURVEY
  surveyOpened = "Survey Opened",
  surveyStarted = "Survey Started",
  surveySubmitted = "Survey Submitted",

  // PROFILE
  profileOpened = "Profile Opened",
  profileChangedPasswordClicked = "Profile Changed Password Clicked",

  // CONTACT
  contactOpened = "Contact Opened",
  contactMessageSubmitted = "Contact Message Submitted",
}

export type Params = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export interface Tracker {
  init(): void
  track(event: AppTrackEvent, params: Params | null): void
}
