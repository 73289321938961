<template>
  <a class="widget-link" :href="link.url" target="_blank" @click="openUrl">
    <Widget>
      <div class="link-container d-flex justify-content-between align-items-center">
        <h5 class="title">{{ link.title }}</h5>
        <img src="@/assets/images/link.png" srcset="@/assets/images/link@2x.png 2x, @/assets/images/link@3x.png 3x" />
      </div>
    </Widget>
  </a>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import { LinkFragment } from "@/queries/client/graphql"

@Component({
  components: {
    Widget: async () => await import("@/components/widgets/widget.vue"),
  },
})
export default class WidgetLink extends Vue {
  @Prop({ type: Object, required: true }) readonly link!: LinkFragment

  openUrl() {
    TrackingHelper.track(AppTrackEvent.linksItemClicked, { linkId: this.link.id })
  }
}
</script>

<style scoped lang="scss">
.widget-link {
  text-decoration: none;
  cursor: pointer;

  .link-container {
    height: 70px;
    padding: 14px 16px;

    .title {
      display: block;
      display: -webkit-box;
      padding-right: 10px;
      text-align: left;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .btn-link {
      width: 40px;
      height: 40px;
      padding: 0;
    }
  }
}
</style>
