<template>
  <Widget class="generate-token-form">
    <h3 class="title">Vous avez oublié votre mot de passe ?</h3>
    <h4 class="sub-title"> Pour réinitialiser votre mot de passe, veuillez saisir votre adresse mail. </h4>
    <FormTextField
      v-model="form"
      class="fields"
      :fields="fields"
      :status="status"
      :error="error"
      button-label="Réinitialiser"
      @submit="handleSubmit"
    />
  </Widget>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import APIAuth from "@/api/ApiAuth"
import TextFieldItem from "@/components/form-text-field/types/TextFieldItem"

interface ForgetPasswordForm {
  email: string | null
}

@Component({
  components: {
    Widget: async () => await import("@/components/widgets/widget.vue"),
    FormTextField: async () => await import("@/components/form-text-field/form-text-field.vue"),
  },
})
export default class GenerateTokenForm extends Vue {
  form: ForgetPasswordForm = { email: null }
  fields: TextFieldItem[] = [{ name: "email", label: "Email :", type: "email" }]
  status = "enabled"
  error: string | null = null

  async handleSubmit() {
    this.error = null

    if (!this.form.email) {
      this.error = "Veuillez fournir un email"
      return
    }

    this.status = "loading"

    try {
      const result = await APIAuth.generateLostPasswordToken(this.form.email)
      if (!result) {
        this.error = "Compte inexistant"
      } else {
        this.$emit("success", this.form.email)
      }
      this.status = "enabled"
    } catch (error) {
      this.status = "enabled"
      this.error = "Une erreur est survenue."
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.generate-token-form {
  .title {
    margin-bottom: 24px;
  }

  .sub-title {
    color: $text-body;
    font-weight: normal;
  }

  .fields {
    padding-top: 48px;
  }
}
</style>
