import { Job, TeamFragment } from "../queries/client/graphql"

export function sortTeamByJob<T extends { job: Job }>(teamMembers: T[]): T[] {
  return teamMembers.sort((a, b) => {
    if (a.job > b.job) {
      return 1
    }
    if (b.job > a.job) {
      return -1
    }
    return 0
  })
}

export function teamFragmentToLocal(teamFragment: TeamFragment): TeamFragment {
  return {
    date: teamFragment.date,
    teamMembers: sortTeamByJob(teamFragment.teamMembers),
  }
}

export function getJob(job: Job): string {
  switch (job) {
    case Job.Coordinator:
      return "La journée"
    case Job.NightWalker:
      return "Le soir"
    default:
      return ""
  }
}
