<template>
  <div class="change-password">
    <NavigationBar :left-buttons="[{ text: 'Retour', back: true }]" title="Profil" />
    <div class="app-view d-flex align-items-center justify-content-center">
      <Widget class="content">
        <h3 class="title">Changer de mot de passe</h3>
        <FormTextField
          v-model="form"
          class="form-group"
          :fields="fields"
          button-label="Valider"
          :error="error"
          :status="status"
          @submit="handleSubmit"
        />
      </Widget>
    </div>
    <b-modal id="popup-success" size="lg" hide-header hide-footer centered no-close-on-backdrop>
      <PopupAlert title="Mot de passe changé avec succès !" :buttons="['Revenir au profil']" :single-button="goBack" />
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import APIClient from "@/api/ApiClient"
import TextFieldItem from "@/components/form-text-field/types/TextFieldItem"

interface ResetPasswordForm {
  oldPassword: string | null
  password: string | null
  passwordConfirm: string | null
}

@Component({
  components: {
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
    Widget: async () => await import("@/components/widgets/widget.vue"),
    PopupAlert: async () => await import("@/components/popups/popup-alert.vue"),
    FormTextField: async () => await import("@/components/form-text-field/form-text-field.vue"),
  },
})
export default class ResetPassword extends Vue {
  form: ResetPasswordForm = {
    oldPassword: null,
    password: null,
    passwordConfirm: null,
  }
  fields: TextFieldItem[] = [
    { name: "oldPassword", label: "Ancien mot de passe :", type: "password" },
    { name: "password", label: "Nouveau mot de passe :", type: "password" },
    { name: "passwordConfirm", label: "Confirmation du nouveau mot de passe :", type: "password" },
  ]
  status = "enabled"
  error: string | null = null

  async handleSubmit() {
    if (!this.form.oldPassword?.length) {
      this.error = "Ancien mot de passe vide."
      return
    }

    if (!this.form.password?.length) {
      this.error = "Le mot de passe est obligatoire."
      return
    }

    if (this.form.password !== this.form.passwordConfirm) {
      this.error = "La confirmation du mot de passe n'est pas valide."
      return
    }

    try {
      this.status = "loading"

      const value = await APIClient.setPassword(this.form.oldPassword, this.form.password)
      this.status = "enabled"
      if (!value) {
        this.error = "Ancien mot de pass invalide."
      } else {
        this.$bvModal.show("popup-success")
      }
    } catch (error) {
      this.status = "enabled"
      this.error = "Une erreur est survenue."
    }
  }

  goBack() {
    this.$router.go(-1)
  }
}
</script>

<style scoped lang="scss">
.change-password {
  height: 100%;
  display: flex;
  flex-flow: column;

  .app-view {
    padding: 20px;

    .content {
      max-width: 550px;
      padding: 30px 32px;

      h3 {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
