import { VuexModule, Module, Action, Mutation } from "vuex-class-modules"
import store from ".."
import { sessionModule } from "./session"
import { isOffline } from "../../helpers/ConnectionHelper"
import { activityModule } from "./activity"
import { teamModule } from "./team"
import { serviceModule } from "./service"
import { linkModule } from "./link"
import { menuModule } from "./menu"
import { widgetModule } from "./widget"
import { featureModule } from "./feature"

export enum SyncStatus {
  IDLE,
  SYNCING,
}

const LAST_SYNC_DATE = "lastSyncDate"
const INTERVAL = 600000 // 10 minutes
const ONE_DAY = 86400000 // 24h

@Module
class SyncModule extends VuexModule {
  interval: number | null = null
  status = SyncStatus.IDLE

  get lastSyncDate() {
    return (residenceId: string) => {
      const date = localStorage.getItem(`${LAST_SYNC_DATE}${residenceId}`)
      return date ? new Date(date) : null
    }
  }

  @Mutation
  setLastSyncDate({ date, residenceId }: { date: Date; residenceId: string }) {
    localStorage.setItem(`${LAST_SYNC_DATE}${residenceId}`, date.toISOString())
  }

  @Mutation
  setInterval(interval: number) {
    this.interval = interval
  }

  @Mutation
  setStatus(status: SyncStatus) {
    this.status = status
  }

  @Action
  async startService() {
    if (this.interval) {
      window.clearInterval(this.interval)
    }

    const interval = window.setInterval(async () => {
      await this.synchronizeIfNeeded()
    }, INTERVAL)
    await this.synchronizeIfNeeded()

    this.setInterval(interval)
  }

  @Action
  async synchronizeIfNeeded(force = false) {
    const currentResidenceId = sessionModule.currentResidence?.id
    if (this.status !== SyncStatus.IDLE || isOffline() || !sessionModule.isAuthenticated || currentResidenceId == null) {
      return
    }

    const lastSyncDate = this.lastSyncDate(currentResidenceId)
    if (!force && lastSyncDate && new Date().getTime() - lastSyncDate.getTime() < ONE_DAY) {
      return
    }

    this.setStatus(SyncStatus.SYNCING)

    // Load Data
    try {
      await activityModule.syncActivities(currentResidenceId)
      await teamModule.syncTeams(currentResidenceId)
      await serviceModule.loadServices(currentResidenceId)
      await linkModule.loadLinks(currentResidenceId)
      await menuModule.syncMenus(currentResidenceId)
      await widgetModule.syncWidgets(currentResidenceId)
      await featureModule.loadFeatures(currentResidenceId)

      this.setLastSyncDate({ date: new Date(), residenceId: currentResidenceId })
    } catch (error) {
      // TODO
      console.log(error)
    }

    this.setStatus(SyncStatus.IDLE)
  }
}

export const syncModule = new SyncModule({ store, name: "sync" })
