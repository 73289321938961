<template>
  <div class="form-group">
    <label :for="name">{{ label }}</label>
    <input
      :id="name"
      v-model="input"
      class="form-control"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, VModel } from "vue-property-decorator"

@Component
export default class TextField extends Vue {
  @Prop({ type: String, required: true }) readonly label!: string
  @Prop({ type: String, required: true }) readonly name!: string
  @Prop({ type: String, default: "" }) readonly placeholder!: string
  @Prop({ type: String, default: "text" }) readonly type!: string
  @Prop({ type: Boolean, default: false }) readonly readonly!: boolean
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean

  @VModel({ type: String }) input!: string
}
</script>
