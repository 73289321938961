<template>
  <screen-base title="Anecdote" sub-title="ÇA S'EST PASSÉ UN..." class="screen-anecdote">
    <h2 class="date">{{ data.date }}</h2>
    <p class="title">{{ data.title }}</p>
    <screen-card>
      <p class="content" :style="contentStyle">{{ data.content }}</p>
    </screen-card>
  </screen-base>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator"
import { AnecdoteFragment } from "../../queries/screen/graphql"

@Component({
  components: {
    "screen-base": () => import("./screen-base.vue"),
    "screen-card": () => import("./screen-card.vue"),
  },
})
export default class ScreenAnecdote extends Vue {
  @Prop({ type: Object, required: true }) readonly data!: AnecdoteFragment

  get contentStyle() {
    let fontSize = 225

    if (this.data.content.length > 1000) {
      fontSize = 145
    } else if (this.data.content.length > 650) {
      fontSize = 185
    }

    return {
      "font-size": `${fontSize}%`,
    }
  }
}
</script>

<style lang="scss">
@import "../../style/_variables.scss";

.screen-anecdote {
  .screen-content-wrapper {
    padding: 5%;
  }

  .date {
    font-size: 550%;
    font-family: "Lobster", sans-serif;
    font-weight: normal;
    text-align: left;
    text-transform: capitalize;
    margin: 2.79% 0;
  }

  .title {
    margin-top: 5%;
    font-size: 300%;
    font-weight: 600;
    color: $secondary;
  }

  .screen-card {
    padding: 2.81%;

    .content {
      margin: 0;
    }
  }
}
</style>
