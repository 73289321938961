<template>
  <div class="faq">
    <InternetError v-if="status === 'error'" :retry="loadFaq" />
    <div v-else-if="status !== 'ready'" class="loader"></div>
    <div v-for="(faq, index) in faqs" v-else :key="index" class="widget-faq">
      <WidgetDropdown :id="faq.id" :title="faq.question">
        <Nl2Br class="answer" :content="faq.answer" />
      </WidgetDropdown>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import APIClient from "@/api/ApiClient"
import { FaqFragment } from "@/queries/client/graphql"
import { sessionModule } from "@/store/modules/session"
import { Status } from "@/types/Status"

@Component({
  components: {
    WidgetDropdown: async () => await import("@/components/widgets/widget-dropdown.vue"),
    Nl2Br: async () => await import("@/components/nl2br.vue"),
    InternetError: async () => await import("@/components/internet-error.vue"),
  },
})
export default class FAQ extends Vue {
  faqs: Array<FaqFragment> = []
  status = Status.New

  get residenceId() {
    return sessionModule.currentResidence?.id ?? null
  }

  async beforeMount() {
    TrackingHelper.track(AppTrackEvent.myResidenceFAQOpened)

    await this.loadFaq()
  }

  async loadFaq() {
    if (!this.residenceId) {
      return
    }

    this.status = Status.Loading

    try {
      this.faqs = await APIClient.getFaq(this.residenceId)
      this.status = Status.Ready
    } catch {
      this.status = Status.Error
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.faq {
  height: 100%;
  padding: 20px;

  .widget-faq {
    margin: 20px 20px 32px 20px;

    .answer {
      @include body2();
    }
  }
}
</style>
