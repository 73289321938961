import axios, { AxiosRequestConfig } from "axios"
import { API_URL } from "./url"
import { ProviderDelegate } from "./ProviderDelegate"

let delegate: ProviderDelegate | null = null

export function setDelegate(newDelegate: ProviderDelegate | null): void {
  delegate = newDelegate
}

if (API_URL == null) {
  throw new Error("API_URL is not defined in env.")
}

const instance = axios.create({
  baseURL: API_URL,
  timeout: 60000,
})

const updateHeader = <T extends AxiosRequestConfig>(config: T, accessToken: string | null): T => {
  if (accessToken) {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`
    } else {
      config.headers = { Authorization: `Bearer ${accessToken}` }
    }
  }

  return config
}

instance.interceptors.request.use(
  (config) => {
    if (!delegate) {
      return config
    }

    // Inject user token if exists
    const accessToken = delegate.delegateGetAccessToken()

    return updateHeader(config, accessToken)
  },
  (error) => {
    if (!delegate) {
      return Promise.reject(error)
    }

    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      if (delegate.delegateIsRefreshingSession()) {
        return new Promise((resolve, reject) => {
          if (!delegate) {
            reject()
            return
          }

          delegate.delegateGetRefreshSession$().subscribe(() => {
            if (!delegate) {
              reject()
              return
            }

            resolve(axios(updateHeader(originalRequest, delegate.delegateGetAccessToken())))
          })
        })
      }

      return delegate.delegateRefreshSession().then(() => {
        if (!delegate) {
          return Promise.reject()
        }

        return Promise.resolve(axios(updateHeader(originalRequest, delegate.delegateGetAccessToken())))
      })
    }

    return Promise.reject(error)
  }
)

export default instance
