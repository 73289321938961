<template>
  <div v-if="menuWithStatus" class="menu">
    <div v-if="menuWithStatus.status === 'ready'">
      <h1 class="date"> Menu du {{ menuWithStatus.date | date | dayDateMonthFormat }} </h1>
      <div v-if="menuWithStatus.menu && menuWithStatus.menu.meals.length > 0">
        <Widget v-for="meal in menuWithStatus.menu.meals" :key="meal.id" class="meal">
          <h3 class="meal-title">{{ getMealType(meal.type) }}</h3>
          <div class="border"></div>
          <div v-if="meal.specialLabel" class="special-meal">
            <h4 class="special-meal-title">MENU SPECIAL</h4>
            <h1 class="special-meal-label">{{ meal.specialLabel }}</h1>
          </div>
          <div v-for="(contentData, index) in meal.content" :key="`meal-content-${index}`" class="meal-content">
            <h4 v-for="content in contentData.content" :key="content.id" class="meal-content-title">{{ content.label | capitalize }}</h4>
          </div>
        </Widget>
      </div>
      <div v-else class="empty-meals d-flex align-items-center justify-content-center">
        <Nl2Br class="title" tag="h2" :content="isPast(menuWithStatus.date)" />
      </div>
      <div class="d-none complement">
        <router-link class="link" to="./"> VOIR LA CARTE DES COMPLEMENTS </router-link>
      </div>
    </div>
    <div v-else class="loader"></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { isPast } from "date-fns"
import { menuModule } from "@/store/modules/menu"
import { sessionModule } from "@/store/modules/session"
import { MealType } from "@/queries/client/graphql"
import { getMealType } from "@/types/Menu"

@Component({
  components: {
    Widget: async () => await import("@/components/widgets/widget.vue"),
    Nl2Br: async () => await import("@/components/nl2br.vue"),
  },
})
export default class Menu extends Vue {
  @Prop({ type: Date, required: true }) readonly date!: Date

  get currentResidenceId() {
    return sessionModule.currentResidence?.id
  }

  get menuWithStatus() {
    if (!this.currentResidenceId) {
      return null
    }
    return menuModule.menuWithStatus(this.currentResidenceId, this.date)
  }

  getMealType(type: MealType) {
    return getMealType(type)
  }

  isPast(date: string): string {
    return isPast(new Date(date)) ? "Indisponible" : "Prochainement\ndisponible…"
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.menu {
  width: 100%;
  height: 100%;
  padding: 30px;
  position: relative;

  .date {
    padding-bottom: 30px;
    @include title-secondary-style();
  }

  .empty-meals {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .title {
      @include title-secondary-style();
      font-size: 2rem;
      color: $disabled;
    }
  }

  .meal {
    width: 100%;
    margin-bottom: 17px;
    padding: 24px;

    .meal-title {
      text-align: center;
      margin-bottom: 5px;
    }

    .border {
      width: 100%;
      height: 2px;
      border: solid 1.5px #e8e8e8;
    }

    .special-meal {
      padding-top: 26px;
      padding-bottom: 8px;

      .special-meal-title {
        @include title-bold();
      }

      .special-meal-label {
        color: $primary;
        font-size: 1.875rem;
        @include title-secondary-style();
      }
    }

    .meal-content {
      width: 100%;
      padding: 15px;

      .meal-content-title {
        color: $text-body;
        font-weight: 400;
        text-align: center;
      }
    }
  }

  .complement {
    margin: 20px 0;
    text-align: center;

    .link {
      @include title-body();
      color: $primary;
    }
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
</style>
