<template>
  <div class="default-view">
    <NavigationBar :right-buttons="rightButtons" :left-buttons="leftButtons" :title="title" />
    <div class="app-view">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { ButtonType } from "@/components/navigation-bar/type"

@Component({
  components: {
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
  },
})
export default class DefaultView extends Vue {
  @Prop({ type: String, required: true }) readonly title!: string
  @Prop({ type: Array, required: false, default: () => [] }) readonly leftButtons!: Array<ButtonType>
  @Prop({ type: Array, required: false, default: () => [] }) readonly rightButtons!: Array<ButtonType>
}
</script>

<style scoped lang="scss">
.default-view {
  height: 100%;
  display: flex;
  flex-flow: column;
}
</style>
