<template>
  <div class="login home-view d-flex align-items-center justify-content-center">
    <Widget class="content">
      <div class="d-flex justify-content-center">
        <img
          class="logo"
          src="@/assets/images/logo.png"
          srcset="@/assets/images/logo@2x.png 2x, @/assets/images/logo@3x.png 3x"
          alt="Logo JDA"
        />
      </div>

      <FormTextField
        v-model="form"
        class="fields"
        :fields="fields"
        button-label="Se connecter"
        :error="error"
        :status="status"
        @submit="handleSubmit"
      />

      <div class="forget-password">
        Si vous avez oublié votre mot de passe, veuillez
        <a class="link" @click="forgetPassword">cliquer ici</a>
      </div>
    </Widget>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import { sessionModule } from "@/store/modules/session"
import TextFieldItem from "@/components/form-text-field/types/TextFieldItem"
import { syncModule } from "@/store/modules/sync"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"

interface LoginForm {
  email: string | null
  password: string | null
}

@Component({
  components: {
    FormTextField: async () => await import("@/components/form-text-field/form-text-field.vue"),
    Widget: () => import("@/components/widgets/widget.vue"),
  },
})
export default class Login extends Vue {
  form: LoginForm = {
    email: null,
    password: null,
  }
  fields: TextFieldItem[] = [
    { name: "email", label: "E-mail", type: "email" },
    { name: "password", label: "Mot de passe", type: "password" },
  ]
  status = "enabled"
  error: string | null = null

  mounted() {
    TrackingHelper.track(AppTrackEvent.authenticationOpened)
  }

  async handleSubmit() {
    if (!this.form.email) {
      this.error = "L'email est vide"
      return
    }

    if (!this.form.password) {
      this.error = "Le mot de passe est vide"
      return
    }

    this.error = null
    this.status = "loading"

    try {
      await sessionModule.login({ email: this.form.email, password: this.form.password })
      TrackingHelper.track(AppTrackEvent.signedIn)

      syncModule.startService()

      await this.$router.push("/")
      this.status = "enabled"
    } catch {
      this.error = "Email ou mot de passe invalide"
      this.status = "enabled"
    }
  }

  async forgetPassword() {
    TrackingHelper.track(AppTrackEvent.passwordLostStarted)
    await this.$router.push("/forgot-password")
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.login {
  height: 100%;

  .content {
    max-width: 500px;
    margin: 32px;
    padding: 0 32px 32px 32px;

    .logo {
      width: 230px;
      margin: 28px auto 35px auto;
      text-align: center;
      object-fit: contain;
    }

    .fields {
      padding-bottom: 16px;
    }

    .forget-password {
      @include body();
      text-align: center;
    }
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
