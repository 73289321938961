<template>
  <div class="screen-topbar">
    <span class="sub-title">{{ subTitle }}</span>
    <h1>{{ title }}</h1>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class ScreenTopbar extends Vue {
  @Prop({ type: String, required: true }) title!: string
  @Prop({ type: String, required: true }) subTitle!: string
}
</script>
<style scoped lang="scss">
@import "../../style/variables";

.screen-topbar {
  position: relative;
  width: 100%;
  height: 12.4%;
  background-color: $secondary;
  padding: 1.67% 3.33%;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.2);

  .sub-title {
    color: white;
    font-weight: bold;
    font-size: 225%;
    letter-spacing: 3px;
    line-height: 1.8;
  }

  h1 {
    position: absolute;
    right: 3.33%;
    display: inline-block;
    color: $highlight;
    font-size: 350%;
    font-family: "Lobster", sans-serif;
    font-weight: normal;
  }
}
</style>
