<template>
  <div class="screen-card">
    <h2 v-if="title" :class="{ center }">{{ title }}</h2>
    <p v-if="title && subtitle" class="subtitle" :class="{ center }">{{ subtitle }}</p>
    <hr v-if="title" />
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class ScreenCard extends Vue {
  @Prop({ type: String, required: false }) title: string | undefined
  @Prop({ type: String, required: false, default: null }) subtitle!: string | null
  @Prop({ type: Boolean, required: false, default: false }) readonly center!: boolean
}
</script>
<style lang="scss">
@import "../../style/variables";

.screen-card {
  position: relative;
  background-color: white;
  padding: 5.88%; // Optimized for 2 columns
  border-radius: 12px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.08);

  h2 {
    font-size: 300%;
    font-weight: 600;
    letter-spacing: -0.39px;
    text-align: left;
  }

  .subtitle {
    color: $secondary;
    font-size: 200%;
    font-family: "Lobster", sans-serif;
    margin-top: 1.2%;
    margin-bottom: 0;
    line-height: 1;
  }

  .center {
    text-align: center;
  }

  hr {
    margin-top: 2%;
    margin-bottom: 6%;
    border-top: 1px solid #b7b7b7;
  }
}

.intro.shown .screen-card {
  transition:
    opacity 400ms 300ms,
    transform 400ms 300ms;
}

.outro.hidden .screen-card {
  transition:
    opacity 400ms,
    transform 400ms;
}

.hidden .screen-card {
  opacity: 0;
}

.outro.hidden .screen-card {
  transform: scale(0.98);
}

.intro.hidden .screen-card {
  transform: translateY(-2%) rotate(-1deg);
}
</style>
