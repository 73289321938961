<template>
  <img class="pdf" :src="pdfUrl" />
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator"
import { PdfFragment } from "../../queries/screen/graphql"
import { getRealFilePath } from "../../types/File"
import { FileSize } from "../../types/FileSize"

@Component
export default class ScreenPdf extends Vue {
  @Prop({ type: Object, required: true }) private readonly data!: PdfFragment

  screenToken: string | null = null

  created() {
    const token = this.$route.query.token
    if (token != null && typeof token === "string") {
      this.screenToken = token
    }
  }

  get pdfUrl(): string {
    return `${getRealFilePath(this.data.pdfId, "screen", FileSize.FULL_HD)}&apiKey=${this.screenToken}`
  }
}
</script>

<style lang="scss">
.pdf {
  width: 100%;
  height: 100%;

  &.hidden {
    opacity: 0;
  }

  &.shown {
    opacity: 1;
  }

  &.intro {
    transition: opacity 400ms;
  }

  &.outro {
    transition: opacity 400ms 300ms;
  }
}
</style>
