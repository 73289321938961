import DeviceHelper from "./DeviceHelper"
import { Platform } from "../queries/auth/graphql"
import { sessionModule } from "../store/modules/session"
import { type FirebasePlugin } from "cordova-plugin-firebasex"

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
declare global {
  interface Window {
    FirebasePlugin?: FirebasePlugin
  }
}

class NotificationHelper {
  started = false

  initToken() {
    if (this.started) {
      return
    }
    this.started = true

    const platform = DeviceHelper.getPlatform()

    if (window.FirebasePlugin) {
      if (platform === Platform.Ios) {
        window.FirebasePlugin.hasPermission(
          (hasPermission) => {
            if (hasPermission) {
              this.setToken()
            } else {
              window.FirebasePlugin?.grantPermission(
                (hasPerms) => {
                  if (hasPerms) {
                    this.setToken()
                  }
                },
                (error) => {
                  console.error(error)
                }
              )
            }
          },
          (error) => console.error(error)
        )
      } else if (platform === Platform.Android) {
        this.setToken()
      }

      window.FirebasePlugin?.onTokenRefresh(
        (fcmToken) => {
          // Refresh token in store and send it to backend
          sessionModule.setAndSendNotificationToken(fcmToken)
        },
        (error) => {
          console.error(error)
        }
      )
    }
  }

  setToken() {
    window.FirebasePlugin?.getToken(
      (fcmToken) => {
        sessionModule.setAndSendNotificationToken(fcmToken)
      },
      (error) => {
        console.error(error)
      }
    )
  }
}

const instance = new NotificationHelper()

export default instance
