import aqua from "@/assets/images/markers/aqua.png"
import blue from "@/assets/images/markers/blue.png"
import cyan from "@/assets/images/markers/cyan.png"
import magenta from "@/assets/images/markers/magenta.png"
import orange from "@/assets/images/markers/orange.png"
import pink from "@/assets/images/markers/pink.png"
import red from "@/assets/images/markers/red.png"
import greenSea from "@/assets/images/markers/greenSea.png"
import darkMagenta from "@/assets/images/markers/darkMagenta.png"
import darkOrange from "@/assets/images/markers/darkOrange.png"
import darkRed from "@/assets/images/markers/darkRed.png"
import deepPink from "@/assets/images/markers/deepPink.png"
import forestGreen from "@/assets/images/markers/forestGreen.png"
import gold from "@/assets/images/markers/gold.png"
import skyBlue from "@/assets/images/markers/skyBlue.png"
import yellowGreen from "@/assets/images/markers/yellowGreen.png"
import residence from "@/assets/images/markers/residence.png"

export const MarkerType: { [type: string]: string } = {
  aqua,
  blue,
  cyan,
  magenta,
  orange,
  pink,
  red,
  greenSea,
  darkMagenta,
  darkOrange,
  darkRed,
  deepPink,
  forestGreen,
  gold,
  skyBlue,
  yellowGreen,
  residence,
}
