<template>
  <div class="widget-dropdown">
    <Widget>
      <div v-if="title" v-b-toggle="dropdownId">
        <div class="title-info">
          <h4 class="title">{{ title }}</h4>
          <img
            class="animated-chevron"
            src="@/assets/images/chevron-up.png"
            srcset="@/assets/images/chevron-up@2x.png 2x, @/assets/images/chevron-up@3x.png 3x"
          />
        </div>
      </div>

      <b-collapse :id="dropdownId" :dropdown="dropdownId">
        <b-card-body class="card-body">
          <slot></slot>
        </b-card-body>
      </b-collapse>
    </Widget>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  components: {
    Widget: async () => await import("./widget.vue"),
  },
})
export default class WidgetSecondary extends Vue {
  @Prop({ type: String, required: true }) readonly id!: string
  @Prop({ type: String, required: true }) readonly title!: string

  get dropdownId() {
    return `dropdown-${this.id}`
  }
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.widget-dropdown {
  .animated-chevron {
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    -o-transition-duration: 0.8s;
    transition-duration: 0.8s;

    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
  }

  .title-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 24px;
    border-top-left-radius: 8px 8px;
    border-top-right-radius: 8px 8px;
    background-color: $background-primary;
    cursor: pointer;

    .title {
      color: $secondary;
      text-align: left;
    }

    .animated-chevron {
      transform: rotate(0deg);
    }
  }

  .collapsed {
    .title-info {
      border-radius: 8px;
    }

    .animated-chevron {
      transform: rotate(-180deg);
    }
  }

  .card-body {
    padding: 16px;
  }
}
</style>
