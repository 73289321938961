<template>
  <canvas class="pdf-page"></canvas>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { PDFPageProxy, RenderTask } from "pdfjs-dist/types/src/display/api"

@Component
export default class PDFPage extends Vue {
  @Prop({ type: Object, required: true }) readonly page!: PDFPageProxy
  @Prop({ type: Number, default: 1 }) readonly scale!: number

  renderTask?: RenderTask | null = null

  $el!: HTMLCanvasElement

  async mounted() {
    await this.$nextTick()
    this.drawPage()
  }

  beforeDestroy() {
    this.destroyRenderTask()
  }

  destroyRenderTask() {
    // RenderTask#cancel
    // https://mozilla.github.io/pdf.js/api/draft/RenderTask.html
    this.renderTask?.cancel()
    delete this.renderTask
  }

  @Watch("scale")
  drawPage() {
    this.destroyRenderTask()

    const canvasContext = this.$el.getContext("2d")
    if (!canvasContext) {
      return
    }

    const viewport = this.page.getViewport({ scale: this.scale })
    const pixelRatio = window.devicePixelRatio || 1

    this.$el.width = Math.floor(viewport.width * pixelRatio)
    this.$el.height = Math.floor(viewport.height * pixelRatio)
    this.$el.style.width = `${Math.floor(viewport.width)}px`
    this.$el.style.height = `${Math.floor(viewport.height)}px`

    const transform = pixelRatio !== 1 ? [pixelRatio, 0, 0, pixelRatio, 0, 0] : undefined

    // PDFPageProxy#render
    // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
    this.renderTask = this.page.render({ canvasContext, viewport, transform })
  }
}
</script>

<style scoped lang="scss">
.pdf-page {
  border: 1px solid gray;
}
</style>
