<template>
  <screen-base title="Restauration" :sub-title="subTitle" :class="animationClass" flex>
    <screen-card-menu-day v-for="menu in displayedMenus" :key="menu.date" class="column-4" :data="menu" />
  </screen-base>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator"
import DateHelper from "../../helpers/DateHelper"
import { MenuFragment } from "../../queries/screen/graphql"

@Component({
  components: {
    "screen-base": () => import("./screen-base.vue"),
    "screen-card-menu-day": () => import("./screen-card-menu-day.vue"),
  },
})
export default class ScreenMenuNextDays extends Vue {
  @Prop({ type: Number, required: true }) private readonly timer!: number
  @Prop({ type: Array, required: true }) private readonly data!: MenuFragment[]

  displayedMenus: MenuFragment[] = []
  animationClass = ""

  created() {
    // Init menus
    this.setDisplayedMenus(0)

    const delay = (this.timer * 1000) / 2
    setTimeout(() => (this.animationClass = "outro shown"), delay - 600)
    setTimeout(() => (this.animationClass = "outro hidden"), delay - 500)
    setTimeout(() => this.setDisplayedMenus(4), delay)
    setTimeout(() => (this.animationClass = "intro hidden"), delay)
    setTimeout(() => (this.animationClass = "intro shown"), delay + 100)
    setTimeout(() => (this.animationClass = ""), delay + 900)
  }

  setDisplayedMenus(offset: number) {
    this.displayedMenus = this.data.slice(offset, offset + 4)
  }

  get subTitle(): string {
    const startDate = new Date(this.data[0].date)
    const endDate = new Date(this.data[7].date)
    const isSameMonth = startDate.getMonth() === endDate.getMonth()
    const startDateString = DateHelper.getDateStringFormat(startDate, isSameMonth ? "d" : "d MMMM")
    const endDateString = DateHelper.getDateStringFormat(endDate, "d MMMM")

    return `Menu du ${startDateString} au ${endDateString}`.toUpperCase()
  }
}
</script>

<style lang="scss">
.column-4 {
  display: flex;
  flex-direction: column;
  width: 23%;
  margin-left: 1%;
  margin-right: 1%;
}
</style>
