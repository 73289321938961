<template>
  <div class="activities">
    <NavigationBar :left-buttons="[{ text: 'Retour', back: true }]" title="Activités" />
    <div ref="contentView" class="app-view">
      <InternetError v-if="status === 'error'" :retry="loadActivities" />
      <div v-else-if="status === 'new' || status === 'loading'" class="loader"></div>
      <div v-else>
        <div ref="weekDate">
          <WeekDateTitle v-model="startDate" title="Programme" :left-button-visible="!isSameWeek" :right-button-visible="!isMaxWeek" />
        </div>
        <div class="description">
          <div
            v-for="(data, index) in weeklyActivities"
            :key="index"
            class="d-flex flex-column flex-md-row justify-content-center align-items-center description-content"
            :style="'min-height: ' + activitiesContentMinHeight + 'px'"
          >
            <div>
              <div class="week" :class="{ 'current-date': isCurrentDay(data.date) }">
                <h5 class="weekday">
                  {{ weekDays[index] | capitalize }}
                </h5>
                <h5 class="week-date">{{ data.date | date | dayOfMonthFormat }}</h5>
              </div>
            </div>

            <div class="day-content flex-grow-1 align-self-stretch">
              <div class="d-flex justify-content-center justify-content-md-start flex-wrap h-100">
                <WidgetActivity v-for="activity in data.activities" :key="activity.id" class="align-self-center" :activity="activity" />
              </div>
              <div class="border-horizontal"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import { isSameWeek, startOfToday, startOfISOWeek, isSameDay } from "date-fns"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import { activityModule } from "@/store/modules/activity"
import DateHelper from "@/helpers/DateHelper"
import { sessionModule } from "@/store/modules/session"

@Component({
  components: {
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
    WidgetActivity: async () => await import("@/components/widgets/widget-activity.vue"),
    WeekDateTitle: async () => await import("@/components/week-date-title.vue"),
    InternetError: async () => await import("@/components/internet-error.vue"),
  },
})
export default class Activities extends Vue {
  weekDays: Array<string> = []
  currentDate = startOfToday()
  startDate = startOfISOWeek(this.currentDate)
  activitiesContentMinHeight = 65

  $refs!: {
    contentView?: HTMLDivElement
    weekDate?: HTMLDivElement
  }

  get currentResidenceId() {
    return sessionModule.currentResidence?.id
  }

  get weeklyActivities() {
    if (!this.currentResidenceId) {
      return null
    }

    const endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate() + 7)
    return activityModule.activities(this.currentResidenceId, this.startDate, endDate)
  }

  get status() {
    return activityModule.status
  }

  get isSameWeek() {
    return isSameWeek(this.currentDate, this.startDate)
  }

  get isMaxWeek() {
    const date = new Date()
    date.setDate(date.getDate() + 7 * 4 * 6) // ~6 months
    return isSameWeek(date, this.startDate)
  }

  mounted() {
    TrackingHelper.track(AppTrackEvent.activitiesOpened)

    this.loadActivities()

    this.setActivitiesContentMinHeight()
    this.setWeekDays()
  }

  created() {
    window.addEventListener("resize", this.handleResize)
  }

  updated() {
    this.setActivitiesContentMinHeight()
  }

  destroyed() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize() {
    this.setActivitiesContentMinHeight()
    this.setWeekDays()
  }

  setActivitiesContentMinHeight() {
    const contentViewHeight = this.$refs.contentView?.clientHeight ?? 0
    const weekDateHeight = this.$refs.weekDate?.clientHeight ?? 0
    const height = (contentViewHeight - weekDateHeight) / 7
    this.activitiesContentMinHeight = Math.max(height, 65)
  }

  setWeekDays() {
    if (window.innerWidth >= 768) {
      this.weekDays = DateHelper.getShortWeekDays()
    } else {
      this.weekDays = DateHelper.getWeekDays()
    }
  }

  async loadActivities() {
    if (!this.currentResidenceId) {
      return
    }
    await activityModule.preloadActivities(this.currentResidenceId)
  }

  isCurrentDay(date: Date) {
    return isSameDay(date, this.currentDate)
  }
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.activities {
  height: 100%;
  display: flex;
  flex-flow: column;

  .description {
    padding: 0 40px;

    .week {
      max-width: 100px;
      min-width: 61px;
      margin: auto;
      padding: 12px;

      .week-date {
        color: $text-body;
        font-weight: 400;
      }

      &.current-date {
        background-color: $primary;
        border-radius: 26.7px;
        margin: 8px auto;

        .week-date {
          color: white;
        }

        .weekday {
          color: $background-default;
        }
      }
    }

    .activities-container {
      min-height: 65px;
    }

    .day-content {
      padding: 4px 4px 3px 4px;

      .widget-activity {
        margin: 8px;

        @media (max-width: $md) and (orientation: portrait) {
          width: 100%;
        }
      }
    }
  }
}
</style>
