<template>
  <div class="message-container">
    <div v-if="author && userId !== author.userId" class="d-flex align-items-start flex-column">
      <h5 class="message-data other"> De {{ name }} le {{ message.createdAt | date | dateTimeFormat }} </h5>
      <Nl2Br class="message" :content="message.body" />
    </div>
    <div v-else class="d-flex align-items-end flex-column">
      <h5 class="message-data me"> De vous-même, le {{ message.createdAt | date | dateTimeFormat }} </h5>
      <Nl2Br class="message me" :content="message.body" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { ThreadMessageFragment, ThreadAuthorFragment } from "@/queries/client/graphql"

@Component({
  components: {
    Nl2Br: async () => await import("@/components/nl2br.vue"),
  },
})
export default class MessageContainer extends Vue {
  @Prop({ type: Object, required: true }) readonly message!: ThreadMessageFragment
  @Prop({ type: Array, required: true }) readonly authors!: ThreadAuthorFragment[]
  @Prop({ type: String, required: true }) readonly userId!: string

  get author() {
    return this.authors.find(({ id }) => id === this.message.authorId) ?? null
  }

  get name() {
    return `${this.author?.firstName ?? ""} ${this.author?.lastName ?? ""}`.trim()
  }
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.message-container {
  .message-data {
    margin-bottom: 8px;

    &.me {
      text-align: right;
    }

    &.other {
      text-align: left;
    }
  }

  .message {
    max-width: 540px;
    padding: 16px;
    border-radius: 8px;
    color: $text-body;
    background-color: white;
    text-align: left;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);

    &.me {
      color: $secondary;
      background-color: $background-primary;
    }
  }
}
</style>
