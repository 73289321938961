<template>
  <div class="popup-activity">
    <h3 class="title">{{ activity.name }}</h3>
    <div class="content d-flex justify-content-center flex-wrap flex-md-nowrap align-content-center">
      <div>
        <ImageUrl v-if="activity.photoId" class="img-activity" :file-id="activity.photoId" :size="FileSize.WIDTH_HD" />
      </div>

      <div class="information-container flex-grow-1" :class="{ 'has-image': activity.photoId }">
        <div v-if="activity.description" class="description">{{ activity.description }}</div>
        <div class="information">
          <h3 class="information-title">Informations</h3>
          <div class="border"></div>
          <div v-if="activity.location" class="information-content"> Lieu de rendez-vous : {{ activity.location }} </div>
          <div class="information-content"> Date : {{ activity.startDateTime | date | dateFormat }} </div>
          <div class="information-content"> Heure de début : {{ activity.startDateTime | date | timeFormat }} </div>
          <div class="information-content"> Heure de fin : {{ activity.endDateTime | date | timeFormat }} </div>
        </div>
      </div>
    </div>

    <button class="btn-close" @click="close()">
      <img class="img-close" src="@/assets/images/close.png" srcset="@/assets/images/close@2x.png 2x, @/assets/images/close@3x.png 3x" />
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { ActivityFragment } from "@/queries/client/graphql"
import { FileSize } from "@/types/FileSize"

@Component({
  components: {
    ImageUrl: async () => await import("@/components/image-url.vue"),
  },
})
export default class PopupActivity extends Vue {
  @Prop({ type: Object, required: true }) readonly activity!: ActivityFragment
  @Prop({ type: Function, required: true }) readonly close!: () => void

  FileSize = FileSize
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.popup-activity {
  padding-top: 16px;
  position: relative;

  .btn-close {
    position: absolute;
    top: 16px;
    left: 30px;

    .img-close {
      width: 24px;
      height: 24px;
    }
  }

  .title {
    margin: 0 56px 33px 56px;
  }

  .content {
    .img-activity {
      width: 100%;
      max-width: 335px;
      object-fit: contain;
      border-radius: 8px;
      padding: 15px;
    }

    .information-container {
      padding: 15px;

      @media (min-width: $md) {
        &.has-image {
          max-width: 335px;
        }
      }

      .description {
        @include body();
        margin-bottom: 15px;
      }

      .information {
        padding: 16px;
        border-radius: 8px;
        background-color: $textfield-bg;

        .information-title {
          text-align: left;
        }

        .border {
          width: 100%;
          height: 2px;
          border: solid 1px $border;
          margin-top: 3.5px;
          margin-bottom: 8.5px;
        }

        .information-content {
          @include body();
        }
      }
    }
  }
}
</style>
