import { VuexModule, Module, Action, Mutation } from "vuex-class-modules"
import store from ".."
import { getFromStorage, removeItemsFromLocalStorage, setToStorage } from "../../utils/storage"
import { isOffline } from "../../helpers/ConnectionHelper"
import APIClient from "../../api/ApiClient"
import { Status, SubStatus } from "../../types/Status"
import { loaderModule } from "./loader"
import { TeamFragment } from "../../queries/client/graphql"
import DateHelper from "../../helpers/DateHelper"

const TEAMS_KEY = "teams"

type TeamWithStatus = {
  status: SubStatus
  team: TeamFragment | null
}

@Module
class TeamModule extends VuexModule {
  data: { [residenceId: string]: { [dateStr: string]: TeamWithStatus } } = {}
  status: Status = Status.New

  get teamWithStatus() {
    return (residenceId: string, date: Date) => this.data[residenceId][DateHelper.getDateStringFormat(date)]
  }

  @Mutation
  initResidence(residenceId: string) {
    if (this.data[residenceId] == null) {
      this.data[residenceId] = {}
    }
  }

  @Mutation
  removeLoadingData({ residenceId, dates }: { residenceId: string; dates: Date[] }) {
    dates.forEach((date) => {
      const dateStr = DateHelper.getDateStringFormat(date)

      if (this.data[residenceId][dateStr]?.status === SubStatus.Loading) {
        delete this.data[residenceId][dateStr]
      }
    })
    this.data = { ...this.data }
  }

  @Mutation
  setLoadingData({ residenceId, dates }: { residenceId: string; dates: Date[] }) {
    dates.forEach((date) => {
      const dateStr = DateHelper.getDateStringFormat(date)

      if (this.data[residenceId][dateStr] == null) {
        this.data[residenceId][dateStr] = {
          status: SubStatus.Loading,
          team: null,
        }
      }
    })
    this.data = { ...this.data }
  }

  @Mutation
  setData({ residenceId, teams, dates }: { residenceId: string; teams: TeamFragment[]; dates: Date[] }) {
    // Set teams
    teams.forEach((team) => {
      this.data[residenceId][DateHelper.getDateStringFormat(new Date(team.date))] = {
        status: SubStatus.Ready,
        team,
      }
    })

    // Clean loading states
    dates.forEach((date) => {
      const dateStr = DateHelper.getDateStringFormat(date)

      if (this.data[residenceId][dateStr]?.status === SubStatus.Loading) {
        this.data[residenceId][dateStr].status = SubStatus.Ready
      }
    })
    this.data = { ...this.data }
  }

  @Mutation
  clearData() {
    removeItemsFromLocalStorage(TEAMS_KEY)
    this.data = {}
    this.status = Status.New
  }

  @Mutation
  setStatus(status: Status) {
    this.status = status
  }

  @Action
  async syncTeams(residenceId: string) {
    // Prepare residence
    this.initResidence(residenceId)

    const dates: Date[] = []
    const currentDate = new Date()

    for (let index = 0; index < 30; index += 1) {
      dates.push(currentDate)
      currentDate.setDate(currentDate.getDate() + 1)
    }

    this.setLoadingData({ residenceId, dates })

    let teams: TeamFragment[]

    try {
      // Exception will be catched in SyncModule
      teams = await APIClient.getTeams(residenceId, new Date(), 30)
    } catch (error) {
      this.removeLoadingData({ residenceId, dates })
      throw error
    }

    // Cache data
    setToStorage(TEAMS_KEY, residenceId, teams)

    this.setData({ residenceId, teams, dates })

    if (Object.keys(this.data[residenceId]).length === 0) {
      this.setStatus(Status.Error)
    } else {
      this.setStatus(Status.Ready)
    }
  }

  @Action
  async loadTeam({ residenceId, date }: { residenceId: string; date: Date }) {
    // Prepare residence
    this.initResidence(residenceId)

    if (this.status === Status.New) {
      this.setStatus(Status.Loading)

      const localTeams = getFromStorage<TeamFragment[]>(TEAMS_KEY, residenceId)
      if (localTeams != null) {
        this.setData({ residenceId, teams: localTeams, dates: [date] })
        this.setStatus(Status.Ready)
      }
    }

    const teamWithStatus = this.data[residenceId][DateHelper.getDateStringFormat(date)]
    if (teamWithStatus != null && teamWithStatus.status === SubStatus.Loading) {
      // Loading in progress
      return
    }

    if (!isOffline()) {
      loaderModule.willRefresh()

      this.setLoadingData({ residenceId, dates: [date] })

      try {
        const team = await APIClient.getTeamForDay(residenceId, date)

        this.setData({ residenceId, teams: [team], dates: [date] })
      } catch (error) {
        this.removeLoadingData({ residenceId, dates: [date] })

        // TODO
        console.log(error)
      }

      loaderModule.didRefresh()
    }

    if (Object.keys(this.data[residenceId]).length === 0) {
      this.setStatus(Status.Error)
    } else {
      this.setStatus(Status.Ready)
    }
  }
}

export const teamModule = new TeamModule({ store, name: "team" })
