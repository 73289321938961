<template>
  <div class="information d-flex align-items-center justify-content-center">
    <div class="info-container">
      <h1 class="title">{{ residence.name }}</h1>
      <Widget>
        <h2 class="info">{{ residence.schedule }}</h2>
        <br />
        <h2 class="info">{{ residence.address }}</h2>
        <br />
        <h2 class="info">{{ residence.phone }}</h2>
        <br />
        <h2 class="info">{{ residence.email }}</h2>
      </Widget>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import { ResidenceFragment } from "@/queries/client/graphql"

@Component({
  components: {
    Widget: async () => await import("@/components/widgets/widget.vue"),
  },
})
export default class Information extends Vue {
  @Prop({ type: Object, required: true }) readonly residence!: ResidenceFragment

  mounted() {
    TrackingHelper.track(AppTrackEvent.myResidenceHoursOpened)
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.information {
  height: 100%;

  .info-container {
    width: 100%;
    max-width: 500px;
    margin: 20px;

    .title {
      @include title-secondary-style();
      margin-bottom: 40px;
    }

    .widget {
      padding: 20px;

      .info {
        font-weight: normal;
        color: $text-body;
      }
    }
  }
}
</style>
