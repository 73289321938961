import { VuexModule, Module, Mutation, Action } from "vuex-class-modules"
import { jwtDecode } from "jwt-decode"
import { ApolloError } from "@apollo/client/core"
import { Subject } from "rxjs"
import store from ".."
import APIClient from "../../api/ApiClient"
import APIAuth from "../../api/ApiAuth"
import { setDelegate as setApolloDelegate } from "../../api/apollo-provider"
import { setDelegate as setAxiosDelegate } from "../../api/axios-provider"
import TrackingHelper from "../../helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "../../helpers/tracking/Tracker"
import NotificationHelper from "../../helpers/NotificationHelper"
import { UserFragment, ResidenceFragment } from "../../queries/client/graphql"
import { ProviderDelegate } from "../../api/ProviderDelegate"
import { parseJSON, setItemInLocalStorage } from "../../utils/storage"
import { SessionTokenFragment } from "../../queries/auth/graphql"
import { activityModule } from "./activity"
import { teamModule } from "./team"
import { serviceModule } from "./service"
import { linkModule } from "./link"
import { menuModule } from "./menu"
import { widgetModule } from "./widget"
import router from "../../router"
import { featureModule } from "./feature"

const ACCESS_TOKEN_KEY = "accessToken"
const REFRESH_TOKEN_KEY = "refreshToken"
const NOTIFICATION_TOKEN_KEY = "notificationToken"
const USER_KEY = "user"
const CURRENT_RESIDENCE_KEY = "currentResidence"
const RESIDENCES_KEY = "residences"
const ERROR_REFRESH_TOKEN_KEY = "AUTHENTICATION.REFRESH_TOKEN_FAILED"
const MAX_DELAY = Math.pow(2, 31) - 1
const APP_REVIEW_LAST_PROMPT_DATE_KEY = "appReviewLastPromptDate"

@Module
class SessionModule extends VuexModule implements ProviderDelegate {
  // State
  accessToken: string | null = localStorage.getItem(ACCESS_TOKEN_KEY)
  refreshToken: string | null = localStorage.getItem(REFRESH_TOKEN_KEY)
  notificationToken: string | null = localStorage.getItem(NOTIFICATION_TOKEN_KEY)
  refreshTimer = 0
  user: UserFragment | null = parseJSON<UserFragment>(localStorage.getItem(USER_KEY))
  currentResidence: ResidenceFragment | null = parseJSON<ResidenceFragment>(localStorage.getItem(CURRENT_RESIDENCE_KEY))
  residences: ResidenceFragment[] | null = parseJSON<ResidenceFragment[]>(localStorage.getItem(RESIDENCES_KEY))
  isRefreshingSession = false
  refreshSession$: Subject<void> = new Subject()
  appReviewLastPromptDate: string | null = localStorage.getItem(APP_REVIEW_LAST_PROMPT_DATE_KEY)

  // Getters
  get fullName(): string {
    if (this.user == null) {
      return ""
    }

    return [this.user.firstName, this.user.lastName].filter((name) => name.length !== 0).join(" ")
  }

  get isAuthenticated(): boolean {
    return this.user !== null
  }

  get hasCurrentResidence(): boolean {
    return this.currentResidence != null
  }

  // Actions
  @Action
  async login({ email, password }: { email: string; password: string }) {
    if (this.isAuthenticated) {
      throw new Error("[Session] Login: User is already logged in.")
    }

    // 1 - Save AcessToken & RefreshToken
    console.log("[Session] Logging in...")

    let sessionToken: SessionTokenFragment
    try {
      sessionToken = await APIAuth.login(email, password)
    } catch (error) {
      console.log("[Session] Failed to login: ", error)
      return
    }

    this.setToken(sessionToken)

    try {
      await this.loadSessionData()
    } catch (error) {
      // Login failed
      this.setToken(null)
    }

    console.log("[Session] Logged in!")
  }

  @Action
  async refreshSession() {
    if (!this.isAuthenticated) {
      throw new Error("[Session] Refresh Session: User is not logged in.")
    }

    this.setIsRefreshingSession(true)

    // 1 - Refresh token
    console.log("[Session] Refreshing session...")

    let sessionToken: SessionTokenFragment

    try {
      sessionToken = await APIAuth.refreshToken(this.refreshToken)
    } catch (error) {
      console.log("[Session] Refresh Session error: ", error)
      this.setIsRefreshingSession(false)

      if (error instanceof ApolloError) {
        const index = error.graphQLErrors.findIndex(
          (graphQLError) =>
            graphQLError.extensions &&
            (graphQLError.extensions.code === ERROR_REFRESH_TOKEN_KEY || graphQLError.extensions.code === "AUTHENTICATION.INVALID_DATA")
        )

        if (index !== -1) {
          try {
            await this.logout()
          } catch (e) {
            console.log("[Session] Logout error: ", e)
          }

          await router.replace("/login")
        }
      }

      return
    }

    this.setToken(sessionToken)

    this.refreshSession$.next()
    this.setIsRefreshingSession(false)

    console.log("[Session] Session refreshed!")
  }

  @Action
  async clearSessionData() {
    if (!this.isAuthenticated) {
      throw new Error("[Session] Logout: user is not logged in.")
    }

    console.log("[Session] Logging out...")

    // 1 - Delete Push Notification Token
    if (this.notificationToken) {
      try {
        await APIClient.deleteNotificationToken()
      } catch (error) {
        console.log("[Session] Delete notification token error: ", error)
      }
    }

    // 2 - Call Logout on API
    try {
      await APIAuth.logout()
    } catch (error) {
      // Silent logout errors since we want success to proceed
      console.log("[Session] Logout error: ", error)
    }

    this.setToken(null)
    this.setUser(null)
    this.setResidences(null)

    activityModule.clearData()
    teamModule.clearData()
    serviceModule.clearData()
    linkModule.clearData()
    menuModule.clearData()
    widgetModule.clearData()
    featureModule.clearData()

    TrackingHelper.track(AppTrackEvent.loggedOut)

    console.log("[Session] Logged out!")
  }

  @Action
  async loadSessionData() {
    // 2 - Fetch User
    console.log("[Session] Loading user...")

    let user: UserFragment | null = null
    try {
      user = await APIClient.me()
    } catch (error) {
      console.log("[Session] Failed to fetch user: ", error)
      throw error
    }

    // 3 - Fetch Residences
    console.log("[Session] Loading residences...")

    let residences: ResidenceFragment[] | null = null
    try {
      residences = await APIClient.getAvailableResidences()
    } catch (error) {
      console.log("[Session] Failed to fetch residences: ", error)
      throw error
    }

    this.setUser(user)
    this.setResidences(residences)

    // 4 - Send Push Notification Token
    NotificationHelper.setToken()

    TrackingHelper.track(AppTrackEvent.accountInitialized, { user })
  }

  @Action
  setToken(token: SessionTokenFragment | null) {
    const accessToken = token?.accessToken ?? null

    this.setAccessToken(accessToken)
    this.setRefreshToken(token?.refreshToken ?? null)

    if (accessToken) {
      const decoded = jwtDecode<{ exp: number }>(accessToken)

      const expDate = new Date(decoded.exp * 1000)
      const currentDate = new Date()

      const REFRESH_WINDOW = 5000
      const interval = expDate.getTime() - currentDate.getTime() - REFRESH_WINDOW
      const secureInterval = Math.min(MAX_DELAY, interval)

      const refreshTimer = window.setTimeout(async () => {
        await this.refreshSession()
      }, secureInterval)

      this.setRefreshTimer(refreshTimer)
    } else {
      this.setRefreshTimer(0)
    }
  }

  @Action
  async setAndSendNotificationToken(fcmToken: string | null) {
    this.setNotificationToken(fcmToken)

    if (this.notificationToken && this.isAuthenticated) {
      await APIClient.setNotificationToken(this.notificationToken)
    }
  }

  @Action
  async logout() {
    if (this.isAuthenticated) {
      if (this.isRefreshingSession) {
        await new Promise<void>((resolve) =>
          this.refreshSession$.subscribe(async () => {
            await this.clearSessionData()
            resolve()
          })
        )
      } else {
        await this.clearSessionData()
      }
    } else {
      this.setRefreshTimer(0)
    }
  }

  // Mutations
  @Mutation
  setAccessToken(token: string | null) {
    setItemInLocalStorage(ACCESS_TOKEN_KEY, token)
    this.accessToken = token
  }

  @Mutation
  setRefreshToken(token: string | null) {
    setItemInLocalStorage(REFRESH_TOKEN_KEY, token)
    this.refreshToken = token
  }

  @Mutation
  setNotificationToken(token: string | null) {
    setItemInLocalStorage(NOTIFICATION_TOKEN_KEY, token)
    this.notificationToken = token
  }

  @Mutation
  setUser(user: UserFragment | null) {
    setItemInLocalStorage(USER_KEY, user)
    this.user = user
  }

  @Mutation
  setCurrentResidence(currentResidence: ResidenceFragment | null) {
    setItemInLocalStorage(CURRENT_RESIDENCE_KEY, currentResidence)
    this.currentResidence = currentResidence
  }

  @Mutation
  setResidences(residences: ResidenceFragment[] | null) {
    setItemInLocalStorage(RESIDENCES_KEY, residences)
    this.residences = residences

    if (residences == null) {
      this.currentResidence = null
      setItemInLocalStorage(CURRENT_RESIDENCE_KEY, this.currentResidence)
    } else {
      const currentResidenceId = this.currentResidence?.id ?? null
      if (currentResidenceId != null) {
        // Check that currentResidence is still valid
        const index = residences.findIndex((r) => r.id === currentResidenceId)
        if (index !== -1) {
          this.currentResidence = residences[index]
          setItemInLocalStorage(CURRENT_RESIDENCE_KEY, this.currentResidence)
          return
        }
      }

      // Automatically select first residence if solo resident profile
      if (residences.length === 1) {
        ;[this.currentResidence] = residences
        setItemInLocalStorage(CURRENT_RESIDENCE_KEY, this.currentResidence)
      }
    }
  }

  @Mutation
  setRefreshTimer(refreshTimer: number) {
    if (this.refreshTimer) {
      window.clearTimeout(this.refreshTimer)
    }

    this.refreshTimer = refreshTimer
  }

  @Mutation
  setIsRefreshingSession(isRefreshingSession: boolean) {
    this.isRefreshingSession = isRefreshingSession
  }

  @Mutation
  setAppReviewLastPromptDate(dateStr: string) {
    setItemInLocalStorage(APP_REVIEW_LAST_PROMPT_DATE_KEY, dateStr)
    this.appReviewLastPromptDate = dateStr
  }

  // ProviderDelegate
  delegateGetAccessToken(): string | null {
    return this.accessToken
  }

  async delegateRefreshSession() {
    await this.refreshSession()
  }

  delegateIsRefreshingSession(): boolean {
    return this.isRefreshingSession
  }

  delegateGetRefreshSession$(): Subject<void> {
    return this.refreshSession$
  }
}

export const sessionModule = new SessionModule({ store, name: "session" })

setApolloDelegate(sessionModule)
setAxiosDelegate(sessionModule)
