<template>
  <div class="trombi">
    <div id="pdf-container" class="pdf-container">
      <PdfDocument :pdf-id="trombiId" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"

@Component({
  components: {
    PdfDocument: async () => await import("@/components/pdf-document/pdf-document.vue"),
  },
})
export default class Trombi extends Vue {
  @Prop({ type: String, required: true }) readonly trombiId!: string

  mounted() {
    TrackingHelper.track(AppTrackEvent.myResidenceTrombiOpened)
  }
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.trombi {
  width: 100%;
  height: 100%;
  padding: 40px;

  @media (max-width: $sm), (max-width: $md) and (orientation: landscape) {
    padding: 20px;
  }

  .pdf-container {
    height: 100%;
    overflow: auto;
  }
}
</style>
