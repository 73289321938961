export enum FileSize {
  ORIGINAL = "ORIGINAL",
  SMALL_SQUARE = "SMALL_SQUARE",
  SMALL_SQUARE_2X = "SMALL_SQUARE_2X",
  MEDIUM_SQUARE = "MEDIUM_SQUARE",
  MEDIUM_SQUARE_2X = "MEDIUM_SQUARE_2X",
  LARGE_SQUARE = "LARGE_SQUARE",
  LARGE_SQUARE_2X = "LARGE_SQUARE_2X",
  WIDTH_HD_2X = "WIDTH_HD_2X",
  WIDTH_HD = "WIDTH_HD",
  FULL_HD_2X = "FULL_HD_2X",
  FULL_HD = "FULL_HD",
  THUMBNAIL = "THUMBNAIL",
}
