/* eslint-disable */
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: Date;
  Time: string;
};

export enum AuthenticatedRoute {
  Admin = 'ADMIN',
  Client = 'CLIENT'
}

export type AuthenticationMutation = {
  createUserWithPassword: Scalars['String'];
  forgetPassword: Scalars['Boolean'];
  generateLostPasswordToken: Scalars['DateTime'];
  loginPassword: Token;
  logoutUserFromDevice: Scalars['Boolean'];
  refreshToken: Token;
  useLostPasswordToken: Scalars['Boolean'];
};


export type AuthenticationMutationCreateUserWithPasswordArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};


export type AuthenticationMutationForgetPasswordArgs = {
  email: Scalars['String'];
};


export type AuthenticationMutationGenerateLostPasswordTokenArgs = {
  email: Scalars['String'];
};


export type AuthenticationMutationLoginPasswordArgs = {
  description: Scalars['String'];
  deviceId: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  platform: Platform;
  route: AuthenticatedRoute;
};


export type AuthenticationMutationLogoutUserFromDeviceArgs = {
  deviceId: Scalars['String'];
  platform: Platform;
  route: AuthenticatedRoute;
};


export type AuthenticationMutationRefreshTokenArgs = {
  deviceId: Scalars['String'];
  platform: Platform;
  refreshToken?: InputMaybe<Scalars['String']>;
  route: AuthenticatedRoute;
};


export type AuthenticationMutationUseLostPasswordTokenArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type AuthenticationQuery = {
  isLoggedIn: Scalars['Boolean'];
};


export type AuthenticationQueryIsLoggedInArgs = {
  route: AuthenticatedRoute;
};

export type Mutation = {
  authentication: AuthenticationMutation;
  version: Scalars['String'];
};

export enum Platform {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB'
}

export type Query = {
  authentication: AuthenticationQuery;
  version: Scalars['String'];
};

export type Token = {
  accessToken: Scalars['String'];
  id: Scalars['String'];
  platform: Platform;
  refreshToken: Scalars['String'];
  route: AuthenticatedRoute;
};

export type SessionTokenFragment = { id: string, route: AuthenticatedRoute, platform: Platform, accessToken: string, refreshToken: string };

export type IsLoggedInQueryVariables = Exact<{
  route: AuthenticatedRoute;
}>;


export type IsLoggedInQuery = { authentication: { isLoggedIn: boolean } };

export type LoginPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  route: AuthenticatedRoute;
  deviceId: Scalars['String'];
  platform: Platform;
  description: Scalars['String'];
}>;


export type LoginPasswordMutation = { authentication: { loginPassword: SessionTokenFragment } };

export type RefreshTokenMutationVariables = Exact<{
  refreshToken?: InputMaybe<Scalars['String']>;
  route: AuthenticatedRoute;
  deviceId: Scalars['String'];
  platform: Platform;
}>;


export type RefreshTokenMutation = { authentication: { refreshToken: SessionTokenFragment } };

export type CreateUserWithPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


export type CreateUserWithPasswordMutation = { authentication: { createUserWithPassword: string } };

export type GenerateLostPasswordTokenMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type GenerateLostPasswordTokenMutation = { authentication: { generateLostPasswordToken: Date } };

export type UseLostPasswordTokenMutationVariables = Exact<{
  email: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type UseLostPasswordTokenMutation = { authentication: { useLostPasswordToken: boolean } };

export type LogoutUserFromDeviceMutationVariables = Exact<{
  deviceId: Scalars['String'];
  platform: Platform;
  route: AuthenticatedRoute;
}>;


export type LogoutUserFromDeviceMutation = { authentication: { logoutUserFromDevice: boolean } };

export const SessionToken = gql`
    fragment SessionToken on Token {
  id
  route
  platform
  accessToken
  refreshToken
}
    `;
export const IsLoggedIn = gql`
    query IsLoggedIn($route: AuthenticatedRoute!) {
  authentication {
    isLoggedIn(route: $route)
  }
}
    `;
export const LoginPassword = gql`
    mutation LoginPassword($email: String!, $password: String!, $route: AuthenticatedRoute!, $deviceId: String!, $platform: Platform!, $description: String!) {
  authentication {
    loginPassword(
      email: $email
      password: $password
      route: $route
      deviceId: $deviceId
      platform: $platform
      description: $description
    ) {
      ...SessionToken
    }
  }
}
    ${SessionToken}`;
export const RefreshToken = gql`
    mutation RefreshToken($refreshToken: String, $route: AuthenticatedRoute!, $deviceId: String!, $platform: Platform!) {
  authentication {
    refreshToken(
      refreshToken: $refreshToken
      route: $route
      deviceId: $deviceId
      platform: $platform
    ) {
      ...SessionToken
    }
  }
}
    ${SessionToken}`;
export const CreateUserWithPassword = gql`
    mutation CreateUserWithPassword($email: String!, $password: String!, $firstName: String!, $lastName: String!) {
  authentication {
    createUserWithPassword(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
    )
  }
}
    `;
export const GenerateLostPasswordToken = gql`
    mutation GenerateLostPasswordToken($email: String!) {
  authentication {
    generateLostPasswordToken(email: $email)
  }
}
    `;
export const UseLostPasswordToken = gql`
    mutation UseLostPasswordToken($email: String!, $token: String!, $password: String!) {
  authentication {
    useLostPasswordToken(email: $email, token: $token, password: $password)
  }
}
    `;
export const LogoutUserFromDevice = gql`
    mutation LogoutUserFromDevice($deviceId: String!, $platform: Platform!, $route: AuthenticatedRoute!) {
  authentication {
    logoutUserFromDevice(deviceId: $deviceId, platform: $platform, route: $route)
  }
}
    `;