<template>
  <screen-card :class="cardStyle" title="Équipe">
    <screen-team-item v-if="internalData.day" :data="internalData.day" :orientation="internalData.night ? 'horizontal' : 'vertical'" />
    <img v-if="internalData.day && internalData.night" class="divider" src="@/assets/images/divider.png" />
    <screen-team-item v-if="internalData.night" :data="internalData.night" :orientation="internalData.day ? 'horizontal' : 'vertical'" />
    <p v-if="!internalData.day && !internalData.night" class="info-unavailable">Information indisponible</p>
  </screen-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { Job, TeamFragment, TeamMemberFragment } from "../../queries/screen/graphql"

type TeamData = {
  day: TeamMemberFragment | null
  night: TeamMemberFragment | null
}

@Component({
  components: {
    "screen-card": () => import("./screen-card.vue"),
    "screen-team-item": () => import("./screen-team-item.vue"),
  },
})
export default class ScreenCardTeam extends Vue {
  @Prop({ type: Object, required: true }) data!: TeamFragment

  get internalData(): TeamData {
    return {
      day: this.data.teamMembers.find((team) => team.job === Job.Coordinator) ?? null,
      night: this.data.teamMembers.find((team) => team.job === Job.NightWalker) ?? null,
    }
  }

  get cardStyle() {
    return {
      "card-team": true,
      "day-night": this.internalData.day && this.internalData.night,
      night: this.internalData.night && !this.internalData.day,
      day: this.internalData.day && !this.internalData.night,
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../style/variables";

.card-team {
  background-position: bottom right;
  background-size: 33.46%;
  background-repeat: no-repeat;

  &.day-night {
    background-image: url("~@/assets/images/day-night.png");
    background-image: -webkit-image-set(url("~@/assets/images/day-night@2x.png") 2x, url("~@/assets/images/day-night@3x.png") 3x);
    background-image: image-set(url("~@/assets/images/day-night@2x.png") 2x, url("~@/assets/images/day-night@3x.png") 3x);
  }

  &.day {
    background-image: url("~@/assets/images/day-shape.png");
    background-image: -webkit-image-set(url("~@/assets/images/day-shape@2x.png") 2x, url("~@/assets/images/day-shape@3x.png") 3x);
    background-image: image-set(url("~@/assets/images/day-shape@2x.png") 2x, url("~@/assets/images/day-shape@3x.png") 3x);
  }

  &.night {
    background-image: url("~@/assets/images/night-shape.png");
    background-image: -webkit-image-set(url("~@/assets/images/night-shape@2x.png") 2x, url("~@/assets/images/night-shape@3x.png") 3x);
    background-image: image-set(url("~@/assets/images/night-shape@2x.png") 2x, url("~@/assets/images/night-shape@3x.png") 3x);
  }

  .content {
    width: 75%;
    font-size: 275%;
    color: $secondary;
    display: inline-block;
  }

  .divider {
    width: 22.9%;
    margin: 8% auto;
    display: block;
  }

  .info-unavailable {
    margin: 36% 0;
    text-align: center;
    font-size: 250%;
    font-weight: 600;
    font-family: "Lobster", sans-serif;
    color: $secondary;
  }
}
</style>
