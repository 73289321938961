export function parseJSON<T>(objectStringify: string | null): T | null {
  if (!objectStringify) {
    return null
  }
  const object = JSON.parse(objectStringify)
  return (object as T) ? object : null
}

export function setItemInLocalStorage<T>(key: string, object: T | null) {
  if (object == null) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, typeof object === "string" ? object : JSON.stringify(object))
  }
}

export function removeItemFromLocalStorage(key: string) {
  localStorage.removeItem(key)
}

export function removeItemsFromLocalStorage(keyPrefix: string) {
  let currentIndex = 0
  let currentKey = localStorage.key(currentIndex)

  while (currentKey != null) {
    if (currentKey.startsWith(keyPrefix)) {
      localStorage.removeItem(currentKey)
    }

    currentIndex += 1
    currentKey = localStorage.key(currentIndex)
  }
}

function storageKey(key: string, residenceId: string): string {
  return `${key}${residenceId}`
}

export function getFromStorage<T>(key: string, residenceId: string): T | null {
  return parseJSON<T>(localStorage.getItem(storageKey(key, residenceId)))
}

export function setToStorage<T>(key: string, residenceId: string, data: T) {
  return setItemInLocalStorage<T>(storageKey(key, residenceId), data)
}
