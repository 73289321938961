<template>
  <screen-card class="menu-day-card">
    <p class="date">
      <span class="week-day">{{ weekDay }}</span>
      {{ dayMonth }}
    </p>
    <div class="content-container">
      <template v-if="lunch || dinner">
        <screen-card-menu-day-item
          v-if="lunch"
          :class="{ special: lunch.specialLabel }"
          title="Déjeuner"
          :subtitle="lunch.specialLabel"
          :data="lunch"
        />
        <screen-card-menu-day-item v-if="dinner" title="Dîner" :data="dinner" />
      </template>
      <p v-else class="coming-soon-day">Prochainement disponible...</p>
    </div>
  </screen-card>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator"
import DateHelper from "../../helpers/DateHelper"
import { MealFragment, MealType, MenuFragment } from "../../queries/screen/graphql"

@Component({
  components: {
    "screen-card": () => import("./screen-card.vue"),
    "screen-card-menu-day-item": () => import("./screen-card-menu-day-item.vue"),
  },
})
export default class ScreenCardMenuDay extends Vue {
  @Prop({ type: Object, required: true }) private readonly data!: MenuFragment

  get date(): string {
    return DateHelper.getDateStringFormat(new Date(this.data.date), "EEEE d MMMM")
  }

  get weekDay(): string {
    return this.date.slice(0, this.date.indexOf(" "))
  }

  get dayMonth(): string {
    return this.date.slice(this.date.indexOf(" "))
  }

  get lunch(): MealFragment | null {
    return this.data.meals.find((meal) => meal.type === MealType.Lunch) ?? null
  }

  get dinner(): MealFragment | null {
    return this.data.meals.find((meal) => meal.type === MealType.Dinner) ?? null
  }
}
</script>

<style lang="scss">
@import "../../style/_variables.scss";

.screen-card.menu-day-card {
  height: 100%;
  padding: 1.25%;

  .date {
    text-align: center;
    text-transform: capitalize;
    font-size: 187.5%;
    margin-bottom: 5%;

    .week-day {
      color: $secondary;
      font-size: 106.67%;
      font-weight: 600;
    }
  }

  .content-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .card-menu-day-item {
      margin-bottom: 5%;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .coming-soon-day {
      margin: 0;
      text-align: center;
      font-size: 250%;
      font-weight: 600;
      font-family: "Lobster", sans-serif;
      color: $secondary;
    }
  }

  .card-menu-day-item.special {
    background-color: #dfece9;

    .menu-item {
      color: #3c3c3c;
    }

    hr {
      border-color: $secondary;
    }
  }
}
</style>
