<template>
  <screen-card title="Météo" class="card-weather">
    <template v-if="data">
      <img
        v-if="weatherType"
        class="weather-icon"
        :src="require(`@/assets/images/weather/${weatherType}.png`)"
        :srcset="
          require(`@/assets/images/weather/${weatherType}@2x.png`) +
          ' 2x, ' +
          require(`@/assets/images/weather/${weatherType}@3x.png`) +
          ' 3x'
        "
      />
      <div class="weather-content">
        <p class="capitalize">{{ data.description }}</p>
        <p>
          <span class="max-temp">{{ data.maxTemperature }}°</span> <span class="min-temp">{{ data.minTemperature }}°</span>
        </p>
      </div>
    </template>
    <p v-else class="info-unavailable">Information indisponible</p>
  </screen-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { WeatherDayFragment } from "../../queries/screen/graphql"

@Component({
  components: {
    "screen-card": () => import("./screen-card.vue"),
  },
})
export default class ScreenCardWeather extends Vue {
  @Prop({ type: Object, required: false }) data!: WeatherDayFragment | undefined

  get weatherType(): string | null {
    return this.data?.type?.toLowerCase() ?? null
  }
}
</script>
<style scoped lang="scss">
@import "../../style/variables";

.card-weather {
  .weather-icon {
    width: 20.56%;
    vertical-align: middle;
  }

  .weather-content {
    width: 75%;
    padding-left: 4%;
    font-size: 275%;
    color: $secondary;
    vertical-align: middle;
    display: inline-block;

    .capitalize {
      text-transform: capitalize;
    }

    .min-temp {
      opacity: 0.5;
    }

    p {
      margin-bottom: 0;
    }
  }

  .info-unavailable {
    margin: 9% 0;
    text-align: center;
    font-size: 250%;
    font-weight: 600;
    font-family: "Lobster", sans-serif;
    color: $secondary;
  }
}
</style>
