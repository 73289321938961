import { Platform } from "../queries/auth/graphql"

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
declare global {
  interface Window {
    device?: {
      uuid?: string
      platform: string
    }
  }
}

class DeviceHelper {
  generateUUID(): string {
    const s4 = () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
  }

  getUUID(): string {
    const currentUUID = localStorage.getItem("deviceId")
    if (currentUUID == null) {
      let newUUID: string
      if (window && window.device && window.device.uuid) {
        newUUID = window.device.uuid
      } else {
        newUUID = this.generateUUID()
      }
      localStorage.setItem("deviceId", newUUID)
      return newUUID
    }

    return currentUUID
  }

  getPlatform(): Platform {
    if (window && window.device && window.device.platform) {
      switch (window.device.platform.toLowerCase()) {
        case "ios":
          return Platform.Ios
        case "android":
          return Platform.Android
        default:
          return Platform.Web
      }
    }
    return Platform.Web
  }

  isSafari(): boolean {
    return (
      /constructor/i.test(window.HTMLElement.toString()) ||
      ((p) => {
        return p.toString() === "[object SafariRemoteNotification]"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      })(!(window as any).safari || (typeof (window as any).safari !== "undefined" && (window as any).safari.pushNotification))
    )
  }

  isMobile(): boolean {
    return this.getPlatform() !== Platform.Web
  }
}

const instance = new DeviceHelper()

export default instance
