<template>
  <div class="calendar">
    <NavigationBar :left-buttons="[{ text: 'Retour', back: true }]" title="Calendrier" />
    <div class="app-view">
      <div class="d-flex justify-content-center content">
        <div class="calendar-container">
          <div class="calendar-widget">
            <DatePick v-model="currentDate" />
          </div>
          <div class="weekly-button">
            <button class="jda-btn btn-primary" @click="onClickWeeklyCalendar()"> Voir les activités de la semaine </button>
          </div>
        </div>

        <div class="information-container">
          <div class="d-flex justify-content-between align-items-center">
            <button @click="incrementDay(-1)">
              <img src="@/assets/images/next-back.png" srcset="@/assets/images/next-back@2x.png 2x, @/assets/images/next-back@3x.png 3x" />
            </button>
            <h4>{{ currentDate | fullDateFormat | capitalize }}</h4>
            <button @click="incrementDay(1)">
              <img src="@/assets/images/next.png" srcset="@/assets/images/next@2x.png 2x, @/assets/images/next@3x.png 3x" />
            </button>
          </div>
          <div class="calendar-day">
            <InternetError v-if="teamStatus === 'error'" :retry="loadTeam" />
            <div
              v-else-if="teamStatus === 'new' || teamStatus === 'loading' || (teamWithStatus && teamWithStatus.status === 'loading')"
              class="loader"
            ></div>
            <div v-else-if="teamWithStatus != null && teamWithStatus.team && teamWithStatus.team.teamMembers.length > 0" class="team">
              <WidgetTeam :team="teamWithStatus.team" />
            </div>
            <InternetError v-if="activityStatus === 'error' || menuStatus === 'error'" :retry="loadMenuAndActivities" />
            <div
              v-else-if="
                activityStatus === 'new' ||
                activityStatus === 'loading' ||
                (activitiesWithStatus && activitiesWithStatus.status === 'loading') ||
                menuStatus === 'new' ||
                menuStatus === 'loading' ||
                (menuWithStatus && menuWithStatus.status === 'loading')
              "
              class="loader-block"
            >
              <div class="loader"></div>
            </div>
            <Widget v-else-if="hasProgram" class="program">
              <div v-if="morningActivities && morningActivities.length !== 0" class="block">
                <h3 class="title">Matinée</h3>
                <div class="d-flex flex-wrap">
                  <WidgetActivity v-for="activity in morningActivities" :key="activity.id" :activity="activity" />
                </div>
              </div>
              <div v-if="lunchMeal" class="block">
                <h3 class="title">
                  Déjeuner <span v-if="lunchMeal.specialLabel">- {{ lunchMeal.specialLabel }}</span>
                </h3>
                <div v-if="starters.lunch.length" class="meal">
                  <span v-for="(content, index) in starters.lunch" :key="`lunch-starters-${index}`">{{ content | capitalize }}</span>
                </div>
                <div v-if="mains.lunch.length" class="meal">
                  <span v-for="(content, index) in mains.lunch" :key="`lunch-mains-${index}`">{{ content | capitalize }}</span>
                </div>
                <div v-if="deserts.lunch.length" class="meal">
                  <span v-for="(content, index) in deserts.lunch" :key="`lunch-deserts-${index}`">{{ content | capitalize }}</span>
                </div>
              </div>
              <div v-if="afternoonActivities && afternoonActivities.length !== 0" class="block">
                <h3 class="title">Après-midi</h3>
                <div class="d-flex flex-wrap">
                  <WidgetActivity v-for="activity in afternoonActivities" :key="activity.id" :activity="activity" />
                </div>
              </div>
              <div v-if="dinnerMeal" class="block">
                <h3 class="title">
                  Dîner <span v-if="dinnerMeal.specialLabel">- {{ dinnerMeal.specialLabel }}</span>
                </h3>
                <div v-if="starters.dinner.length" class="meal">
                  <span v-for="(content, index) in starters.dinner" :key="`dinner-starters-${index}`">{{ content | capitalize }}</span>
                </div>
                <div v-if="mains.dinner.length" class="meal">
                  <span v-for="(content, index) in mains.dinner" :key="`dinner-mains-${index}`">{{ content | capitalize }}</span>
                </div>
                <div v-if="deserts.dinner.length" class="meal">
                  <span v-for="(content, index) in deserts.dinner" :key="`dinner-deserts-${index}`">{{ content | capitalize }}</span>
                </div>
              </div>
            </Widget>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import { MealType, ActivityFragment, MealFragment, MealContentType } from "@/queries/client/graphql"
import { sessionModule } from "@/store/modules/session"
import { activityModule } from "@/store/modules/activity"
import { teamModule } from "@/store/modules/team"
import { menuModule } from "@/store/modules/menu"

@Component({
  components: {
    Widget: async () => await import("@/components/widgets/widget.vue"),
    WidgetActivity: async () => await import("@/components/widgets/widget-activity.vue"),
    WidgetTeam: async () => await import("@/components/widgets/widget-team.vue"),
    DatePick: async () => await import("@/components/date-pick.vue"),
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
    InternetError: async () => await import("@/components/internet-error.vue"),
  },
})
export default class Calendar extends Vue {
  currentDate = new Date()
  lunchMeal: MealFragment | null = null
  dinnerMeal: MealFragment | null = null
  morningActivities: ActivityFragment[] | null = null
  afternoonActivities: ActivityFragment[] | null = null

  get activityStatus() {
    return activityModule.status
  }

  get activitiesWithStatus() {
    if (!this.residenceId) {
      return null
    }
    const activitiesWithStatus = activityModule.activitiesWithStatus(this.residenceId, this.currentDate)

    if (activitiesWithStatus.activities) {
      const morningActivities =
        activitiesWithStatus.activities.filter((activity) => new Date(activity.startDateTime).getHours() < 14) ?? null
      this.morningActivities = morningActivities?.length !== 0 ? morningActivities : null

      const afternoonActivities =
        activitiesWithStatus.activities.filter((activity) => new Date(activity.startDateTime).getHours() >= 14) ?? null
      this.afternoonActivities = afternoonActivities?.length !== 0 ? afternoonActivities : null
    }
    return activitiesWithStatus
  }

  get teamStatus() {
    return teamModule.status
  }

  get teamWithStatus() {
    if (!this.residenceId) {
      return null
    }

    return teamModule.teamWithStatus(this.residenceId, this.currentDate)
  }

  get menuStatus() {
    return menuModule.status
  }

  get menuWithStatus() {
    if (!this.residenceId) {
      return null
    }

    const menuWithStatus = menuModule.menuWithStatus(this.residenceId, this.currentDate)

    if (menuWithStatus.menu) {
      this.lunchMeal = menuWithStatus.menu.meals.find((meal) => meal.type === MealType.Lunch) ?? null

      this.dinnerMeal = menuWithStatus.menu.meals.find((meal) => meal.type === MealType.Dinner) ?? null
    }

    return menuWithStatus
  }

  get starters() {
    return {
      lunch: this.lunchMeal?.content.find((meal) => meal.type === MealContentType.Starter)?.content.map(({ label }) => label) ?? [],
      dinner: this.dinnerMeal?.content.find((meal) => meal.type === MealContentType.Starter)?.content.map(({ label }) => label) ?? [],
    }
  }

  get mains() {
    return {
      lunch: this.lunchMeal?.content.find((meal) => meal.type === MealContentType.Main)?.content.map(({ label }) => label) ?? [],
      dinner: this.dinnerMeal?.content.find((meal) => meal.type === MealContentType.Main)?.content.map(({ label }) => label) ?? [],
    }
  }

  get deserts() {
    return {
      lunch: this.lunchMeal?.content.find((meal) => meal.type === MealContentType.Desert)?.content.map(({ label }) => label) ?? [],
      dinner: this.dinnerMeal?.content.find((meal) => meal.type === MealContentType.Desert)?.content.map(({ label }) => label) ?? [],
    }
  }

  get residenceId() {
    return sessionModule.currentResidence?.id
  }

  get hasProgram() {
    return Boolean(this.activitiesWithStatus?.activities?.length || this.menuWithStatus?.menu?.meals.length)
  }

  beforeMount() {
    this.loadCalendarDay()

    TrackingHelper.track(AppTrackEvent.calendarOpened)
  }

  loadTeam() {
    if (!this.residenceId) {
      return
    }

    teamModule.loadTeam({ residenceId: this.residenceId, date: this.currentDate })
  }

  loadMenuAndActivities() {
    if (!this.residenceId) {
      return
    }

    activityModule.loadActivities({ residenceId: this.residenceId, date: this.currentDate })

    menuModule.loadMenu({ residenceId: this.residenceId, date: this.currentDate })
  }

  incrementDay(increment: number) {
    this.currentDate = new Date(this.currentDate.setDate(this.currentDate.getDate() + increment))
  }

  async onClickWeeklyCalendar() {
    TrackingHelper.track(AppTrackEvent.calendarSeeActivitiesClicked)

    await this.$router.push("activities")
  }

  @Watch("currentDate", { immediate: true, deep: true })
  loadCalendarDay() {
    this.loadTeam()
    this.loadMenuAndActivities()
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.calendar {
  height: 100%;
  display: flex;
  flex-flow: column;

  .content {
    height: 100%;

    .calendar-container {
      flex: 1 1;
      min-width: 340px;
      max-width: 640px;
      margin: 48px 20px;

      @media (max-height: 520px) {
        display: none;
      }

      .weekly-button {
        display: grid;

        @media (max-height: 592px) {
          display: none;
        }

        .jda-btn {
          max-width: 415px;
          text-align: center;
          margin: 20px auto;
          font-weight: 600;
        }
      }

      .calendar-widget {
        width: 100%;
      }
    }

    @media (max-width: $md), (orientation: portrait) {
      .calendar-container {
        display: none;
      }
    }

    .information-container {
      height: 100%;
      overflow-y: auto;
      max-width: 640px;
      display: flex;
      flex-flow: column;
      flex: 2 1;
      padding: 48px 20px;

      .date {
        width: 100%;
        position: relative;
        padding: 0 32px;

        .arrow {
          position: absolute;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          display: block;

          &.arrow-left {
            left: 0;
          }

          &.arrow-right {
            right: 0;
          }
        }
      }

      .calendar-day {
        margin-top: 20px;
        padding-bottom: 52px;

        &::-webkit-scrollbar {
          display: none;
        }

        .team {
          margin-top: 20px;
        }

        .program {
          width: 100%;
          padding: 12px 24px;
          margin-top: 20px;

          .block {
            margin-bottom: 30px;

            .title {
              text-align: left;
              padding-bottom: 4px;
              border-bottom: 2px solid $border;
              margin-bottom: 8px;
              color: $secondary;
            }

            .widget-activity {
              padding: 8px 24px 8px 0;
            }

            .meal {
              margin-bottom: 12px;
              line-height: 1.2;
              text-align: left;
              @include body();

              &:last-child {
                margin-bottom: 0;
              }

              span {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .loader-block {
    padding: 50px;
  }
}
</style>
