<template>
  <div class="viewer" @mousemove="showOverlay" @click="showOverlay">
    <div ref="contentView" class="app-view" :class="{ overflow: displayButtons }">
      <div v-if="album && album.photos" class="carousel-container">
        <Flicking ref="flick" class="flicking-container" :options="flickOptions" @changed="indexChange($event)">
          <div v-for="(photo, index) in album.photos" :key="photo.id" :ref="photo.id" class="image-container" @dragstart.prevent>
            <PinchZoom ref="pinch" class="h-100" :draggable-image="false">
              <ImageUrl class="image" :file-id="photo.mediaId" :size="FileSize.WIDTH_HD" />
            </PinchZoom>
            <h1>{{ index }}</h1>
          </div>
        </Flicking>
      </div>
      <div v-if="currentIndex !== 0" ref="arrowLeft" class="arrow left">
        <button class="btn-arrow" @click="previous()">
          <img
            src="@/assets/images/next-back.png"
            srcset="@/assets/images/next-back@2x.png 2x, @/assets/images/next-back@3x.png 3x"
            alt="Image Précédente"
          />
        </button>
      </div>
      <div v-if="album && currentIndex !== album.photos.length - 1" ref="arrowRight" class="arrow right">
        <button class="btn-arrow" @click="next()">
          <img
            src="@/assets/images/next.png"
            srcset="@/assets/images/next@2x.png 2x, @/assets/images/next@3x.png 3x"
            alt="Image Suivante"
          />
        </button>
      </div>
      <button class="jda-btn btn-primary" @click="downloadURL()"> Télécharger </button>
      <button class="btn-close" @click="close">
        <img
          class="img-close"
          src="@/assets/images/close_white.png"
          srcset="@/assets/images/close_white@2x.png 2x, @/assets/images/close_white@3x.png 3x"
        />
      </button>
      <div v-if="album && album.photos" class="counter-box"> {{ currentIndex + 1 }}/{{ album.photos.length }} </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Model } from "vue-property-decorator"
import type { Flicking } from "@egjs/vue-flicking"
import { photoModule } from "@/store/modules/photo"
import { GalleryAlbumFragment } from "@/queries/client/graphql"
import FileHelper from "@/helpers/FileHelper"
import DeviceHelper from "@/helpers/DeviceHelper"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import { FileSize } from "@/types/FileSize"

declare global {
  interface Window {
    plugins?: {
      socialsharing?: {
        share(title: string | null, message: string | null, link: string): void
      }
    }
  }
}

@Component({
  components: {
    Flicking: async () => (await import("@egjs/vue-flicking")).Flicking,
    PinchZoom: async () => await import("@/vendor/vue-pinch-zoom/src/components/PinchZoom.vue"),
    ImageUrl: async () => await import("@/components/image-url.vue"),
  },
})
export default class PhotosViewer extends Vue {
  @Model("input", { type: String, required: true }) readonly photoId!: string

  @Prop({ type: Object, required: true }) readonly album!: GalleryAlbumFragment

  FileSize = FileSize

  $refs!: {
    contentView?: HTMLDivElement
    flick?: InstanceType<typeof Flicking>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pinch?: Array<any> // PinchZoom
  }

  get flickOptions(): Partial<Flicking> {
    return {
      autoResize: true,
      defaultIndex: this.currentIndex,
      panelsPerView: 1,
    }
  }

  internalPhotoId = ""
  currentIndex = 0
  contentViewHeight = 1000
  displayButtons = true
  timeoutInstance = -1
  unwatch: (() => void) | null = null

  created() {
    window.addEventListener("resize", this.handleResize)
    window.addEventListener("keydown", this.handleKeyDown)

    this.unwatch = photoModule.$watch(
      (photoModule) => photoModule.isZoomedIn,
      (isZoomedIn) => {
        this.toggleFlickInput(!isZoomedIn)
      }
    ) as () => void
  }

  mounted() {
    TrackingHelper.track(AppTrackEvent.galleryPhotoOpened)

    this.bindPhotoId()
    this.handleResize()
    this.onCurrentIndexChange()
  }

  destroyed() {
    window.removeEventListener("resize", this.handleResize)
    window.removeEventListener("keydown", this.handleKeyDown)
    if (this.timeoutInstance !== -1) {
      window.clearTimeout(this.timeoutInstance)
    }

    if (this.unwatch) {
      this.unwatch()
    }
  }

  bindPhotoId() {
    this.internalPhotoId = this.photoId
  }

  handleResize() {
    this.contentViewHeight = this.$refs.contentView?.clientHeight ?? 100
  }

  handleKeyDown({ key }: KeyboardEvent) {
    if (!key) {
      return
    }

    switch (key) {
      case "Escape":
        this.close()
        break
      case "ArrowLeft":
        this.previous()
        break
      case "ArrowRight":
        this.next()
        break

      default:
        break
    }
  }

  showOverlay() {
    if (this.timeoutInstance !== -1) {
      window.clearTimeout(this.timeoutInstance)
    }

    this.displayButtons = true

    this.timeoutInstance = window.setTimeout(() => {
      this.displayButtons = false
    }, 10000)
  }

  toggleFlickInput(toggle: boolean) {
    if (toggle) {
      this.$refs.flick?.enableInput()
    } else {
      this.$refs.flick?.disableInput()
    }
  }

  indexChange(event: { index: number }) {
    this.currentIndex = event.index
  }

  previous() {
    if (!this.$refs.flick || this.$refs.flick.animating || this.currentIndex === 0) {
      return
    }

    this.$refs.flick.prev()
    this.currentIndex -= 1
  }

  next() {
    if (!this.$refs.flick || this.$refs.flick.animating || this.currentIndex === this.album.photos.length - 1) {
      return
    }

    this.$refs.flick.next()
    this.currentIndex += 1
  }

  close() {
    this.$emit("close")
  }

  async downloadURL() {
    if (!this.album || this.album.photos.length < 1) {
      return
    }

    const photo = this.album.photos[this.currentIndex]
    const blob = await FileHelper.getBlob(photo.mediaId, photo.media.type)

    if (DeviceHelper.isMobile()) {
      const url = (await FileHelper.blobToBase64(blob)) as string

      window.plugins?.socialsharing?.share(null, "Partager", url)
    } else {
      FileHelper.downloadFile(blob, FileHelper.getFilename(photo.mediaId, photo.media.type))
    }
  }

  @Watch("photoId")
  onPhotoIdChanged() {
    this.bindPhotoId()
  }

  @Watch("internalPhotoId")
  onInternalPhotoIdChanged() {
    const index = this.album.photos.findIndex((photo) => photo.id === this.internalPhotoId) ?? 0
    this.currentIndex = index >= 0 ? index : 0
    this.handleResize()

    this.$emit("input", this.internalPhotoId)

    if (!this.$refs.flick) {
      return
    }

    this.$refs.flick.moveTo(this.currentIndex)
    this.$refs.flick.resize()
    this.showOverlay()
  }

  @Watch("currentIndex")
  onCurrentIndexChange() {
    this.$refs.pinch?.at(this.currentIndex)?.resetZoom()
  }
}
</script>

<style>
@import "@egjs/vue-flicking/dist/flicking";
</style>

<style scoped lang="scss">
@import "@/style/mixins";

.viewer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 150;
  display: flex;
  flex-flow: column;
  background-color: rgba(0, 0, 0, 0.85);

  .app-view {
    height: 100%;

    .carousel-container {
      height: 100%;

      .flicking-container {
        height: 100%;

        .image-container {
          height: 100%;
          width: 100%;

          .image {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .btn-close {
      position: absolute;
      top: 30px;
      left: 30px;

      @media (max-width: $sm), (max-width: $md) and (orientation: landscape) {
        width: 45px;
        height: 45px;
      }

      .img-close {
        width: 100%;
        height: 100%;
      }
    }

    .counter-box {
      min-width: 100px;
      position: absolute;
      top: 30px;
      right: 30px;
      padding: 9px 10px;
      border-radius: 8px;
      background-color: $background-primary;
      text-align: center;
      @include title-body();
    }

    .arrow {
      position: fixed;
      top: 50%;

      &.left {
        left: 40px;
        transform: translate(0, -50%);
      }

      &.right {
        right: 40px;
        transform: translate(0, -50%);
      }

      .btn-arrow {
        width: 50px;
        height: 50px;
      }
    }

    .btn-primary {
      width: 160px;
      position: fixed;
      right: 40px;
      bottom: 40px;
    }

    .btn-close,
    .counter-box,
    .arrow,
    .btn-primary {
      z-index: 100;
    }

    .counter-box,
    .arrow,
    .btn-primary {
      display: none;
    }

    &.overflow {
      .counter-box,
      .arrow,
      .btn-primary {
        display: block;
      }
    }
  }
}
</style>
