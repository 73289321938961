/* eslint-disable */
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: Date;
  Time: string;
  UUID: any;
};

export type Activity = {
  endDateTime: Scalars['DateTime'];
  iconId?: Maybe<Scalars['UUID']>;
  iconMediaId?: Maybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  startDateTime: Scalars['DateTime'];
};

export type Anecdote = {
  content: Scalars['String'];
  date: Scalars['String'];
  day: Scalars['String'];
  title: Scalars['String'];
};

export type Ephemeris = {
  gender?: Maybe<Gender>;
  name: Scalars['String'];
};

export type EphemerisSlide = {
  ephemeris?: Maybe<Ephemeris>;
  team: Team;
  weather?: Maybe<WeatherDay>;
};

export type GalleryAlbum = {
  date: Scalars['DateTime'];
  id: Scalars['UUID'];
  photos: Array<GalleryPhoto>;
  title: Scalars['String'];
};

export type GalleryPhoto = {
  id: Scalars['UUID'];
  mediaId: Scalars['String'];
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type Icon = {
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  mediaId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum Job {
  Coordinator = 'COORDINATOR',
  NightWalker = 'NIGHT_WALKER'
}

export type Meal = {
  content: Array<MealContentData>;
  id: Scalars['UUID'];
  specialLabel?: Maybe<Scalars['String']>;
  type: MealType;
};

export type MealContent = {
  id: Scalars['UUID'];
  label: Scalars['String'];
};

export type MealContentData = {
  content: Array<MealContent>;
  type: MealContentType;
};

export enum MealContentType {
  Desert = 'DESERT',
  Main = 'MAIN',
  Starter = 'STARTER'
}

export enum MealType {
  Dinner = 'DINNER',
  Lunch = 'LUNCH'
}

export type MediaItem = {
  id: Scalars['String'];
  type: MediaItemType;
};

export enum MediaItemType {
  Jpeg = 'JPEG',
  Pdf = 'PDF',
  Png = 'PNG'
}

export type Menu = {
  date: Scalars['Date'];
  id: Scalars['UUID'];
  meals: Array<Meal>;
};

export type Pdf = {
  pdfId: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
};

export type Query = {
  screens: ScreensQuery;
  version: Scalars['String'];
};

export type ScreenSlide = {
  album?: Maybe<GalleryAlbum>;
  albumId?: Maybe<Scalars['UUID']>;
  anecdote?: Maybe<Anecdote>;
  animationsWeek?: Maybe<Array<Activity>>;
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  ephemeris?: Maybe<EphemerisSlide>;
  id: Scalars['UUID'];
  mediaItemId?: Maybe<Scalars['UUID']>;
  menuDay?: Maybe<Menu>;
  menuNextDays?: Maybe<Array<Menu>>;
  order: Scalars['Int'];
  pdf?: Maybe<Pdf>;
  timer: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  type: ScreenSlideType;
  updatedAt: Scalars['DateTime'];
};

export enum ScreenSlideType {
  Album = 'ALBUM',
  Anecdote = 'ANECDOTE',
  AnimationsWeek = 'ANIMATIONS_WEEK',
  Ephemeris = 'EPHEMERIS',
  MenuDay = 'MENU_DAY',
  MenuNextDays = 'MENU_NEXT_DAYS',
  Pdf = 'PDF'
}

export type ScreensQuery = {
  screens: Array<ScreenSlide>;
};

export type Team = {
  teamMembers: Array<TeamMember>;
};

export type TeamMember = {
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  job: Job;
  lastName: Scalars['String'];
  photoId?: Maybe<Scalars['UUID']>;
};

export type WeatherDay = {
  description: Scalars['String'];
  id: Scalars['UUID'];
  maxTemperature: Scalars['Int'];
  minTemperature: Scalars['Int'];
  type?: Maybe<WeatherType>;
};

export enum WeatherType {
  BrokenClouds = 'BROKEN_CLOUDS',
  ClearSky = 'CLEAR_SKY',
  FewClouds = 'FEW_CLOUDS',
  Mist = 'MIST',
  Rain = 'RAIN',
  ScatteredClouds = 'SCATTERED_CLOUDS',
  ShowerRain = 'SHOWER_RAIN',
  Snow = 'SNOW',
  Thunderstorm = 'THUNDERSTORM'
}

export type ActivityFragment = { startDateTime: Date, endDateTime: Date, name: string, iconMediaId?: any | null };

export type AnecdoteFragment = { date: string, title: string, content: string };

export type EphemerisFragment = { gender?: Gender | null, name: string };

export type GalleryPhotoFragment = { mediaId: string };

export type GalleryAlbumFragment = { date: Date, title: string, photos: Array<GalleryPhotoFragment> };

export type IconFragment = { id: any, name: string, mediaId?: any | null };

export type MediaItemFragment = { id: string, type: MediaItemType };

export type MealContentFragment = { label: string };

export type MealContentDataFragment = { type: MealContentType, content: Array<MealContentFragment> };

export type MealFragment = { type: MealType, specialLabel?: string | null, content: Array<MealContentDataFragment> };

export type MenuFragment = { date: string, meals: Array<MealFragment> };

export type PdfFragment = { pdfId: any };

export type EphemerisSlideFragment = { ephemeris?: EphemerisFragment | null, weather?: WeatherDayFragment | null, team: TeamFragment };

export type ScreenSlideFragment = { id: any, enabled: boolean, order: number, timer: number, title?: string | null, type: ScreenSlideType, ephemeris?: EphemerisSlideFragment | null, anecdote?: AnecdoteFragment | null, menuDay?: MenuFragment | null, menuNextDays?: Array<MenuFragment> | null, animationsWeek?: Array<ActivityFragment> | null, pdf?: PdfFragment | null, album?: GalleryAlbumFragment | null };

export type TeamFragment = { teamMembers: Array<TeamMemberFragment> };

export type TeamMemberFragment = { firstName: string, lastName: string, job: Job, photoId?: any | null };

export type WeatherDayFragment = { type?: WeatherType | null, minTemperature: number, maxTemperature: number, description: string };

export type ScreenSlidesForDayQueryVariables = Exact<{ [key: string]: never; }>;


export type ScreenSlidesForDayQuery = { screens: { screens: Array<ScreenSlideFragment> } };

export const Icon = gql`
    fragment Icon on Icon {
  id
  name
  mediaId
}
    `;
export const MediaItem = gql`
    fragment MediaItem on MediaItem {
  id
  type
}
    `;
export const Ephemeris = gql`
    fragment Ephemeris on Ephemeris {
  gender
  name
}
    `;
export const WeatherDay = gql`
    fragment WeatherDay on WeatherDay {
  type
  minTemperature
  maxTemperature
  description
}
    `;
export const TeamMember = gql`
    fragment TeamMember on TeamMember {
  firstName
  lastName
  job
  photoId
}
    `;
export const Team = gql`
    fragment Team on Team {
  teamMembers {
    ...TeamMember
  }
}
    ${TeamMember}`;
export const EphemerisSlide = gql`
    fragment EphemerisSlide on EphemerisSlide {
  ephemeris {
    ...Ephemeris
  }
  weather {
    ...WeatherDay
  }
  team {
    ...Team
  }
}
    ${Ephemeris}
${WeatherDay}
${Team}`;
export const Anecdote = gql`
    fragment Anecdote on Anecdote {
  date
  title
  content
}
    `;
export const MealContent = gql`
    fragment MealContent on MealContent {
  label
}
    `;
export const MealContentData = gql`
    fragment MealContentData on MealContentData {
  type
  content {
    ...MealContent
  }
}
    ${MealContent}`;
export const Meal = gql`
    fragment Meal on Meal {
  type
  specialLabel
  content {
    ...MealContentData
  }
}
    ${MealContentData}`;
export const Menu = gql`
    fragment Menu on Menu {
  date
  meals {
    ...Meal
  }
}
    ${Meal}`;
export const Activity = gql`
    fragment Activity on Activity {
  startDateTime
  endDateTime
  name
  iconMediaId
}
    `;
export const Pdf = gql`
    fragment Pdf on Pdf {
  pdfId
}
    `;
export const GalleryPhoto = gql`
    fragment GalleryPhoto on GalleryPhoto {
  mediaId
}
    `;
export const GalleryAlbum = gql`
    fragment GalleryAlbum on GalleryAlbum {
  date
  title
  photos {
    ...GalleryPhoto
  }
}
    ${GalleryPhoto}`;
export const ScreenSlide = gql`
    fragment ScreenSlide on ScreenSlide {
  id
  enabled
  order
  timer
  title
  type
  ephemeris {
    ...EphemerisSlide
  }
  anecdote {
    ...Anecdote
  }
  menuDay {
    ...Menu
  }
  menuNextDays {
    ...Menu
  }
  animationsWeek {
    ...Activity
  }
  pdf {
    ...Pdf
  }
  album {
    ...GalleryAlbum
  }
}
    ${EphemerisSlide}
${Anecdote}
${Menu}
${Activity}
${Pdf}
${GalleryAlbum}`;
export const ScreenSlidesForDay = gql`
    query ScreenSlidesForDay {
  screens {
    screens {
      ...ScreenSlide
    }
  }
}
    ${ScreenSlide}`;