import { apolloClient } from "./apollo-provider"
import {
  Version,
  VersionQuery,
  VersionQueryVariables,
  Me,
  MeQuery,
  MeQueryVariables,
  Residences,
  ResidencesQuery,
  ResidencesQueryVariables,
  ResidenceFragment,
  ActivitiesForDay,
  ActivitiesForDayQuery,
  ActivitiesForDayQueryVariables,
  Activities,
  ActivitiesQuery,
  ActivitiesQueryVariables,
  ActivityFragment,
  Menus,
  MenusQuery,
  MenusQueryVariables,
  MenuForDate,
  MenuForDateQuery,
  MenuForDateQueryVariables,
  MenuFragment,
  CalendarForDay,
  CalendarForDayQuery,
  CalendarForDayQueryVariables,
  SetPassword,
  SetPasswordMutation,
  SetPasswordMutationVariables,
  TeamForDay,
  TeamForDayQuery,
  TeamForDayQueryVariables,
  GetFaq,
  GetFaqQuery,
  GetFaqQueryVariables,
  FaqFragment,
  ServiceFragment,
  GetServicesQuery,
  GetServicesQueryVariables,
  GetServices,
  GetAlbums,
  GetAlbumsQuery,
  GetAlbumsQueryVariables,
  GetAlbum,
  GetAlbumQuery,
  GetAlbumQueryVariables,
  GetPhoto,
  GetPhotoQuery,
  GetPhotoQueryVariables,
  UserFragment,
  CalendarDayFragment,
  TeamFragment,
  PlaceFragment,
  GetPlaces,
  GetPlacesQuery,
  GetPlacesQueryVariables,
  PlaceCategoryFragment,
  GetPlaceCategories,
  GetPlaceCategoriesQuery,
  GetPlaceCategoriesQueryVariables,
  GetLinks,
  GetLinksQuery,
  GetLinksQueryVariables,
  LinkFragment,
  SendMessage,
  SendMessageMutation,
  SendMessageMutationVariables,
  ThreadMessageFragment,
  ThreadFragment,
  GetThread,
  GetThreadQuery,
  GetThreadQueryVariables,
  GalleryAlbumFragment,
  GalleryPhotoFragment,
  InputSubmitSurvey,
  SubmitSurvey,
  SubmitSurveyMutation,
  SubmitSurveyMutationVariables,
  SurveyFragment,
  GetSurveys,
  GetSurveysQuery,
  GetSurveysQueryVariables,
  GetSurveyWithId,
  GetSurveyWithIdQuery,
  GetSurveyWithIdQueryVariables,
  WidgetFragment,
  GetWidgets,
  GetWidgetsQuery,
  GetWidgetsQueryVariables,
  GetActivity,
  GetActivityQuery,
  GetActivityQueryVariables,
  SetNotificationToken,
  SetNotificationTokenMutation,
  SetNotificationTokenMutationVariables,
  DeleteNotificationToken,
  DeleteNotificationTokenMutation,
  DeleteNotificationTokenMutationVariables,
  ProductFragment,
  GetProducts,
  GetProductsQuery,
  GetProductsQueryVariables,
  ReadDataEvent,
  ReadDataEventMutation,
  ReadDataEventMutationVariables,
  DataEventType,
  GetDataEvents,
  GetDataEventsQuery,
  GetDataEventsQueryVariables,
  DataEventFragment,
  GetTeams,
  GetTeamsQuery,
  GetTeamsQueryVariables,
  GetFeatures,
  GetFeaturesQuery,
  GetFeaturesQueryVariables,
  FeatureFragment,
} from "../queries/client/graphql"
import DateHelper from "../helpers/DateHelper"
import { teamFragmentToLocal } from "../types/Team"
import DeviceHelper from "../helpers/DeviceHelper"
import { ApolloQueryResult } from "@apollo/client"

class APIClient {
  // Queries
  async getVersion(): Promise<string> {
    const value = await apolloClient.query<VersionQuery, VersionQueryVariables>({
      query: Version,
    })
    if (value && (value.data.version as string)) {
      return value.data.version
    }

    throw new Error("No result received in getVersion")
  }

  async me(): Promise<UserFragment> {
    let value: ApolloQueryResult<MeQuery> | undefined = undefined

    value = await apolloClient.query<MeQuery, MeQueryVariables>({
      query: Me,
    })

    if (value && value.data.user.me) {
      return value.data.user.me
    }
    throw new Error("No result received in me")
  }

  async getAvailableResidences(): Promise<ResidenceFragment[]> {
    const value = await apolloClient.query<ResidencesQuery, ResidencesQueryVariables>({
      query: Residences,
    })
    return value.data.residence.availableResidences
  }

  async getActivitiesForDay(residenceId: string, date: Date): Promise<ActivityFragment[]> {
    const result = await apolloClient.query<ActivitiesForDayQuery, ActivitiesForDayQueryVariables>({
      query: ActivitiesForDay,
      variables: {
        residenceId,
        date: DateHelper.getDateStringFormat(date),
      },
    })

    if (result && (result.data.residence.data.activity.activitiesForDay as ActivityFragment[])) {
      return result.data.residence.data.activity.activitiesForDay
    }
    throw new Error("No result received in getActivitiesForDay")
  }

  async getActivities(residenceId: string, dateStart: Date, numberOfDays: number): Promise<ActivityFragment[]> {
    const result = await apolloClient.query<ActivitiesQuery, ActivitiesQueryVariables>({
      query: Activities,
      variables: {
        residenceId,
        dateStart: DateHelper.getDateStringFormat(dateStart),
        numberOfDays,
      },
    })

    return result.data.residence.data.activity.activities
  }

  async getActivity(residenceId: string, activityId: string): Promise<ActivityFragment | null> {
    const result = await apolloClient.query<GetActivityQuery, GetActivityQueryVariables>({
      query: GetActivity,
      variables: {
        residenceId,
        activityId,
      },
    })

    return result.data.residence.data.activity.activity ?? null
  }

  async getMenus(residenceId: string, date: Date, numberOfDaysBefore: number, numberOfDaysAfter: number): Promise<MenuFragment[]> {
    const result = await apolloClient.query<MenusQuery, MenusQueryVariables>({
      query: Menus,
      variables: {
        residenceId,
        date: DateHelper.getDateStringFormat(date),
        numberOfDaysBefore,
        numberOfDaysAfter,
      },
    })

    return result.data.residence.data.menu.menus
  }

  async getMenu(residenceId: string, date: Date): Promise<MenuFragment | null> {
    const result = await apolloClient.query<MenuForDateQuery, MenuForDateQueryVariables>({
      query: MenuForDate,
      variables: {
        residenceId,
        date: DateHelper.getDateStringFormat(date),
      },
    })

    return result.data.residence.data.menu.menu ?? null
  }

  async getCalendarDay(residenceId: string, date: Date): Promise<CalendarDayFragment> {
    const result = await apolloClient.query<CalendarForDayQuery, CalendarForDayQueryVariables>({
      query: CalendarForDay,
      variables: {
        residenceId,
        date: DateHelper.getDateStringFormat(date),
      },
    })

    const { calendar } = result.data.residence.data.calendar

    return { ...calendar, team: calendar.team ? teamFragmentToLocal(calendar.team) : null }
  }

  async getTeamForDay(residenceId: string, date: Date): Promise<TeamFragment> {
    const result = await apolloClient.query<TeamForDayQuery, TeamForDayQueryVariables>({
      query: TeamForDay,
      variables: {
        residenceId,
        date: DateHelper.getDateStringFormat(date),
      },
    })

    return teamFragmentToLocal(result.data.residence.data.team.team)
  }

  async getTeams(residenceId: string, startDate: Date, numberOfDays: number): Promise<TeamFragment[]> {
    const result = await apolloClient.query<GetTeamsQuery, GetTeamsQueryVariables>({
      query: GetTeams,
      variables: {
        residenceId,
        dateStart: DateHelper.getDateStringFormat(startDate),
        numberOfDays,
      },
    })

    return result.data.residence.data.team.teams.map((team) => teamFragmentToLocal(team))
  }

  async getFaq(residenceId: string): Promise<Array<FaqFragment>> {
    const result = await apolloClient.query<GetFaqQuery, GetFaqQueryVariables>({
      query: GetFaq,
      variables: {
        residenceId,
      },
    })

    return result.data.residence.data.faq.faq
  }

  async getServices(residenceId: string): Promise<Array<ServiceFragment>> {
    const result = await apolloClient.query<GetServicesQuery, GetServicesQueryVariables>({
      query: GetServices,
      variables: {
        residenceId,
      },
    })

    return result.data.residence.data.service.services
  }

  async getAlbums(residenceId: string): Promise<Array<GalleryAlbumFragment>> {
    const result = await apolloClient.query<GetAlbumsQuery, GetAlbumsQueryVariables>({
      query: GetAlbums,
      variables: {
        residenceId,
      },
    })

    return result.data.residence.data.gallery.albums
  }

  async getAlbum(residenceId: string, albumId: string): Promise<GalleryAlbumFragment> {
    const result = await apolloClient.query<GetAlbumQuery, GetAlbumQueryVariables>({
      query: GetAlbum,
      variables: {
        residenceId,
        albumId,
      },
    })

    return result.data.residence.data.gallery.album
  }

  async getPhoto(residenceId: string, photoId: string): Promise<GalleryPhotoFragment> {
    const result = await apolloClient.query<GetPhotoQuery, GetPhotoQueryVariables>({
      query: GetPhoto,
      variables: {
        residenceId,
        photoId,
      },
    })

    return result.data.residence.data.gallery.photo
  }

  async getPlaces(residenceId: string): Promise<PlaceFragment[]> {
    const result = await apolloClient.query<GetPlacesQuery, GetPlacesQueryVariables>({
      query: GetPlaces,
      variables: {
        residenceId,
      },
    })

    return result.data.residence.data.place.places
  }

  async getPlaceCategories(): Promise<PlaceCategoryFragment[]> {
    const result = await apolloClient.query<GetPlaceCategoriesQuery, GetPlaceCategoriesQueryVariables>({
      query: GetPlaceCategories,
    })

    return result.data.category.place.categories
  }

  async getLinks(residenceId: string): Promise<LinkFragment[]> {
    const result = await apolloClient.query<GetLinksQuery, GetLinksQueryVariables>({
      query: GetLinks,
      variables: {
        residenceId,
      },
    })

    return result.data.residence.data.link.links
  }

  async getThread(residenceId: string): Promise<ThreadFragment | null> {
    const result = await apolloClient.query<GetThreadQuery, GetThreadQueryVariables>({
      query: GetThread,
      variables: {
        residenceId,
      },
    })

    return result.data.contact.thread ?? null
  }

  async getSurveys(residenceId: string): Promise<Array<SurveyFragment>> {
    const result = await apolloClient.query<GetSurveysQuery, GetSurveysQueryVariables>({
      query: GetSurveys,
      variables: {
        residenceId,
      },
    })

    return result.data.residence.data.survey.surveys
  }

  async getSurveyById(residenceId: string, surveyId: string): Promise<SurveyFragment> {
    const result = await apolloClient.query<GetSurveyWithIdQuery, GetSurveyWithIdQueryVariables>({
      query: GetSurveyWithId,
      variables: {
        residenceId,
        surveyId,
      },
    })

    return result.data.residence.data.survey.survey
  }

  async getWidgets(residenceId: string, date: Date, numberOfDays: number): Promise<WidgetFragment[]> {
    const result = await apolloClient.query<GetWidgetsQuery, GetWidgetsQueryVariables>({
      query: GetWidgets,
      variables: {
        residenceId,
        dateStart: DateHelper.getDateStringFormat(date),
        numberOfDays,
      },
    })

    return result.data.residence.data.widget.widgets
  }

  async getProducts(residenceId: string): Promise<ProductFragment[]> {
    const result = await apolloClient.query<GetProductsQuery, GetProductsQueryVariables>({
      query: GetProducts,
      variables: {
        residenceId,
      },
    })

    return result.data.residence.data.shopping.products
  }

  async getDataEvents(residenceId: string): Promise<DataEventFragment[]> {
    const result = await apolloClient.query<GetDataEventsQuery, GetDataEventsQueryVariables>({
      query: GetDataEvents,
      variables: {
        residenceId,
      },
    })

    return result.data.dataEvents
  }

  async getFeatures(residenceId: string): Promise<FeatureFragment[]> {
    const result = await apolloClient.query<GetFeaturesQuery, GetFeaturesQueryVariables>({
      query: GetFeatures,
      variables: {
        residenceId,
      },
    })

    return result.data.residence.data.features
  }

  // Mutation
  async setPassword(oldPassword: string, newPassword: string): Promise<boolean> {
    const result = await apolloClient.mutate<SetPasswordMutation, SetPasswordMutationVariables>({
      mutation: SetPassword,
      variables: {
        oldPassword,
        newPassword,
      },
    })

    if (result.data) {
      return result.data.user.setPassword
    }
    throw new Error("No result received in setPassword")
  }

  async sendMessage(residenceId: string, message: string): Promise<ThreadMessageFragment | null> {
    const result = await apolloClient.mutate<SendMessageMutation, SendMessageMutationVariables>({
      mutation: SendMessage,
      variables: {
        residenceId,
        message,
      },
    })

    return result.data?.contact.sendMessage ?? null
  }

  async submitSurvey(residenceId: string, surveyId: string, inputSubmitSurvey: InputSubmitSurvey): Promise<boolean> {
    await apolloClient.mutate<SubmitSurveyMutation, SubmitSurveyMutationVariables>({
      mutation: SubmitSurvey,
      variables: {
        residenceId,
        surveyId,
        answers: inputSubmitSurvey,
      },
    })

    return true
  }

  async setNotificationToken(token: string) {
    await apolloClient.mutate<SetNotificationTokenMutation, SetNotificationTokenMutationVariables>({
      mutation: SetNotificationToken,
      variables: {
        notificationToken: token,
        deviceId: DeviceHelper.getUUID(),
      },
    })

    return true
  }

  async deleteNotificationToken() {
    await apolloClient.mutate<DeleteNotificationTokenMutation, DeleteNotificationTokenMutationVariables>({
      mutation: DeleteNotificationToken,
      variables: {
        deviceId: DeviceHelper.getUUID(),
      },
    })

    return true
  }

  async markAsReadForType(type: DataEventType, residenceId: string) {
    await apolloClient.mutate<ReadDataEventMutation, ReadDataEventMutationVariables>({
      mutation: ReadDataEvent,
      variables: {
        type,
        residenceId,
      },
    })

    return true
  }
}

const instance = new APIClient()

export default instance
