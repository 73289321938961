<template>
  <div class="home-module">
    <h1 class="title">Bonjour {{ firstname }}</h1>
    <b-row>
      <b-col v-for="resource in resources" :key="resource.type" cols="6" sm="4">
        <div class="home-button">
          <ButtonHome
            :resource="resource"
            :badge="
              (resource.typeName === 'photos' && albumStatus === 'NEW') ||
              (resource.typeName === 'message' && contactStatus === 'NEW') ||
              (resource.typeName === 'survey' && surveyStatus === 'NEW')
            "
            @select="select"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import { sessionModule } from "@/store/modules/session"
import { getHomeResources, HomeResourceType } from "@/types/Home"
import { dataEventModule } from "@/store/modules/data-event"
import { DataEventType, DataEventStatus, FeatureStatus } from "@/queries/client/graphql"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import { featureModule } from "@/store/modules/feature"

@Component({
  components: {
    ButtonHome: async () => await import("@/components/home/button-home.vue"),
  },
})
export default class HomeModule extends Vue {
  firstname = ""

  get resources() {
    const residenceId = sessionModule.currentResidence?.id
    if (!residenceId) {
      return []
    }

    const residencePermissions = sessionModule.user?.residencePermissions
    if (!residencePermissions) {
      return []
    }

    const clientPermission = residencePermissions.find((residencePermission) => residencePermission.residence?.id === residenceId)
    if (!clientPermission) {
      return []
    }

    const { features } = featureModule

    return getHomeResources(clientPermission, features ?? []).filter((resource) => resource.status !== FeatureStatus.Hidden)
  }

  get albumStatus() {
    return dataEventModule.statusForType(DataEventType.Gallery) ?? DataEventStatus.New
  }

  get contactStatus() {
    return dataEventModule.statusForType(DataEventType.Contact) ?? DataEventStatus.Read
  }

  get surveyStatus() {
    return dataEventModule.statusForType(DataEventType.Survey) ?? DataEventStatus.New
  }

  mounted() {
    this.firstname = sessionModule.user?.firstName ?? ""

    document.addEventListener("resume", this.appInForeground, false)

    this.loadDataEvents()
    this.loadFeatures()
  }

  async appInForeground() {
    await this.loadDataEvents()
  }

  async loadDataEvents() {
    await dataEventModule.loadDataEvents()
  }

  async loadFeatures() {
    const residenceId = sessionModule.currentResidence?.id
    if (!residenceId) {
      return
    }

    await featureModule.loadFeatures(residenceId)
  }

  async select(type: HomeResourceType) {
    switch (type) {
      case HomeResourceType.CALENDAR:
        TrackingHelper.track(AppTrackEvent.calendarOpened, { item: "calendar" })
        await this.$router.push("calendar")
        break
      case HomeResourceType.ACTIVITY:
        TrackingHelper.track(AppTrackEvent.calendarOpened, { item: "activities" })
        await this.$router.push("activities")
        break
      case HomeResourceType.RESTAURANT:
        TrackingHelper.track(AppTrackEvent.calendarOpened, { item: "restaurant" })
        await this.$router.push("restaurant")
        break
      case HomeResourceType.PHOTO:
        TrackingHelper.track(AppTrackEvent.calendarOpened, { item: "gallery" })
        if (this.albumStatus === DataEventStatus.New) {
          try {
            await dataEventModule.markAsReadForType(DataEventType.Gallery)
          } catch (error) {
            console.log(error)
          }
        }

        await this.$router.push("albums")
        break
      case HomeResourceType.SERVICE:
        TrackingHelper.track(AppTrackEvent.calendarOpened, { item: "services" })
        await this.$router.push("services-catalog")
        break
      case HomeResourceType.RESIDENCE:
        TrackingHelper.track(AppTrackEvent.calendarOpened, { item: "myResidence" })
        await this.$router.push("residence")
        break
      case HomeResourceType.AROUND_ME:
        TrackingHelper.track(AppTrackEvent.calendarOpened, { item: "places" })
        await this.$router.push("around-me")
        break
      case HomeResourceType.SHOPPING:
        TrackingHelper.track(AppTrackEvent.calendarOpened, { item: "shopping" })
        await this.$router.push("shopping")
        break
      case HomeResourceType.LINK:
        TrackingHelper.track(AppTrackEvent.calendarOpened, { item: "links" })
        await this.$router.push("links")
        break
      case HomeResourceType.SURVEY:
        if (this.surveyStatus === DataEventStatus.New) {
          try {
            await dataEventModule.markAsReadForType(DataEventType.Survey)
          } catch (error) {
            console.log("[HomeModule]:", error)
          }
        }

        TrackingHelper.track(AppTrackEvent.calendarOpened, { item: "survey" })
        await this.$router.push("surveys")
        break
      case HomeResourceType.PROFILE:
        TrackingHelper.track(AppTrackEvent.calendarOpened, { item: "profile" })
        await this.$router.push("profile")
        break
      case HomeResourceType.CONTACT: {
        if (this.contactStatus === DataEventStatus.New) {
          try {
            await dataEventModule.markAsReadForType(DataEventType.Contact)
          } catch (error) {
            console.log(error)
          }
        }

        TrackingHelper.track(AppTrackEvent.calendarOpened, { item: "contact" })
        await this.$router.push("contact")
        break
      }
      default:
        break
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.home-module {
  width: 100%;
  max-width: 600px;
  padding: 40px 15px 0 15px;

  .title {
    @include title-secondary-style();
    font-size: 3.25rem;
    text-align: left;
    padding-bottom: 48px;

    @media (max-width: $sm) {
      @include title();
      padding-bottom: 24px;
    }
  }

  .home-button {
    padding: 16px 0;
  }

  @media (min-width: $md), (orientation: landscape) {
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media (max-width: $md), (orientation: portrait) {
    .title {
      text-align: center;
    }
  }
}
</style>
