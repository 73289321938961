<template>
  <div class="navigation-bar-button">
    <RouterLink v-if="button.to" tag="button" type="button" class="jda-btn btn-link" :to="button.to">
      <img
        v-if="button.img"
        :src="require(`@/assets/images/${button.img}.png`)"
        :srcset="require(`@/assets/images/${button.img}@2x.png`) + ' 2x, ' + require(`@/assets/images/${button.img}@3x.png`) + ' 3x'"
        :alt="button.text"
      />
    </RouterLink>
    <ButtonBack v-else-if="button.back" class="jda-btn btn-default" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { ButtonType } from "./type"

@Component({
  components: {
    ButtonBack: async () => await import("@/components/buttons/button-back.vue"),
  },
})
export default class NavigationBarButton extends Vue {
  @Prop({ type: Object, required: true }) readonly button!: ButtonType
}
</script>
