import mixpanel from "mixpanel-browser"
import { Tracker, Params, AppTrackEvent } from "./Tracker"

let mixpanelToken: string | null = null

if (window._env != null && window._env.MIXPANEL_TOKEN != null && window._env.MIXPANEL_TOKEN.length > 0) {
  mixpanelToken = window._env.MIXPANEL_TOKEN
} else if (process.env.MIXPANEL_TOKEN != null && process.env.MIXPANEL_TOKEN.length > 0) {
  mixpanelToken = process.env.MIXPANEL_TOKEN
}

const MIXPANEL_TOKEN = mixpanelToken

export class MixpanelHelper implements Tracker {
  init() {
    if (MIXPANEL_TOKEN) {
      mixpanel.init(MIXPANEL_TOKEN, { api_host: "https://api-eu.mixpanel.com" }, "")
    }
  }

  track(event: AppTrackEvent, params: Params | null = null) {
    if (!MIXPANEL_TOKEN) {
      return
    }

    switch (event) {
      case AppTrackEvent.accountInitialized: {
        if (params) {
          const { user } = params

          if (user) {
            mixpanel.identify(user.id)
            mixpanel.people.set({
              $first_name: user.firstName,
              $last_name: user.lastName,
              $email: user.email,
              $created: new Date().toISOString(),
            })
          }
        }
        break
      }
      case AppTrackEvent.loggedOut:
        mixpanel.track(event)
        mixpanel.reset()
        break
      default:
        mixpanel.track(event, params ?? undefined)
        break
    }
  }
}
