import Vue from "vue"
import { format } from "date-fns"
import { fr } from "date-fns/locale"

Vue.filter("capitalize", (value: string) => {
  if (!value) return ""
  const valueStr = value.toString()
  return valueStr.charAt(0).toUpperCase() + valueStr.slice(1)
})

Vue.filter("date", (value: string) => {
  if (!value) return ""
  const valueStr = value.toString()
  return new Date(valueStr)
})

Vue.filter("fullDateFormat", (date: Date) => {
  return format(date, "iiii d MMMM yyyy", { locale: fr })
})

Vue.filter("dayDateMonthFormat", (date: Date) => {
  return format(date, "iiii d MMMM", { locale: fr })
})

Vue.filter("dateMonthFormat", (date: Date) => {
  return format(date, "d MMMM", { locale: fr })
})

Vue.filter("dayOfMonthFormat", (date: Date) => {
  return format(date, "d", { locale: fr })
})

Vue.filter("monthYearFormat", (date: Date) => {
  return format(date, "MMMM yyyy", { locale: fr })
})

Vue.filter("timeFormat", (date: Date) => {
  return `${format(date, "HH'h'mm", { locale: fr })}`
})

Vue.filter("dateFormat", (date: Date) => {
  return format(date, "dd/MM/yyyy", { locale: fr })
})

Vue.filter("dateTimeFormat", (date: Date) => {
  return `${format(date, "dd/MM/yyyy à HH'h'mm", { locale: fr })}`
})
