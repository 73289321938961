import { Tracker, Params, AppTrackEvent } from "./Tracker"
import { MixpanelHelper } from "./MixpanelHelper"

class TrackingHelper implements Tracker {
  trackers: Array<Tracker>

  constructor() {
    this.trackers = []
  }

  init() {
    this.trackers = [new MixpanelHelper()]

    this.trackers.forEach((tracker) => tracker.init())
  }

  track(event: AppTrackEvent, params: Params | null = null) {
    this.trackers.forEach((tracker) => tracker.track(event, params))
  }
}

const instance = new TrackingHelper()

export default instance
