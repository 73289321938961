<template>
  <button
    class="btn-home"
    :class="{ 'btn-home-primary-dark': isDark, 'btn-home-white': isLight }"
    :disabled="resource.status === 'DISABLED'"
    @click="$emit('select', resource.type)"
  >
    <div class="btn-container d-flex flex-column justify-content-center align-items-center">
      <img
        :src="require(`@/assets/images/home-module/${resource.typeName}.png`)"
        :srcset="
          require(`@/assets/images/home-module/${resource.typeName}@2x.png`) +
          ' 2x, ' +
          require(`@/assets/images/home-module/${resource.typeName}@3x.png`) +
          ' 3x'
        "
      />
      <div class="name flex-shrink-1">{{ resource.name }}</div>
    </div>
    <div v-if="badge" class="badge">&nbsp;</div>
  </button>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { HomeResource, HomeResourceType } from "@/types/Home"

@Component
export default class ButtonHome extends Vue {
  @Prop({ type: Object, required: true }) readonly resource!: HomeResource
  @Prop({ type: Boolean, required: false, default: false }) readonly badge!: boolean

  get isDark() {
    return [HomeResourceType.CALENDAR, HomeResourceType.ACTIVITY, HomeResourceType.RESTAURANT].includes(this.resource.type)
  }

  get isLight() {
    return [HomeResourceType.PROFILE, HomeResourceType.CONTACT, HomeResourceType.SURVEY].includes(this.resource.type)
  }
}
</script>

<style scoped lang="scss">
@import "@/style/variables";

.btn-home {
  height: 85px;
  position: relative;
  width: 100%;
  color: white;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 8px;
  background-color: $primary;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);

  .btn-container {
    width: 100%;
    height: 100%;

    .name {
      width: 100%;
      line-height: 1.2em;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.btn-home-primary-dark {
    background-color: $secondary;
  }

  &.btn-home-white {
    background-color: white;
    color: $secondary;
  }

  &:disabled {
    opacity: 0.3;
    box-shadow: none;
  }
}

.badge {
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 1000;
  top: -12px;
  right: -12px;
  border-radius: 14px;
  background-color: $highlight;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
}
</style>
