<template>
  <div class="album-group">
    <button class="content" @click="onClickAlbum()">
      <img
        src="@/assets/images/album-background.png"
        srcset="@/assets/images/album-background@2x.png 2x, @/assets/images/album-background@3x.png 3x"
      />
      <div class="first">
        <WidgetImage :file-id="album.thumbnailId" />
      </div>
    </button>
    <h4 class="title">{{ album.title }}</h4>
    <h6 class="sub-title">{{ album.date | date | dateFormat }}</h6>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { GalleryAlbumFragment } from "@/queries/client/graphql"

@Component({
  components: {
    WidgetImage: async () => await import("@/components/widgets/widget-image.vue"),
  },
})
export default class AlbumGroup extends Vue {
  @Prop({ type: Object, required: true }) readonly album!: GalleryAlbumFragment

  async onClickAlbum() {
    await this.$router.push(`list-photo/${this.album.id}`)
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.album-group {
  width: 310px;

  .content {
    width: 100%;
    height: 211px;
    object-fit: contain;
    position: relative;

    .first {
      width: 256px;
      height: 170px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .title {
    margin-top: 16px;
    @include title-bold();
  }

  .sub-title {
    margin-top: 8px;
    color: $text-body;
  }
}
</style>
