<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"

@Component
export default class App extends Vue {
  name = "App"
}
</script>

<style lang="scss">
@import "./style/main";
</style>
