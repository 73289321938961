<template>
  <div class="shopping">
    <NavigationBar :left-buttons="[{ text: 'Retour', back: true }]" title="Shopping" />

    <div class="app-view">
      <InternetError v-if="status === 'error'" :retry="loadProducts" />
      <div v-else-if="status !== 'ready'" class="loader"></div>
      <div v-for="product in products" v-else :key="product.id" class="product">
        <WidgetSecondary :title="product.title">
          <div class="product-container d-flex flex-wrap">
            <div class="product-photo-container">
              <ImageUrl class="photo" :file-id="product.photoId" :size="FileSize.WIDTH_HD" />
            </div>
            <Nl2Br class="description" :content="product.description" />
          </div>
        </WidgetSecondary>
        <button class="jda-btn btn-primary product-link" @click="openUrl(product)">Voir l'offre</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import APIClient from "@/api/ApiClient"
import { sessionModule } from "@/store/modules/session"
import { ProductFragment } from "@/queries/client/graphql"
import { Status } from "@/types/Status"
import { FileSize } from "@/types/FileSize"

@Component({
  components: {
    NavigationBar: async () => await import("@/components/navigation-bar/navigation-bar.vue"),
    WidgetSecondary: async () => await import("@/components/widgets/widget-secondary.vue"),
    Nl2Br: async () => await import("@/components/nl2br.vue"),
    ImageUrl: async () => await import("@/components/image-url.vue"),
    InternetError: async () => await import("@/components/internet-error.vue"),
  },
})
export default class Shopping extends Vue {
  products: ProductFragment[] = []
  status = Status.New

  FileSize = FileSize

  async mounted() {
    TrackingHelper.track(AppTrackEvent.shoppingOpened)
    await this.loadProducts()
  }

  async loadProducts() {
    const currentResidenceId = sessionModule.currentResidence?.id

    if (!currentResidenceId) {
      return
    }

    this.status = Status.Loading

    try {
      this.products = await APIClient.getProducts(currentResidenceId)

      this.status = Status.Ready
    } catch (error) {
      this.status = Status.Error
    }
  }

  openUrl(product: ProductFragment) {
    TrackingHelper.track(AppTrackEvent.shoppingItemClicked, { shoppingId: product.id })
    window.open(product.url, "_blank")
  }
}
</script>

<style scoped lang="scss">
.shopping {
  height: 100%;
  display: flex;
  flex-flow: column;

  .app-view {
    padding-bottom: 40px;

    .product {
      position: relative;
      padding: 40px 40px 10px 40px;

      .product-container {
        padding: 15px;

        .product-photo-container {
          width: 100%;
          padding: 15px;
          max-width: 270px;

          .photo {
            width: 100%;
            object-fit: contain;
            border-radius: 8px;
          }
        }

        .description {
          padding: 15px;
        }
      }

      .product-link {
        width: 132px;
        padding: 12px;
        text-decoration: none;
        text-align: center;
        position: absolute;
        cursor: pointer;
        right: 60px;
        bottom: -10px;
      }
    }
  }
}
</style>
