<template>
  <div class="widget-service">
    <Widget>
      <div class="d-flex">
        <div class="icon-container">
          <ImageUrl v-if="service.icon && !isOffline" class="icon" :file-id="service.icon.mediaId" :size="FileSize.SMALL_SQUARE" />
          <img
            v-else
            class="icon"
            src="@/assets/images/icon-default.png"
            srcset="@/assets/images/icon-default@2x.png 2x, @/assets/images/icon-default@3x.png 3x"
          />
        </div>
        <div class="detail flex-shrink-1">
          <h3 class="name">{{ service.name }}</h3>
          <div class="description">{{ service.description }}</div>
        </div>
      </div>
      <button class="jda-btn btn-highlight" @click="showPdfViewer"> Voir </button>
    </Widget>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import TrackingHelper from "@/helpers/tracking/TrackingHelper"
import { AppTrackEvent } from "@/helpers/tracking/Tracker"
import { ServiceFragment } from "@/queries/client/graphql"
import { isOffline } from "@/helpers/ConnectionHelper"
import { FileSize } from "@/types/FileSize"

@Component({
  components: {
    Widget: async () => await import("@/components/widgets/widget.vue"),
    ImageUrl: async () => await import("@/components/image-url.vue"),
  },
})
export default class WidgetService extends Vue {
  @Prop({ type: Object, required: true }) readonly service!: ServiceFragment

  FileSize = FileSize

  get isOffline() {
    return isOffline()
  }

  async showPdfViewer() {
    TrackingHelper.track(AppTrackEvent.servicesItemClicked, { serviceId: this.service.id })
    await this.$router.push({ name: "PdfViewer", params: { pdfId: this.service.fileId, title: this.service.name } })
  }
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.widget-service {
  position: relative;

  .widget {
    padding: 24px;

    .icon-container {
      border-radius: 4px;
      background-color: $background-primary;

      .icon {
        width: 80px;
        height: 80px;
        padding: 12px;
        object-fit: contain;
      }
    }

    .service-img {
      width: 80px;
      height: 80px;
      border-radius: 4px;
      padding: 12px;
      object-fit: contain;
      background-color: $background-primary;
    }

    .detail {
      overflow: hidden;
      padding-left: 23px;

      .name {
        width: 100%;

        text-align: left;
      }

      .description {
        width: 100%;
        @include body();
      }

      .name,
      .description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .jda-btn {
      width: 97px;
      position: absolute;
      right: 16px;
      bottom: -18px;
      text-align: center;
      text-decoration: none;
      line-height: 48px;
    }
  }
}
</style>
