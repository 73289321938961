<template>
  <div class="pinch-zoom-wrapper" :style="styleObject">
    <div ref="wrapper" class="pinch-zoom-content" :class="{ 'pz-dragging': isDragging() }">
      <slot></slot>
    </div>

    <div
      v-if="isControl()"
      class="pz-zoom-button pz-zoom-control-position-bottom"
      :class="{ 'pz-zoom-button-out': isZoomedIn }"
      @click="toggleZoom()"
    ></div>
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Prop } from "vue-property-decorator"
import { IvyPinch } from "../ivypinch"
import { Properties } from "../interfaces"
import { defaultProperties } from "../properties"
import { photoModule } from "../../../../store/modules/photo"

@Component
export default class PinchZoom extends Vue {
  @Prop({ type: Object, required: false, default: () => defaultProperties }) properties!: Properties
  @Prop({ type: Number, required: false, default: defaultProperties.transitionDuration }) transitionDuration!: number
  @Prop({ type: Boolean, required: false, default: defaultProperties.doubleTap }) doubleTap!: boolean
  @Prop({ type: Number, required: false, default: defaultProperties.doubleTapScale }) doubleTapScale!: number
  @Prop({ type: Boolean, required: false, default: defaultProperties.autoZoomOut }) autoZoomOut!: boolean
  @Prop({ type: [Number, String], required: false, default: defaultProperties.limitZoom }) limitZoom!: number | "original image size"
  @Prop({ type: Boolean, required: false, default: defaultProperties.disabled }) disabled!: boolean
  @Prop({ type: Boolean, required: false, default: defaultProperties.disablePan }) disablePan!: boolean
  @Prop({ type: String, required: false, default: defaultProperties.overflow }) overflow!: "hidden" | "visible"
  //@Prop() zoomControlScale: number;
  @Prop({ type: String, required: false, default: defaultProperties.disableZoomControl }) disableZoomControl!: "disable" | "never" | "auto"
  @Prop({ type: String, required: false, default: defaultProperties.backgroundColor }) backgroundColor!: string
  @Prop({ type: Boolean, required: false, default: defaultProperties.limitPan }) limitPan!: boolean
  @Prop({ type: Number, required: false, default: defaultProperties.minScale }) minScale!: number
  @Prop({ type: String, required: false, default: defaultProperties.listeners }) listeners!: "auto" | "mouse and touch"
  @Prop({ type: Boolean, required: false, default: defaultProperties.wheel }) wheel!: boolean
  @Prop({ type: Boolean, required: false, default: defaultProperties.autoHeight }) autoHeight!: boolean
  @Prop({ type: Number, required: false, default: defaultProperties.wheelZoomFactor }) wheelZoomFactor!: number
  @Prop({ type: Boolean, required: false, default: defaultProperties.draggableImage }) draggableImage!: boolean

  _properties!: Properties
  ivyPinch: any
  styleObject: any

  get isTouchScreen() {
    const prefixes = " -webkit- -moz- -o- -ms- ".split(" ")
    const mq = function (query: string) {
      return window.matchMedia(query).matches
    }

    if ("ontouchstart" in window) {
      return true
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("")
    return mq(query)
  }

  get isZoomedIn() {
    return photoModule.isZoomedIn
  }

  created() {
    const changedOptions = this.getProperties(this.$props)
    this.applyOptionsDefault(defaultProperties, changedOptions)
    this.setStyles()
  }

  mounted() {
    this.init()
  }

  beforeDestroy() {
    this.ivyPinch.destroy()
  }

  isDragging() {
    if (!this.ivyPinch) {
      return undefined
    }
    return this.ivyPinch.isDragging()
  }

  isControl() {
    if (this._properties["disabled"]) {
      return false
    }

    if (!this._properties) {
      return undefined
    }

    if (this._properties["disableZoomControl"] === "disable") {
      return false
    }

    if (this.isTouchScreen && this._properties["disableZoomControl"] === "auto") {
      return false
    }

    return true
  }

  getScale() {
    if (!this.ivyPinch) {
      return undefined
    }
    return this.ivyPinch.scale
  }

  init() {
    if (this._properties["disabled"]) {
      return
    }

    this._properties["element"] = this.$refs.wrapper
    this._properties["eventHandler"] = this.myEventHandler
    this.ivyPinch = new IvyPinch(this._properties)

    this.pollLimitZoom()
  }

  getProperties(changes: any) {
    let properties: any = {}

    for (const prop in changes) {
      if (changes[prop] !== undefined) {
        if (prop !== "properties") {
          properties[prop] = changes[prop]
        }
        if (prop === "properties") {
          properties = changes[prop]
        }
      }
    }
    return properties
  }

  applyOptionsDefault(defaultOptions: any, options: any): void {
    this._properties = Object.assign({}, defaultOptions, options)
  }

  myEventHandler(event: any) {
    if (["wheel", "pinch", "doubleTap"].includes(event.name)) {
      const zoomedIn = event.detail.scale > 1

      if (zoomedIn != this.isZoomedIn) {
        photoModule.setZoomedIn(zoomedIn)
      }
    }
  }

  toggleZoom() {
    this.ivyPinch.toggleZoom()

    const zoomedIn = this.getScale() > 1

    if (zoomedIn != this.isZoomedIn) {
      photoModule.setZoomedIn(zoomedIn)
    }
  }

  resetZoom() {
    photoModule.setZoomedIn(false)
    this.ivyPinch.resetScale()
  }

  pollLimitZoom() {
    this.ivyPinch.pollLimitZoom()
  }

  setStyles() {
    this.styleObject = {
      overflow: this._properties["overflow"],
      "background-color": this._properties["backgroundColor"],
    }
  }
}
</script>

<style lang="scss">
.pinch-zoom-wrapper {
  position: relative;
  overflow: hidden;
  display: block;

  .pinch-zoom-content {
    height: inherit;
  }

  .pz-dragging {
    cursor: all-scroll;
  }

  /* Zoom button */
  .pz-zoom-button {
    position: absolute;
    z-index: 1000;
    color: #fff;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgc3R5bGU9IiI+PHJlY3QgaWQ9ImJhY2tncm91bmRyZWN0IiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4PSIwIiB5PSIwIiBmaWxsPSJub25lIiBzdHJva2U9Im5vbmUiLz48ZyBjbGFzcz0iY3VycmVudExheWVyIiBzdHlsZT0iIj48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PHBhdGggZD0iTTE1LjUgMTRoLS43OWwtLjI4LS4yN0MxNS40MSAxMi41OSAxNiAxMS4xMSAxNiA5LjUgMTYgNS45MSAxMy4wOSAzIDkuNSAzUzMgNS45MSAzIDkuNSA1LjkxIDE2IDkuNSAxNmMxLjYxIDAgMy4wOS0uNTkgNC4yMy0xLjU3bC4yNy4yOHYuNzlsNSA0Ljk5TDIwLjQ5IDE5bC00Ljk5LTV6bS02IDBDNy4wMSAxNCA1IDExLjk5IDUgOS41UzcuMDEgNSA5LjUgNSAxNCA3LjAxIDE0IDkuNSAxMS45OSAxNCA5LjUgMTR6IiBpZD0ic3ZnXzEiIGNsYXNzPSIiIGZpbGw9IiNmZmZmZmYiIGZpbGwtb3BhY2l0eT0iMSIvPjxwYXRoIGQ9Ik0xMiAxMGgtMnYySDl2LTJIN1Y5aDJWN2gxdjJoMnYxeiIgaWQ9InN2Z18zIiBjbGFzcz0iIiBmaWxsPSIjZmZmZmZmIiBmaWxsLW9wYWNpdHk9IjEiLz48L2c+PC9zdmc+),
      url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHJlY3QgaWQ9ImJhY2tncm91bmRyZWN0IiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4PSIwIiB5PSIwIiBmaWxsPSJub25lIiBzdHJva2U9Im5vbmUiLz48ZyBjbGFzcz0iY3VycmVudExheWVyIiBzdHlsZT0iIj48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PHBhdGggZD0iTTE1LjUgMTRoLS43OWwtLjI4LS4yN0MxNS40MSAxMi41OSAxNiAxMS4xMSAxNiA5LjUgMTYgNS45MSAxMy4wOSAzIDkuNSAzUzMgNS45MSAzIDkuNSA1LjkxIDE2IDkuNSAxNmMxLjYxIDAgMy4wOS0uNTkgNC4yMy0xLjU3bC4yNy4yOHYuNzlsNSA0Ljk5TDIwLjQ5IDE5bC00Ljk5LTV6bS02IDBDNy4wMSAxNCA1IDExLjk5IDUgOS41UzcuMDEgNSA5LjUgNSAxNCA3LjAxIDE0IDkuNSAxMS45OSAxNCA5LjUgMTR6TTcgOWg1djFIN3oiIGlkPSJzdmdfMiIgY2xhc3M9IiIgZmlsbD0iI2ZmZmZmZiIgZmlsbC1vcGFjaXR5PSIxIi8+PC9nPjwvc3ZnPg==);
    background-color: rgba(0, 0, 0, 0.8);
    background-position:
      center,
      -1000px;
    background-repeat: no-repeat, no-repeat;
    background-size: 40px;
    width: 56px;
    height: 56px;
    border-radius: 4px;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.1s;
    user-select: none;
  }

  .pz-zoom-button-out {
    background-position: -1000px, center;
  }

  .pz-zoom-button:hover {
    opacity: 0.7;
  }

  .pz-zoom-button.pz-zoom-control-position-right {
    right: 16px;
    top: 50%;
    margin-top: -28px;
  }

  .pz-zoom-button.pz-zoom-control-position-right-bottom {
    right: 16px;
    bottom: 32px;
  }

  .pz-zoom-button.pz-zoom-control-position-bottom {
    bottom: 16px;
    left: 50%;
    margin-left: -28px;
  }

  /* Zoom control */
  .pz-zoom-control {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    overflow: hidden;
  }

  .pz-zoom-control.pz-zoom-control-position-right {
    right: 16px;
    top: 50%;
    margin-top: -48px;
  }

  .pz-zoom-control.pz-zoom-control-position-right-bottom {
    right: 16px;
    bottom: 32px;
  }

  .pz-zoom-control.pz-zoom-control-position-bottom {
    bottom: 16px;
    left: 50%;
    margin-left: -48px;
  }

  .pz-zoom-in,
  .pz-zoom-out {
    width: 48px;
    height: 48px;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    cursor: pointer;
  }

  .pz-zoom-in:hover,
  .pz-zoom-out:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .pz-zoom-control-position-bottom .pz-zoom-in,
  .pz-zoom-control-position-bottom .pz-zoom-out {
    float: right;
  }

  .pz-disabled {
    opacity: 0.5;
    cursor: default;
  }

  .pz-disabled:hover {
    background-color: rgba(255, 255, 255, 0);
  }
}
</style>
