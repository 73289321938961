<template>
  <div class="widget-team">
    <WidgetPrimary title="Équipe">
      <div class="team">
        <TeamDay :team="team" />
      </div>
    </WidgetPrimary>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator"
import { TeamFragment } from "@/queries/client/graphql"

@Component({
  components: {
    WidgetPrimary: async () => await import("./widget-primary.vue"),
    TeamDay: async () => await import("@/components/teams/team-day.vue"),
  },
})
export default class WidgetTeam extends Vue {
  @Prop({ type: Object }) readonly team!: TeamFragment
}
</script>

<style scoped lang="scss">
.widget-team {
  .team {
    padding: 18px 24px 0 18px;
  }
}
</style>
