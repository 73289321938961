<template>
  <div class="form-group number-form">
    <h5 class="form-type">Uniquement des chiffres</h5>
    <input v-model="number" class="form-control number" type="number" placeholder="Tapez votre réponse ici" :disabled="disabled" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, VModel } from "vue-property-decorator"

@Component
export default class NumberForm extends Vue {
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean

  @VModel({ type: String }) readonly number!: string
}
</script>

<style scoped lang="scss">
@import "@/style/mixins";

.number-form {
  width: 100%;
  max-width: 355px;
  margin: 0 auto;
  padding: 11px;

  .form-type {
    color: $text-body;
    font-style: italic;
    font-weight: normal;
  }

  .number {
    margin-top: 50px;
    width: 100%;
    @include title-h3();
    text-align: center;
    font-weight: normal;
    color: $text-body;
    border-radius: 8px;
    border: none;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    background-color: white;
  }

  ::placeholder {
    color: $disabled;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: $disabled;
  }

  ::-ms-input-placeholder {
    color: $disabled;
  }
}
</style>
